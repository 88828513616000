import { IconButtonsPlayPauseO } from '@doltech/icons/IconButtonsPlayPauseO';
import { IconChevronDropdownDown } from '@doltech/icons/IconChevronDropdownDown';
import { IconMultimediaPlayButtonO } from '@doltech/icons/IconMultimediaPlayButtonO';
import { ContentGroupEnum, TestStatusEnum } from '@doltech/utils/lib/constants';
import { getLandingUrlFromPath } from '@doltech/utils/lib/url';
import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

import { colorsV2 } from '../../../figma/colors-v2';
import { Typography } from '../../../figma/DesignSystemV2/Typography';
import { Link } from '../../../figma/Link';
import { useFetchResourceStatus } from '../../api/resources/fetchResourceStatus';
import { DolPopover } from '../../../figma/Popover/DolPopover';
import { IconCourseRefresh } from '@doltech/icons/Course/IconCourseRefesh';

interface ButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  item: any;
  altActions?: any;
}

const EmptyDiv = styled.div`
  height: 48px;
`;
const MainButtonLink = styled(Link)`
  display: grid;
  grid-auto-flow: column;
  column-gap: 8px;
  color: ${colorsV2.black100};
  padding: 8px 12px;

  height: 100%;
  place-items: center;
  &.reduce-right-space {
    padding: 8px 4px 8px 12px;
  }
`;

const MainButtonNormal = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 8px;
  color: ${colorsV2.black100};
  padding: 8px 12px;

  height: 100%;
  place-items: center;
  &.reduce-right-space {
    padding: 8px 4px 8px 12px;
  }
`;

const AltActionsButton = styled.div`
  width: 36px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${colorsV2.gray20};
  }
`;

const ButtonWrapper = styled.div<{ hasAltActions?: boolean }>`
  border-radius: 8px;
  display: grid;
  grid-auto-flow: column;
  width: max-content;
  align-items: center;
  border: 1px solid ${colorsV2.gray20};

  &:hover {
    cursor: pointer;
    box-shadow: 0 2px 2px ${colorsV2.blackShadow5};
    .main-button {
      color: ${colorsV2.black100};
    }
    border: 1px solid ${colorsV2.primary100};

    &.${TestStatusEnum.IN_PROGRESS} {
      border-color: ${colorsV2.blue100};
    }
    &.${TestStatusEnum.COMPLETED} {
      border-color: ${colorsV2.gray60};
    }
  }
  &:active {
    background-color: ${colorsV2.black5};
    border-color: ${colorsV2.primary100};
  }
  &.disabled {
    box-shadow: unset;
    .main-button {
      color: ${colorsV2.black40};
      pointer-events: none;
    }
    .alt-actions-button {
      background-color: ${colorsV2.white100};
      &:hover {
        background-color: ${colorsV2.gray20};
      }
    }
  }
`;

const ActionLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const ActionIconWrapper = styled.div`
  display: flex;
  font-size: 24px;
  color: ${colorsV2.black100};
  margin-right: 10px;
`;

const ActionDropdown = styled.div`
  padding: 8px 0;
  min-width: 174px;
  .action-item {
    padding: 8px 16px;
    > span {
      color: ${colorsV2.black100};
    }
    &:hover {
      background-color: ${colorsV2.gray10};
    }
  }
`;

const AltActionItems = ({ altActions }) =>
  altActions.map((action) => {
    const { key, url, icon, text } = action;
    return (
      <ActionLink target="_blank" key={key} href={url} className="action-item">
        <ActionIconWrapper>{icon}</ActionIconWrapper>
        <Typography.Text variant="semi-bold/14-24">{text}</Typography.Text>
      </ActionLink>
    );
  });

export const ItemDetailDoTestButton = (props: ButtonProps) => {
  const { className, item, altActions } = props;
  const [visible, setVisible] = React.useState(false);

  const { data: status } = useFetchResourceStatus(item);
  const statusToCheck = item.status || status;

  const hasAltActions = Boolean(altActions);
  const disabled = Boolean(!item.url);

  const message = React.useMemo(() => {
    switch (statusToCheck) {
      case TestStatusEnum.IN_PROGRESS:
        if (item.type === ContentGroupEnum.DO_DICTATION) {
          return 'Chép tiếp';
        }
        return 'Làm tiếp';
      case TestStatusEnum.COMPLETED:
        if (item.type === ContentGroupEnum.DO_DICTATION) {
          return 'Chép lại';
        }
        return 'Làm lại';
      default:
        // Status is null or NOT_STARTED.
        if (item.type === ContentGroupEnum.DO_DICTATION) {
          return 'Chép bài';
        }
        return 'Làm bài';
    }
  }, [item.type, statusToCheck]);

  const getIcon = React.useCallback(() => {
    switch (statusToCheck) {
      case TestStatusEnum.IN_PROGRESS:
        return <IconButtonsPlayPauseO fontSize={20} color="blue100" />;
      case TestStatusEnum.COMPLETED:
        return <IconCourseRefresh fontSize={20} color="black60" />;
      default:
        // Status is null or NOT_STARTED.
        return <IconMultimediaPlayButtonO fontSize={20} color="primary100" />;
    }
  }, [statusToCheck]);

  if (!item) {
    return null;
  }
  if (item.type === ContentGroupEnum.LINEAR_THINKING) {
    return <EmptyDiv />;
  }

  return (
    <DolPopover
      placement="bottom-start"
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
      overlayClassName="dol-popover"
      content={
        Boolean(altActions?.length) && (
          <ActionDropdown>
            <AltActionItems altActions={altActions} />
          </ActionDropdown>
        )
      }
    >
      <ButtonWrapper
        hasAltActions={hasAltActions}
        className={cl(className, statusToCheck, { disabled })}
      >
        {item?.onSelect ? (
          <MainButtonNormal
            className={cl('main-button', { 'reduce-right-space': hasAltActions })}
            onClick={(e) => {
              e.stopPropagation();
              item.onSelect();
            }}
          >
            {getIcon()}
            <Typography.Text variant="semi-bold/14-24">{message}</Typography.Text>
          </MainButtonNormal>
        ) : (
          <MainButtonLink
            className={cl('main-button', { 'reduce-right-space': hasAltActions })}
            href={getLandingUrlFromPath(item.url)}
            target="_blank"
          >
            {getIcon()}
            <Typography.Text variant="semi-bold/14-24">{message}</Typography.Text>
          </MainButtonLink>
        )}

        {hasAltActions && (
          <AltActionsButton className="alt-actions-button" onClick={() => setVisible((o) => !o)}>
            <IconChevronDropdownDown fontSize={20} color={'black40'} />
          </AltActionsButton>
        )}
      </ButtonWrapper>
    </DolPopover>
  );
};
