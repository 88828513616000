import { layoutGridWidthCalculator } from '../../../hooks/useLayoutGrid';

// Main Landing Layout component
export const SCROLL_BAR_WIDTH = 14;
export const LANDING_HEADER_HEIGHT = 64;
export const LANDING_BANNER_HEIGHT = 250;

export const LANDING_SIDE_BAR_WIDTH = {
  MOBILE: 300 + SCROLL_BAR_WIDTH,
  TABLET: 375 + SCROLL_BAR_WIDTH,
};

// Landing SEO Header
export const SEO_TEST_HEADER_SECTION_SPACING = 40;

// Landing Table of content
export const TABLE_OF_CONTENT_MIN_WIDTH = 248;

export const CONTENT_MIDDLE_MAX_WIDTH = {
  DESKTOP: layoutGridWidthCalculator(12),
  TABLET: layoutGridWidthCalculator(8),
  MOBILE: layoutGridWidthCalculator(4),
  LG_MOBILE: 396, // Hard the width due to not have an appropriate column design for this screen size.
};

export const LANDING_BANNER = {
  IELTS_ONLINE_TEST: {
    desktop: {
      url: 'PUBLIC/ASSETS/images/ielts-online-test-banner-2x.png',
      height: 401,
    },
    tablet: {
      url: 'PUBLIC/ASSETS/images/ielts-online-test-banner-tablet-2x.png',
      height: 401,
    },
    mobile: {
      url: 'PUBLIC/ASSETS/images/ielts-online-test-banner-mobile-2x.png',
      height: 384,
    },
  },
  LINEARTHINKING: {
    desktop: {
      url: 'PUBLIC/ASSETS/images/linearthinking-banner-2x.png',
      height: 406,
    },
    tablet: {
      url: 'PUBLIC/ASSETS/images/linearthinking-banner-tablet-2x.png',
      height: 429,
    },
    mobile: {
      url: 'PUBLIC/ASSETS/images/linearthinking-banner-mobile-2x.png',
      height: 384,
    },
  },
  DICTATION: {
    desktop: {
      url: 'PUBLIC/ASSETS/images/dictation-banner-2x.png',
      height: 428,
    },
    tablet: {
      url: 'PUBLIC/ASSETS/images/dictation-banner-tablet-2x.png',
      height: 405,
    },
    mobile: {
      url: 'PUBLIC/ASSETS/images/dictation-banner-mobile-2x.png',
      height: 384,
    },
  },
  IELTS_READING_BLOG: {
    desktop: {
      url: 'PUBLIC/ASSETS/images/ielts-reading-banner-2x.png',
      height: 435,
    },
    tablet: {
      url: 'PUBLIC/ASSETS/images/ielts-reading-banner-tablet-2x.png',
      height: 435,
    },
    mobile: {
      url: 'PUBLIC/ASSETS/images/ielts-reading-banner-mobile-2x.png',
      height: 384,
    },
  },
  IELTS_LISTENING_BLOG: {
    desktop: {
      url: 'PUBLIC/ASSETS/images/ielts-listening-banner-2x.png',
      height: 440,
    },
    tablet: {
      url: 'PUBLIC/ASSETS/images/ielts-listening-banner-tablet-2x.png',
      height: 418,
    },
    mobile: {
      url: 'PUBLIC/ASSETS/images/ielts-listening-banner-mobile-2x.png',
      height: 384,
    },
  },
  IELTS_WRITING_BLOG: {
    desktop: {
      url: 'PUBLIC/ASSETS/images/ielts-writing-banner-2x.png',
      height: 409,
    },
    tablet: {
      url: 'PUBLIC/ASSETS/images/ielts-writing-banner-tablet-2x.png',
      height: 409,
    },
    mobile: {
      url: 'PUBLIC/ASSETS/images/ielts-writing-banner-mobile-2x.png',
      height: 384,
    },
  },
  IELTS_SPEAKING_BLOG: {
    desktop: {
      url: 'PUBLIC/ASSETS/images/ielts-speaking-banner-2x.png',
      height: 416,
    },
    tablet: {
      url: 'PUBLIC/ASSETS/images/ielts-speaking-banner-tablet-2x.png',
      height: 416,
    },
    mobile: {
      url: 'PUBLIC/ASSETS/images/ielts-speaking-banner-mobile-2x.png',
      height: 384,
    },
  },
  IELTS_SHARE_REVIEW_BLOG: {
    desktop: {
      url: 'PUBLIC/ASSETS/images/share-and-review-banner-2x.png',
      height: 384,
    },
    tablet: {
      url: 'PUBLIC/ASSETS/images/share-and-review-banner-tablet-2x.png',
      height: 384,
    },
    mobile: {
      url: 'PUBLIC/ASSETS/images/share-and-review-banner-mobile-2x.png',
      height: 384,
    },
  },
};

export const TOP_NAVS_ID = {
  // Main
  DAILY_LEARNING: 'Tin tức tiếng anh',
  K12: 'K12',
  ONLINE_TEST: 'IELTS Full Test',
  LINEAR_THINKING: 'Linearthinking',
  IELTS_PRACTICE: 'IELTS Online Test',
  IELTS_BLOG: 'IELTS Blog',
  PRACTICE_SKILLS: 'nav_practice_skills',
  VOCAB: 'Từ vựng IELTS',
  GRAMMAR: 'Ngữ pháp IELTS',
  DICTATION: 'Chép chính tả',
  DICTIONARY: 'Từ điển IELTS',

  ABOUT_DOL: 'Về DOL',

  // BLogs

  LANDING_IELTS_REVIEW: 'LANDING_IELTS_REVIEW',
  LANDING_READING_IELTS_BLOG: 'LANDING_READING_IELTS_BLOG',
  LANDING_LISTENING_IELTS_BLOG: 'LANDING_LISTENING_IELTS_BLOG',
  LANDING_SPEAKING_IELTS_BLOG: 'LANDING_SPEAKING_IELTS_BLOG',
  LANDING_WRITING_IELTS_BLOG: 'LANDING_WRITING_IELTS_BLOG',
  LANDING_GRAMMAR_IELTS_BLOG: 'LANDING_GRAMMAR_IELTS_BLOG',
  LANDING_VOCAB_IELTS_BLOG: 'LANDING_VOCAB_IELTS_BLOG',
  LANDING_IELTS_KNOWLEDGE: 'LANDING_IELTS_KNOWLEDGE',

  // IELTS Reading
  IELTS_READING: 'IELTS Reading',
  IELTS_READING_BLOG: 'IELTS Reading Blog',
  IELTS_READING_PRACTICE: 'IELTS Reading Practice',
  IELTS_FULL_TEST: 'IELTS Online Full Test ',

  // IELTS Listening
  IELTS_LISTENING: 'IELTS Listening',
  IELTS_LISTENING_BLOG: 'IELTS Listening Blog',
  IELTS_LISTENING_PRACTICE: 'IELTS Listening Practice',

  // IELTS Writing
  IELTS_WRITING: 'IELTS Writing',
  IELTS_WRITING_BLOG: 'IELTS Writing Blog',
  IELTS_WRITING_SAMPLE: 'IELTS Writing Sample',
  IELTS_WRITING_SAMPLE_ALL: 'IELTS Writing Sample Tổng',
  IELTS_WRITING_TASK1: 'IELTS Writing Task 1 Academic',
  IELTS_WRITING_TASK2_GENERAL: 'IELTS Writing Task 1 General',
  IELTS_WRITING_TASK2_ACA: 'IELTS Writing Task 2 Academic',

  // IELTS Speaking
  IELTS_SPEAKING: 'IELTS Speaking',
  IELTS_SPEAKING_BLOG: 'IELTS Speaking Blog',
  IELTS_SPEAKING_SAMPLE: 'IELTS Speaking Sample',
  IELTS_SPEAKING_SAMPLE_ALL: 'IELTS Speaking Sample Tổng',
  IELTS_SPEAKING_PART1: 'IELTS Speaking Part 1',
  IELTS_SPEAKING_PART2: 'IELTS Speaking Part 2',
  IELTS_SPEAKING_PART3: 'IELTS Speaking Part 3',

  // K12: English 6-12
  ENGLISH_6_12: 'nav_english_6_12',
  ENGLISH_SECONDARY: 'nav_english_secondary',
  ENGLISH_HIGH_SCHOOL: 'nav_english_high_school',

  // K12: English 6-12 by grade
  ENGLISH_6_GRADE: 'nav_english_6_grade',
  ENGLISH_6_GRADE_NEW: 'nav_english_6_grade_new',
  ENGLISH_7_GRADE: 'nav_english_7_grade',
  ENGLISH_7_GRADE_NEW: 'nav_english_7_grade_new',
  ENGLISH_8_GRADE: 'nav_english_8_grade',
  ENGLISH_8_GRADE_NEW: 'nav_english_8_grade_new',
  ENGLISH_9_GRADE: 'nav_english_9_grade',
  ENGLISH_9_GRADE_NEW: 'nav_english_9_grade_new',
  ENGLISH_10_GRADE: 'nav_english_10_grade',
  ENGLISH_10_GRADE_NEW: 'nav_english_10_grade_new',
  ENGLISH_11_GRADE: 'nav_english_11_grade',
  ENGLISH_11_GRADE_NEW: 'nav_english_11_grade_new',
  ENGLISH_12_GRADE: 'nav_english_12_grade',
  ENGLISH_12_GRADE_NEW: 'nav_english_12_grade_new',

  // K12: English Exam preparation
  ENGLISH_10_GRADE_PREP: 'nav_english_10_prep',
  ENGLISH_UNIVERSITY_PREP: 'nav_english_university_prep',

  // Blog
  IELTS_REVIEW: 'Review IELTS',
  EXPERIENCE_SHARE: 'Chia sẻ kinh nghiệm',
  LANDING_BOOK_REVIEW: 'Tài liệu tự học IELTS',
} as const;
