export const ELEMENT_PARAGRAPH = 'p'; // use this instead of importing slate-plugins to reduce bundle size
export const ELEMENT_H1 = 'h1'; // use this instead of importing slate-plugins to reduce bundle size
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_2 = 'DD MMM YYYY';
export const DATE_FORMAT_3 = 'YYYY-MM-DD';
export const DATE_FORMAT_4 = 'MMMM DD, YYYY HH:mm A';
export const DATE_FORMAT_5 = 'MMMM DD, YYYY, H:mm A';
export const DATE_FORMAT_6 = 'DD-MM-YYYY';
export const DATE_TIME_FORMAT = 'HH:mm DD/MM/YYYY';
export const ƒ = 'HH:mm - DD/MM/YYYY';
export const DATE_TIME_FORMAT_2 = 'HH:mm, DD/MM/YYYY';
export const DATE_TIME_FORMAT_3 = 'HH:mm, DD/MM';
export const DATE_TIME_FORMAT_4 = 'HH:mm:ss DD MMM, yyyy';
export const DATE_TIME_FORMAT_5 = 'HH:mm - MMM DD, yyyy';
export const DATE_TIME_FORMAT_6 = 'HH:mm, MMM DD, yyyy';
export const DATE_TIME_FORMAT_7 = 'HH:mm, DD MMM';
export const DATE_TIME_FORMAT_8 = 'MMM DD, YYYY, HH:mm';

export const DATE_TIME_FORMAT_9 = 'HH:mm, MMM DD yyyy';

export const DATE_TIME_FORMAT_10 = 'HH:mm DD MMM YYYY';

export const TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT_7 = 'MMMM DD, YYYY, H:mm A'; // ex: October 12, 2020, 3:12 PM

export const DATE_FORMAT_8 = 'MMMM DD, YYYY'; // ex: October 12
export const DATE_FORMAT_9 = 'MMM DD, YYYY, H:mm A'; // ex: October 12, 2020, 3:12 PM
export const DATE_FORMAT_10 = 'Do MMM YYYY'; // ex: 8th May 2023
export const DATE_FORMAT_11 = 'MMM DD, YYYY, H:mm'; // ex: October 12, 2020, 3:12 PM
export const DATE_FORMAT_12 = 'ddd, DD/MM/YYYY'; // ex: Mon, 12/10/2020
export const DATE_FORMAT_13 = 'D/MM/YYYY'; // ex: 4/10/2020
export const DATE_FORMAT_14 = 'ddd, MMM DD, YYYY'; // ex: Mon, Dec 06, 1997
export const DATE_FORMAT_15 = 'MMM DD YYYY'; // ex: Jul 23 2023

export const DATE_FORMAT_16 = 'HH:mm MMM DD'; // ex: Jul 23 2023
export const DATE_FORMAT_17 = 'DD - ddd'; // ex: 9 - WED
export const DATE_FORMAT_18 = 'DD/MM/YYYY - ddd'; // ex: 9 - WED

export const API_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const API_DATE_FORMAT_2 = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const API_DATE_FORMAT_3 = "DD-MM-YYYY'T'HH:mm:ss'Z'";

export const UPDATED_DATE_DISPLAY_FORMAT = 'HH:mm, DD-MM-yyyy';

export const MONTH_NAME_FORMAT = 'MMM DD, yyyy';
export const TIME_MONTH_NAME_FORMAT = 'HH:mm, MMM DD, yyyy';
export const MONTH_DAY_NAME_FORMAT = 'MMMM DD';

export const ONLY_DATE_NAME_FORMAT = 'ddd';

export const DAY_MONTH_NAME_FORMAT = 'DD MMM, yyyy';
export const DAY_MONTH_FORMAT = 'DD MMM';

export const MONTH_YEAR_FORMAT = 'YYYY-MM';
export const MONTH_YEAR_FORMAT_2 = 'MMMM YYYY';
export const MONTH_YEAR_FORMAT_3 = 'MMM YYYY';

export const WEEKDAY_FORMAT_SHORT = 'ddd, DD MMM';
export const WEEKDAY_FORMAT_LONG = 'dddd, DD MMMM';
export const WEEKDAY_FORMAT = 'ddd, DD/MM';

export const DAY_MONTH_FORMAT_2 = 'DD/MM';

export const HOUR_MINUTES_FORMAT = 'HH:mm A';

export const QUARTER_YEAR_FORMAT = 'YYYY-\\QQ';
export const UTC_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZZ';

export const DAY_NAME = 'ddd';

export const DEFAULT_FILE_UPLOAD_LIMIT_IN_MB = 0.2;
export const DEFAULT_DICTATION_FILE_UPLOAD_LIMIT_IN_MB = 20;
export const Author = {
  DOL: 'DOL',
} as const;

export type Author = (typeof Author)[keyof typeof Author];

export const AccountStatus = {
  ACTIVE: 'ACTIVE',
  BANNED: 'BANNED',
} as const;

export type AccountStatus = (typeof AccountStatus)[keyof typeof AccountStatus];

export const RegistrationStatus = {
  REGISTER: 'REGISTERED',
  UN_REGISTER: 'UNREGISTERED',
} as const;

export type RegistrationStatus = (typeof RegistrationStatus)[keyof typeof RegistrationStatus];

export const PremiumType = {
  PROMOTION: 'PROMOTION',
  EMAIL_ADDED: 'EMAIL_ADDED',
  PAGE_SHARING: 'PAGE_SHARING',
  REFERRAL: 'REFERRAL',
  GIFT_CODE: 'GIFT_CODE',
  DOLPRO_PURCHASE: 'DOLPRO_PURCHASE',
  REWARD_REDEMPTION: 'REWARD_REDEMPTION',
  OTHER: 'OTHER',
  DAILY_CODE: 'DAILY_CODE',
  DOL_REVIEW: 'DOL_REVIEW',
  TOP_UP_SUPER_LMS: 'TOP_UP_SUPER_LMS',
  REGISTRATION_SUPER_LMS: 'REGISTRATION_SUPER_LMS',
  DOLPOINT_TO_DOLPRO: 'DOLPOINT_TO_DOLPRO',
} as const;

export type PremiumType = (typeof PremiumType)[keyof typeof PremiumType];

export const SkillEnum = {
  LISTENING: 'LISTENING',
  READING: 'READING',
  WRITING: 'WRITING',
  SPEAKING: 'SPEAKING',
  GRAMMAR: 'GRAMMAR',
  VOCAB: 'VOCAB',
  SUMMARIZE: 'SUMMARIZE',
  OVERALL: 'OVERALL',
  TE_LISTENING: 'TE_LISTENING',
  TE_READING: 'TE_READING',
  EXTRA_DOCS: 'EXTRA_DOCS',
  AUTO_GENERATE: 'AUTO_GENERATE',
} as const;

export type SkillEnum = (typeof SkillEnum)[keyof typeof SkillEnum];

export const SKILL_DISPLAY_NAME = {
  [SkillEnum.GRAMMAR]: 'Grammar',
  [SkillEnum.READING]: 'Reading',
  [SkillEnum.LISTENING]: 'Listening',
  [SkillEnum.WRITING]: 'Writing',
  [SkillEnum.SPEAKING]: 'Speaking',
  [SkillEnum.VOCAB]: 'Vocabulary',
  [SkillEnum.SUMMARIZE]: 'Tổng hợp',
  [SkillEnum.OVERALL]: 'Overall',
};

export interface OptionItem {
  label: string;
  value: any;
  disabled?: boolean;
}

export const IeltsSkillEnum = {
  LISTENING: 'LISTENING',
  READING: 'READING',
  WRITING: 'WRITING',
  SPEAKING: 'SPEAKING',
  GRAMMAR: 'GRAMMAR',
  VOCAB: 'VOCAB',
} as const;

export type IeltsSkillEnum = (typeof IeltsSkillEnum)[keyof typeof IeltsSkillEnum];

export const BlogContentGroup = {
  LISTENING: 'IELTS LISTENING BLOG',
  READING: 'IELTS READING BLOG',
  WRITING: 'IELTS WRITING BLOG',
  SPEAKING: 'IELTS SPEAKING BLOG',
} as const;

export type BlogContentGroup = (typeof BlogContentGroup)[keyof typeof BlogContentGroup];

export const TestStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
} as const;

export type TestStatus = (typeof TestStatus)[keyof typeof TestStatus];

export const SectionStatus = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  ARCHIVED: 'ARCHIVED',
  EDITING: 'EDITING',
  SUBMITTED: 'DONE',
} as const;

export type SectionStatus = (typeof SectionStatus)[keyof typeof SectionStatus];

export const QuestionTypeEnum = {
  DEFAULT: 0,
  SINGLE_ANSWER: 'SINGLE_ANSWER',
  TFN_ANSWER: 'TFN_ANSWER',
  YNN_ANSWER: 'YNN_ANSWER',
  MULTIPLE_ANSWER: 'MULTIPLE_ANSWER',
  SHORT_ANSWER: 'SHORT_ANSWER',
  NOTE_COMPLETION_NO_HINT: 'NOTE_COMPLETION_NO_HINT',
  SENTENCE_COMPLETE: 'SENTENCE_COMPLETE',
  MATCHING_PARAGRAPH: 'MATCHING_PARAGRAPH',
  MATCHING_FEATURE_NAME: 'MATCHING_FEATURE_NAME',
  MATCHING_HEADING: 'MATCHING_HEADING',
  MATCHING_ENDING: 'MATCHING_ENDING',
  NOTE_COMPLETION_WITH_HINT: 'NOTE_COMPLETION_WITH_HINT',
  FLOWCHART_ANSWER: 'FLOWCHART_ANSWER',
  TABLE_COMPLETION: 'TABLE_COMPLETION',
  DIAGRAM_LABEL_COMPLETION: 'DIAGRAM_LABEL_COMPLETION',
  DRAG_IN: 'DRAG_IN',
  DRAG_OUT: 'DRAG_OUT',
  FLOW_CHART_COMPLEX: 'FLOW_CHART_COMPLEX',
} as const;

export type QuestionTypeEnum = (typeof QuestionTypeEnum)[keyof typeof QuestionTypeEnum];

export const TestStatusEnum = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  NOT_STARTED: 'NOT_STARTED',
} as const;

export type TestStatusEnum = (typeof TestStatusEnum)[keyof typeof TestStatusEnum];

export const PassageTypeEnum = {
  REGULAR: 'REGULAR',
  ANNOTATE: 'ANNOTATE',
} as const;

export type PassageTypeEnum = (typeof PassageTypeEnum)[keyof typeof PassageTypeEnum];

export const ScriptTypeEnum = {
  MONOLOG: 'MONOLOG',
  CONVERSATION: 'CONVERSATION',
} as const;

export type ScriptTypeEnum = (typeof ScriptTypeEnum)[keyof typeof ScriptTypeEnum];

export type AvailableSetupTypeEnum = PassageTypeEnum | ScriptTypeEnum;

export const MarkedByEnum = {
  ALPHABET: 'ALPHABET',
  ABECEDARIUM_LATINUM: 'ABECEDARIUM_LATINUM',
  NUMBER: 'NUMBER',
} as const;

export type MarkedByEnum = (typeof MarkedByEnum)[keyof typeof MarkedByEnum];

export const MarkedTypeEnum = {
  CORRECT: 'CORRECT',
  MISSING: 'MISSING',
  WRONG: 'WRONG',
} as const;

export type MarkedTypeEnum = (typeof MarkedTypeEnum)[keyof typeof MarkedTypeEnum];

export const ExplanationTypeEnum = {
  MAIN_IDEA: 'MAIN_IDEA',
  LOCATING_DETAIL: 'LOCATING_DETAIL',
  LISTENING_EXPLANATION: 'LISTENING_EXPLANATION',
  DETAIL_ONLY: 'DETAIL_ONLY',
} as const;

export type ExplanationTypeEnum = (typeof ExplanationTypeEnum)[keyof typeof ExplanationTypeEnum];

export const TestModeEnum = {
  ADMIN_SETUP: 'ADMIN_SETUP',
  DO_TEST: 'DO_TEST',
  TEST_RESULT: 'TEST_RESULT',
  EXPLANATION: 'EXPLANATION',
  VIEW_TEST_QUESTION: 'VIEW_TEST_QUESTION',
} as const;

export type TestModeEnum = (typeof TestModeEnum)[keyof typeof TestModeEnum];

export const SeoStatusEnum = {
  PUBLISH: 'PUBLISH',
  UN_PUBLISH: 'UN_PUBLISH',
  EDITING: 'EDITING',
} as const;

export type SeoStatusEnum = (typeof SeoStatusEnum)[keyof typeof SeoStatusEnum];

export const QUESTION_NAME = {
  SINGLE_ANSWER: 'Single choice',
  TFN_ANSWER: 'T/F/NG',
  YNN_ANSWER: 'Y/N/NG',
  MULTIPLE_ANSWER: 'Multiple choice',
  SHORT_ANSWER: 'Short Answer Questions',
  NOTE_COMPLETION_NO_HINT: 'Completion no hint',
  SENTENCE_COMPLETE: 'Sentence complete',
  MATCHING_PARAGRAPH: 'Matching paragraph',
  MATCHING_FEATURE_NAME: 'Matching feature name',
  MATCHING_HEADING: 'Matching heading',
  MATCHING_ENDING: 'Matching ending',
  NOTE_COMPLETION_WITH_HINT: 'Completion with hint',
  FLOWCHART_ANSWER: 'Flow chart',
  TABLE_COMPLETION: 'Table completion',
  DIAGRAM_LABEL_COMPLETION: 'Diagram label',
  DRAG_IN: 'Drag in',
  DRAG_OUT: 'Drag out',
  FLOW_CHART_COMPLEX: 'Flow Chart Complex',
};

export const ModalNameEnum = {
  DEFAULT: 'DEFAULT',
  QUESTION_PALETTES: 'QUESTION_PALETTES',
  TEST_PARAGRAPH: 'TEST_PARAGRAPH',
} as const;

export type ModalNameEnum = (typeof ModalNameEnum)[keyof typeof ModalNameEnum];

export const QUESTION_CSS_VARIABLES = {
  QUESTION_CIRCLE_LR_PADDING: '--question-circle-left-right-padding',
};

export const ALL_SKILL = 'All skills';

export const ALL = 'All';

export const LATEST = 'LATEST';

export const OLDEST = 'OLDEST';

export const TEST_SECTION_STATUS_OPTIONS = [
  { label: ALL, value: ALL },
  { label: SectionStatus.EDITING, value: SectionStatus.EDITING },
  {
    label: SectionStatus.DRAFT,
    value: SectionStatus.DRAFT,
  },
  {
    label: SectionStatus.PUBLISHED,
    value: SectionStatus.PUBLISHED,
  },
];

export const DICTATION_STATUS_OPTIONS = [
  { label: ALL, value: undefined },
  { label: SectionStatus.EDITING, value: SectionStatus.EDITING },
  { label: SectionStatus.DRAFT, value: SectionStatus.DRAFT },
  { label: SectionStatus.PUBLISHED, value: SectionStatus.PUBLISHED },
];
export const DICTATION_PROGRAM_OPTIONS = [
  { label: ALL, value: undefined },
  { label: 'IELTS', value: 'IELTS' },
  { label: 'TOEIC', value: 'TOEIC' },
];
export const DICTATION_SECTION_OPTIONS = [
  { label: ALL, value: undefined },
  { label: 'Section 1', value: 'SECTION_1' },
  { label: 'Section 2', value: 'SECTION_2' },
  { label: 'Section 3', value: 'SECTION_3' },
  { label: 'Section 4', value: 'SECTION_4' },
];

export const DICTATION_TYPE_OPTIONS = [
  { label: ALL, value: undefined },
  { label: 'Audio', value: 'AUDIO' },
  { label: 'Video', value: 'VIDEO' },
];
export const BLOG_MANAGEMENT_STATUS_OPTIONS = [
  { label: ALL, value: undefined },
  { label: SectionStatus.EDITING, value: SectionStatus.EDITING },
  {
    label: SectionStatus.DRAFT,
    value: SectionStatus.DRAFT,
  },
  {
    label: SectionStatus.SUBMITTED,
    value: SectionStatus.PUBLISHED,
  },
];

export const PUBLISHED_EDITING = [
  { label: ALL, value: undefined },
  { label: SectionStatus.EDITING, value: SectionStatus.EDITING },
  {
    label: SectionStatus.SUBMITTED,
    value: SectionStatus.PUBLISHED,
  },
];

export const HAVE_NO_HAVE_STATUS_OPTIONS = [
  { label: ALL, value: undefined },
  { label: 'Có', value: true },
  {
    label: 'Chưa có',
    value: false,
  },
];

export const DolAppEnum = {
  DOLVN: 'DOLVN',
} as const;

export type DolAppEnum = (typeof DolAppEnum)[keyof typeof DolAppEnum];

export const LINK_STATUS = {
  USED: 'USED',
  UN_USED: 'UN_USED',
};
export const SEO_STATUS = {
  PUBLISH: 'PUBLISH',
  UN_PUBLISH: 'UN_PUBLISH',
};

export const USED_OPTION_BOOLEAN: OptionItem[] = [
  { label: ALL, value: undefined },
  { label: LINK_STATUS.USED, value: true },
  {
    label: 'Un-used',
    value: false,
  },
];

export const ASSINGMENT_USED_OPTION: OptionItem[] = [
  { label: ALL, value: undefined },
  { label: LINK_STATUS.USED, value: 'USED' },
  {
    label: 'Un-used',
    value: 'UNUSED',
  },
];
export const BLOG_USED_OPTION: OptionItem[] = [
  { label: ALL, value: undefined },
  { label: 'Used', value: true },
  { label: 'Un-used', value: false },
];

export const SKILL_OPTIONS: OptionItem[] = [
  { label: ALL_SKILL, value: ALL_SKILL },
  { label: SkillEnum.LISTENING, value: SkillEnum.LISTENING },
  {
    label: SkillEnum.READING,
    value: SkillEnum.READING,
  },
];

export const TestTypeEnum = {
  PRACTICE_TEST: 'PRACTICE_TEST',
  FULL_TEST: 'FULL_TEST',
} as const;

export type TestTypeEnum = (typeof TestTypeEnum)[keyof typeof TestTypeEnum];

export const LandingTestStatusEnum = {
  LISTED: 'LISTED',
  UN_LISTED: 'UN_LISTED',
  NOT_LISTED_YET: 'NOT_LISTED_YET',
} as const;

export type LandingTestStatusEnum =
  (typeof LandingTestStatusEnum)[keyof typeof LandingTestStatusEnum];

export const LANDING_TEST_STATUS = {
  LISTED: 'Listed',
  UNLISTED: 'Unlisted',
  UN_LISTED: 'Unlisted',
  NOT_LISTED_YET: 'Not Listed Yet',
};

export const ListingStatusEnum = {
  LISTED: 'LISTED',
  UNLISTED: 'UNLISTED',
  NOT_LISTED_YET: 'NOT_LISTED_YET',
} as const;

export type ListingStatusEnum = (typeof ListingStatusEnum)[keyof typeof ListingStatusEnum];

export const PRACTICE_LANDING_STATUS_OPTIONS = [
  {
    label: ALL,
    value: undefined,
  },
  {
    label: LANDING_TEST_STATUS.LISTED,
    value: LandingTestStatusEnum.LISTED,
  },
  {
    label: LANDING_TEST_STATUS.UN_LISTED,
    value: 'UN_LISTED',
  },
  {
    label: LANDING_TEST_STATUS.NOT_LISTED_YET,
    value: LandingTestStatusEnum.NOT_LISTED_YET,
  },
];

export const PREMIUMUM_TYPE_OPTIONS = [
  {
    label: ALL,
    value: undefined,
  },
  {
    label: 'Gift code',
    value: PremiumType.GIFT_CODE,
  },
  {
    label: 'Promotion',
    value: PremiumType.PROMOTION,
  },
  {
    label: 'Đổi thưởng ',
    value: PremiumType.REWARD_REDEMPTION,
  },
  {
    label: 'Mua gói',
    value: PremiumType.DOLPRO_PURCHASE,
  },
  {
    label: 'Other',
    value: PremiumType.OTHER,
  },
];

export const PageTypeEnum = {
  LANDING_HOME: 'LANDING_HOME',
  LANDING_FULL_TEST: 'LANDING_FULL_TEST',
  LANDING_READING_PRACTICE_TEST: 'LANDING_READING_PRACTICE_TEST',
  LANDING_LISTENING_PRACTICE_TEST: 'LANDING_LISTENING_PRACTICE_TEST',
  LANDING_DICTATION: 'LANDING_DICTATION',
  VIEW_TRANSCRIPT: 'VIEW_TRANSCRIPT',
  VIEW_TEST_QUESTION: 'VIEW_TEST_QUESTION',
  VIEW_VOCAB: 'VIEW_VOCAB',
  VIEW_SOLUTION: 'VIEW_SOLUTION',
  DO_TEST: 'DO_TEST',
  BOOK: 'BOOK',
  VIEW_BLOG: 'VIEW_BLOG',
  DO_DICTATION: 'DO_DICTATION',
  READING_IELTS_BLOG: 'READING_IELTS_BLOG',
  LISTENING_IELTS_BLOG: 'LISTENING_IELTS_BLOG',
  SPEAKING_IELTS_BLOG: 'SPEAKING_IELTS_BLOG',
  WRITING_IELTS_BLOG: 'WRITING_IELTS_BLOG',
  LANDING_READING_IELTS_BLOG: 'LANDING_READING_IELTS_BLOG',
  LANDING_LISTENING_IELTS_BLOG: 'LANDING_LISTENING_IELTS_BLOG',
  LANDING_SPEAKING_IELTS_BLOG: 'LANDING_SPEAKING_IELTS_BLOG',
  LANDING_WRITING_IELTS_BLOG: 'LANDING_WRITING_IELTS_BLOG',
  LANDING_WRITING_SAMPLE: 'LANDING_WRITING_SAMPLE',
  LANDING_WRITING_SAMPLE_T1: 'LANDING_WRITING_SAMPLE_T1',
  LANDING_WRITING_SAMPLE_T2A: 'LANDING_WRITING_SAMPLE_T2A',
  LANDING_WRITING_SAMPLE_T2G: 'LANDING_WRITING_SAMPLE_T2G',
  LANDING_SPEAKING_SAMPLE: 'LANDING_SPEAKING_SAMPLE',
  LANDING_SPEAKING_SAMPLE_P1: 'LANDING_SPEAKING_SAMPLE_P1',
  LANDING_SPEAKING_SAMPLE_P2: 'LANDING_SPEAKING_SAMPLE_P2',
  LANDING_SPEAKING_SAMPLE_P3: 'LANDING_SPEAKING_SAMPLE_P3',
  WRITING_SAMPLE_DETAIL: 'WRITING_SAMPLE_DETAIL',
  SPEAKING_SAMPLE_DETAIL: 'SPEAKING_SAMPLE_DETAIL',
  LANDING_BOOK_REVIEW: 'LANDING_BOOK_REVIEW',
  LANDING_CENTER_REVIEW: 'LANDING_CENTER_REVIEW',
  BOOK_REVIEW_IELTS_BLOG: 'BOOK_REVIEW_IELTS_BLOG',
  CENTER_REVIEW_IELTS_BLOG: 'CENTER_REVIEW_IELTS_BLOG',
  TEACHER_REVIEW_IELTS_BLOG: 'TEACHER_REVIEW_IELTS_BLOG',
  COURSE_REVIEW_IELTS_BLOG: 'COURSE_REVIEW_IELTS_BLOG',
  IELTS_INFO_BLOG: 'IELTS_INFO_BLOG',
  LANDING_IELTS_REVIEW: 'LANDING_IELTS_REVIEW',
  LANDING_GRAMMAR_IELTS_BLOG: 'LANDING_GRAMMAR_IELTS_BLOG',
  LANDING_VOCAB_IELTS_BLOG: 'LANDING_VOCAB_IELTS_BLOG',
  VOCAB_IELTS_BLOG: 'VOCAB_IELTS_BLOG',
  GRAMMAR_IELTS_BLOG: 'GRAMMAR_IELTS_BLOG',
  LANDING_DAILY_LEARNING_NEWS: 'LANDING_DAILY_LEARNING_NEWS',
  DAILY_LEARNING_NEWS: 'DAILY_LEARNING_NEWS',
  LANDING_DICTIONARY: 'LANDING_DICTIONARY',
  DICTIONARY_DETAIL: 'DICTIONARY_DETAIL',
  LANDING_IELTS_KNOWLEDGE: 'LANDING_IELTS_KNOWLEDGE',
  DOL_NEWS_IELTS_BLOG: 'DOL_NEWS_IELTS_BLOG',
  DOL_LINEAR_IELTS_BLOG: 'LINEARTHINKING_IELTS_BLOG',
  STUDENT_STORY_IELTS_BLOG: 'STUDENT_STORY_IELTS_BLOG',
  LAND_K12_EXAM_BOOK: 'LAND_K12_EXAM_BOOK',
  K12_EXAM_BOOK: 'K12_EXAM_BOOK',
  K12_VIEW_SOLUTION: 'K12_VIEW_SOLUTION',
  K12_DO_TEST: 'K12_DO_TEST',
  K12_VIEW_TEST_QUESTION: 'K12_VIEW_TEST_QUESTION',
} as const;

export type PageTypeEnum = (typeof PageTypeEnum)[keyof typeof PageTypeEnum];

export const PageTypeForWritingSampleAsPagePath = [
  PageTypeEnum.LANDING_WRITING_SAMPLE,
  PageTypeEnum.LANDING_WRITING_SAMPLE_T1,
  PageTypeEnum.LANDING_WRITING_SAMPLE_T2A,
  PageTypeEnum.LANDING_WRITING_SAMPLE_T2G,
];

export const PageTypeForSpeakingSampleAsPagePath = [
  PageTypeEnum.LANDING_SPEAKING_SAMPLE,
  PageTypeEnum.LANDING_SPEAKING_SAMPLE_P1,
  PageTypeEnum.LANDING_SPEAKING_SAMPLE_P2,
  PageTypeEnum.LANDING_SPEAKING_SAMPLE_P3,
];

export const PageTypeForLandingBlogAsCategoryPath = [
  PageTypeEnum.LANDING_READING_IELTS_BLOG,
  PageTypeEnum.LANDING_LISTENING_IELTS_BLOG,
  PageTypeEnum.LANDING_SPEAKING_IELTS_BLOG,
  PageTypeEnum.LANDING_WRITING_IELTS_BLOG,

  PageTypeEnum.LANDING_WRITING_SAMPLE,
  PageTypeEnum.LANDING_WRITING_SAMPLE_T1,
  PageTypeEnum.LANDING_WRITING_SAMPLE_T2A,
  PageTypeEnum.LANDING_WRITING_SAMPLE_T2G,
  PageTypeEnum.LANDING_SPEAKING_SAMPLE,
  PageTypeEnum.LANDING_SPEAKING_SAMPLE_P1,
  PageTypeEnum.LANDING_SPEAKING_SAMPLE_P2,
  PageTypeEnum.LANDING_SPEAKING_SAMPLE_P3,

  PageTypeEnum.LANDING_IELTS_REVIEW,
  PageTypeEnum.LANDING_GRAMMAR_IELTS_BLOG,
  PageTypeEnum.LANDING_VOCAB_IELTS_BLOG,

  PageTypeEnum.LANDING_BOOK_REVIEW,
  PageTypeEnum.LANDING_CENTER_REVIEW,

  PageTypeEnum.LANDING_IELTS_KNOWLEDGE,
];

export const PageTypeForSampleAsCategoryPath = [
  PageTypeEnum.LANDING_WRITING_SAMPLE,
  PageTypeEnum.LANDING_WRITING_SAMPLE_T1,
  PageTypeEnum.LANDING_WRITING_SAMPLE_T2A,
  PageTypeEnum.LANDING_WRITING_SAMPLE_T2G,
  PageTypeEnum.LANDING_SPEAKING_SAMPLE,
  PageTypeEnum.LANDING_SPEAKING_SAMPLE_P1,
  PageTypeEnum.LANDING_SPEAKING_SAMPLE_P2,
  PageTypeEnum.LANDING_SPEAKING_SAMPLE_P3,
];
export const PageTypeForSampleAsPagePath = [
  PageTypeEnum.WRITING_SAMPLE_DETAIL,
  PageTypeEnum.SPEAKING_SAMPLE_DETAIL,
];

export const PageTypeForLandingBlogAsPagePath = [
  PageTypeEnum.WRITING_SAMPLE_DETAIL,
  PageTypeEnum.SPEAKING_SAMPLE_DETAIL,
  PageTypeEnum.BOOK_REVIEW_IELTS_BLOG,
  PageTypeEnum.IELTS_INFO_BLOG,

  PageTypeEnum.VOCAB_IELTS_BLOG,
  PageTypeEnum.GRAMMAR_IELTS_BLOG,

  PageTypeEnum.READING_IELTS_BLOG,
  PageTypeEnum.LISTENING_IELTS_BLOG,
  PageTypeEnum.SPEAKING_IELTS_BLOG,
  PageTypeEnum.WRITING_IELTS_BLOG,

  PageTypeEnum.DOL_NEWS_IELTS_BLOG,
  PageTypeEnum.DOL_LINEAR_IELTS_BLOG,
  PageTypeEnum.STUDENT_STORY_IELTS_BLOG,
  PageTypeEnum.VIEW_BLOG,
];

export const PageTypeForLandingBlogAsCategoryPathWithoutSample = [
  PageTypeEnum.LANDING_READING_IELTS_BLOG,
  PageTypeEnum.LANDING_LISTENING_IELTS_BLOG,
  PageTypeEnum.LANDING_SPEAKING_IELTS_BLOG,
  PageTypeEnum.LANDING_WRITING_IELTS_BLOG,

  PageTypeEnum.LANDING_IELTS_REVIEW,
  PageTypeEnum.LANDING_GRAMMAR_IELTS_BLOG,
  PageTypeEnum.LANDING_VOCAB_IELTS_BLOG,

  PageTypeEnum.LANDING_BOOK_REVIEW,
  PageTypeEnum.LANDING_CENTER_REVIEW,

  PageTypeEnum.LANDING_IELTS_KNOWLEDGE,
];
export const PageTypeForLandingBlogAsPagePathWithoutSample = [
  PageTypeEnum.BOOK_REVIEW_IELTS_BLOG,
  PageTypeEnum.IELTS_INFO_BLOG,

  PageTypeEnum.VOCAB_IELTS_BLOG,
  PageTypeEnum.GRAMMAR_IELTS_BLOG,

  PageTypeEnum.READING_IELTS_BLOG,
  PageTypeEnum.LISTENING_IELTS_BLOG,
  PageTypeEnum.SPEAKING_IELTS_BLOG,
  PageTypeEnum.WRITING_IELTS_BLOG,

  PageTypeEnum.DOL_NEWS_IELTS_BLOG,
  PageTypeEnum.DOL_LINEAR_IELTS_BLOG,
  PageTypeEnum.STUDENT_STORY_IELTS_BLOG,
  PageTypeEnum.VIEW_BLOG,
];

export const PageTypeForLandingDictionaryPath = [
  PageTypeEnum.LANDING_DICTIONARY,
  PageTypeEnum.DICTIONARY_DETAIL,
];

export const PageTypeForLandingBlog = [
  ...PageTypeForLandingBlogAsCategoryPath,
  ...PageTypeForLandingBlogAsPagePath,
];

export const PageTypeForBlogWithoutSample = [
  ...PageTypeForLandingBlogAsCategoryPathWithoutSample,
  ...PageTypeForLandingBlogAsPagePathWithoutSample,
];

export const PageTypeForTuHoc = Object.values(PageTypeEnum)
  .filter((t) => !PageTypeForBlogWithoutSample.includes(t as any))
  .filter((t) => !PageTypeForLandingDictionaryPath.includes(t as any))
  .filter((t) => t !== PageTypeEnum.DAILY_LEARNING_NEWS);

export const STATIC_PAGE_TYPES = Object.values(PageTypeEnum).filter(
  (type) => type.startsWith('LANDING') && type.includes('K12')
);

export const BlogPageEnum = {
  WHAT_IS_LINEAR_THINKING: 'WHAT_IS_LINEAR_THINKING',
  LINEAR_THINKING_IN_READING: 'LINEAR_THINKING_IN_READING',
  LINEAR_THINKING_IN_WRITING: 'LINEAR_THINKING_IN_WRITING',
  LINEAR_THINKING_IN_SPEAKING: 'LINEAR_THINKING_IN_SPEAKING',
  LINEAR_THINKING_IN_LISTENING: 'LINEAR_THINKING_IN_LISTENING',
  LINEAR_THINKING_IN_VOCAB: 'LINEAR_THINKING_IN_VOCAB',
  LINEAR_THINKING_IN_GRAMMAR: 'LINEAR_THINKING_IN_GRAMMAR',
  LINEAR_THINKING_COMPARE_WITH_OTHERS: 'LINEAR_THINKING_COMPARE_WITH_OTHERS',
  LINEAR_THINKING_BENEFIT_LOW_LEVEL: 'LINEAR_THINKING_BENEFIT_LOW_LEVEL',
  LINEAR_THINKING_BENEFIT_HIGH_LEVEL: 'LINEAR_THINKING_BENEFIT_HIGH_LEVEL',
} as const;

export type BlogPageEnum = (typeof BlogPageEnum)[keyof typeof BlogPageEnum];

export const BookStatusEnum = {
  NOT_LISTED_YET: 'NOT_LISTED_YET',
  LISTED: 'LISTED',
  UNLISTED: 'UNLISTED',
} as const;

export type BookStatusEnum = (typeof BookStatusEnum)[keyof typeof BookStatusEnum];

export const ReadingViewModeEnum = {
  PASSAGE: 0,
  QUESTION: 1,
} as const;

export type ReadingViewModeEnum = (typeof ReadingViewModeEnum)[keyof typeof ReadingViewModeEnum];

export const StepStatus = {
  WAIT: 'wait',
  PROCESS: 'process',
  FINISH: 'finish',
  ERROR: 'error',
};

export const LEFT_WIDTH = '35%';
export const PADDING_LR = 64;

export const ImageModeEnum = {
  COVER: 'COVER',
  IMAGE: 'IMAGE',
} as const;

export type ImageModeEnum = (typeof ImageModeEnum)[keyof typeof ImageModeEnum];

export const DeviceTypeEnum = {
  MOBILE: 'MOBILE',
  DESKTOP: 'DESKTOP',
} as const;

export type DeviceTypeEnum = (typeof DeviceTypeEnum)[keyof typeof DeviceTypeEnum];

export const zIndex = {
  LISTENING_REVIEW: 21,
  TEST_CONTENT: 21,
  TIME_LIMIT: 22,

  HEADER: 200,
  FOOTER: 200,
  AUDIO_PLAYER_WRAPPER: 201,

  BOTTOM_DRAWER: 300,

  HEADER_MODAL: 100,
};

export const BOOK_SERIES = [
  'Cambridge',
  'Actual Test',
  'Practice Test Plus',
  'Official Guide To Ielts',
  'Ielts Trainer',
  'Other',
];

export interface DictationSentence {
  key: number;
  content: string;
  words: DictationWord[];
}

export interface StudentDictationSentence extends DictationSentence {
  words: StudentDictationWord[];
  status: ResourceStatusEnum;
}

export interface DictationWord {
  key: number;
  value: string;
  type: DictationWordTypeEnum;
  /**
   * Can be generated in client side. Don't use `key`
   */
  id?: string;
  length?: number;
}

export const DictationWordTypeEnum = {
  BLANK: 'BLANK',
  PUNCTUATION: 'PUNCTUATION',
  TEXT: 'TEXT',
} as const;

export type DictationWordTypeEnum =
  (typeof DictationWordTypeEnum)[keyof typeof DictationWordTypeEnum];

export const DictationWordStatusEnum = {
  UNTOUCHED: 'untouched',
  CORRECT: 'correct',
  WRONG_POSITION: 'wrong-position',
  INCORRECT: 'incorrect',
  DIRTY: 'dirty',
  READ_ONLY: 'read-only',
} as const;

export type DictationWordStatusEnum =
  (typeof DictationWordStatusEnum)[keyof typeof DictationWordStatusEnum];

export const DictationSentenceStatusEnum = {
  UNTOUCHED: 'untouched',
  IN_PROGRESS: 'in-progress',
  CORRECT: 'correct',
  INCORRECT: 'incorrect',
  WRONG_POSITION: 'wrong-position',
} as const;

export type DictationSentenceStatusEnum =
  (typeof DictationSentenceStatusEnum)[keyof typeof DictationSentenceStatusEnum];

export const DictationSentenceStudentStatusEnum = {
  UNTOUCHED: 'UNTOUCHED',
  IN_PROGRESS: 'IN_PROGRESS',
  CORRECT: 'CORRECT',
  INCORRECT: 'INCORRECT',
  WRONG_POSITION: 'WRONG_POSITION',
} as const;

export type DictationSentenceStudentStatusEnum =
  (typeof DictationSentenceStudentStatusEnum)[keyof typeof DictationSentenceStudentStatusEnum];

export interface StudentDictationWord extends DictationWord {
  studentValue?: string;
  status?: DictationWordStatusEnum;
}

/**
 * unit: pixel
 */
export const dictationWordLength = {
  A: 14,
  B: 14,
  C: 15,
  D: 15,
  E: 12,
  F: 12,
  G: 15,
  H: 15,
  I: 6,
  J: 11,
  K: 14,
  L: 12,
  M: 18,
  N: 16,
  O: 16,
  P: 13,
  Q: 16,
  R: 13,
  S: 13,
  T: 13,
  U: 15,
  V: 14,
  W: 17,
  X: 13,
  Y: 14,
  Z: 13,
  a: 12,
  b: 13,
  c: 12,
  d: 13,
  e: 12,
  f: 8,
  g: 13,
  h: 12,
  i: 5,
  j: 5,
  k: 11,
  l: 5,
  m: 18,
  n: 12,
  o: 12,
  p: 13,
  q: 13,
  r: 8,
  s: 11,
  t: 8,
  u: 12,
  v: 12,
  x: 11,
  w: 19,
  y: 12,
  z: 11,
  '0': 13,
  '1': 9,
  '2': 13,
  '3': 13,
  '4': 13,
  '5': 13,
  '6': 13,
  '7': 12,
  '8': 13,
  '9': 13,
  '-': 13,
  "'": 2,
  '’': 4,
  '.': 4,
  ',': 4,
  DEFAULT: 12,
};

export const ResourceStatusEnum = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  EDITING: 'EDITING',
} as const;

export type ResourceStatusEnum = (typeof ResourceStatusEnum)[keyof typeof ResourceStatusEnum];

export const AvailableForEnum = {
  NOBODY: 'NOBODY',
  ALL: 'ALL',
  MARKETER: 'MARKETER',
  DATA_ENTRY: 'DATA_ENTRY',
  ACADEMIC: 'ACADEMIC',
  ORC_MANAGER: 'ORC_MANAGER',
  ORC_MARKETER: 'ORC_MARKETER',
  PROGRAM_DIRECTION: 'PROGRAM_DIRECTION',
  NO_PUBLISH: 'NO_PUBLISH',
  PD: 'PD',
  FOR_PROGRAM_DIRECTOR: 'FOR_PROGRAM_DIRECTOR',
  FOR_ALL: 'FOR_ALL',
  FOR_PROGRAM_DIRECTION: 'FOR_PROGRAM_DIRECTION',
  FOR_MARKETER: 'FOR_MARKETER',
  FOR_ACA: 'FOR_ACA',
} as const;

export type AvailableForEnum = (typeof AvailableForEnum)[keyof typeof AvailableForEnum];

export const GrammarTypeEnum = {
  EXERCISE: 'EXERCISE',
  THEORY: 'THEORY',
} as const;

export type GrammarTypeEnum = (typeof GrammarTypeEnum)[keyof typeof GrammarTypeEnum];

export const BlogVocabTypeEnum = {
  COMMON: 'COMMON',
  LEARNING: 'LEARNING',
  SPEAKING: 'SPEAKING',
  WRITING: 'WRITING',
  TOPIC: 'TOPIC',
} as const;

export type BlogVocabTypeEnum = (typeof BlogVocabTypeEnum)[keyof typeof BlogVocabTypeEnum];

export const ActiveStatusEnum = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const;

export type ActiveStatusEnum = (typeof ActiveStatusEnum)[keyof typeof ActiveStatusEnum];

export const HttpMethodEnum = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
} as const;

export type HttpMethodEnum = (typeof HttpMethodEnum)[keyof typeof HttpMethodEnum];

export const LevelEnum = {
  EASY: 'EASY',
  MEDIUM: 'MEDIUM',
  HARD: 'HARD',
} as const;

export type LevelEnum = (typeof LevelEnum)[keyof typeof LevelEnum];

export const EnglishLevel = {
  BASIC: 'BASIC',
  INTERMEDIATE: 'INTERMEDIATE',
  ADVANCED: 'ADVANCED',
  INTENSIVE: 'INTENSIVE',
} as const;

export const QUESTION_OPTION_PADDING = -12;
export const QUESTION_NO_WRAPPER_MARGIN_BOTTOM = 12;

export const TopicUseForEnum = {
  BOOK: 'BOOK',
  PRACTICE_READING: 'PRACTICE_READING',
  PRACTICE_LISTENING: 'PRACTICE_LISTENING',
  DICTATION: 'DICTATION',
  SAMPLE: 'SAMPLE',
  NEWS: 'NEWS',
} as const;

export type TopicUseForEnum = (typeof TopicUseForEnum)[keyof typeof TopicUseForEnum];

export const TrendingType = {
  SEO_PAGE: 'SEO_PAGE',
  READING_PRACTICE_TEST: 'READING_PRACTICE_TEST',
  LISTENING_PRACTICE_TEST: 'LISTENING_PRACTICE_TEST',
  WRITING_PRACTICE_TEST: 'WRITING_PRACTICE_TEST',
  SPEAKING_PRACTICE_TEST: 'SPEAKING_PRACTICE_TEST',
  DICTATION: 'DICTATION',
  BOOK: 'BOOK',
  READING_IELTS_BLOG: 'READING_IELTS_BLOG',
  LISTENING_IELTS_BLOG: 'LISTENING_IELTS_BLOG',
  SPEAKING_IELTS_BLOG: 'SPEAKING_IELTS_BLOG',
  WRITING_IELTS_BLOG: 'WRITING_IELTS_BLOG',
  BOOK_REVIEW_IELTS_BLOG: 'BOOK_REVIEW_IELTS_BLOG',
  CENTER_REVIEW_IELTS_BLOG: 'CENTER_REVIEW_IELTS_BLOG',
  TEACHER_REVIEW_IELTS_BLOG: 'TEACHER_REVIEW_IELTS_BLOG',
  COURSE_REVIEW_IELTS_BLOG: 'COURSE_REVIEW_IELTS_BLOG',
  IELTS_INFO_BLOG: 'IELTS_INFO_BLOG',
  BOOK_ON_IELTS_INFO_BLOG: 'BOOK_ON_IELTS_INFO_BLOG',
  CENTER_ON_IELTS_INFO_BLOG: 'CENTER_ON_IELTS_INFO_BLOG',
  WRITING_SAMPLE_BLOG: 'WRITING_SAMPLE_BLOG',
  WRITING_SAMPLE_BLOG_WRITING_TASK_1_GUIDELINE_BLOGS:
    'WRITING_SAMPLE_BLOG_WRITING_TASK_1_GUIDELINE_BLOGS',
  WRITING_SAMPLE_BLOG_WRITING_TASK_2_SAMPLES: 'WRITING_SAMPLE_BLOG_WRITING_TASK_2_SAMPLES',
  WRITING_SAMPLE_BLOG_WRITING_TASK_2_GUIDELINE_BLOGS:
    'WRITING_SAMPLE_BLOG_WRITING_TASK_2_GUIDELINE_BLOGS',
  WRITING_SAMPLE: 'WRITING_SAMPLE',
  WRITING_SAMPLE_WST_1: 'WRITING_SAMPLE_WST_1',
  WRITING_SAMPLE_WST_2_ACADEMIC: 'WRITING_SAMPLE_WST_2_ACADEMIC',
  WRITING_SAMPLE_WST_2_GENERAL: 'WRITING_SAMPLE_WST_2_GENERAL',
  WRITING_SAMPLE_TASK_1: 'WRITING_SAMPLE_TASK_1',
  WRITING_SAMPLE_TASK_2_ACADEMIC: 'WRITING_SAMPLE_TASK_2_ACADEMIC',
  WRITING_SAMPLE_TASK_2_GENERAL: 'WRITING_SAMPLE_TASK_2_GENERAL',
  WRITING_SAMPLE_NEWLY_UPDATED: 'WRITING_SAMPLE_NEWLY_UPDATED',
  SPEAKING_SAMPLE_BLOG: 'SPEAKING_SAMPLE_BLOG',
  SPEAKING_SAMPLE_BLOG_SPEAKING_PART_1_GUIDELINE_BLOGS:
    'SPEAKING_SAMPLE_BLOG_SPEAKING_PART_1_GUIDELINE_BLOGS',
  SPEAKING_SAMPLE_BLOG_SPEAKING_PART_2_SAMPLES: 'SPEAKING_SAMPLE_BLOG_SPEAKING_PART_2_SAMPLES',
  SPEAKING_SAMPLE_BLOG_SPEAKING_PART_2_GUIDELINE_BLOGS:
    'SPEAKING_SAMPLE_BLOG_SPEAKING_PART_2_GUIDELINE_BLOGS',
  SPEAKING_SAMPLE_BLOG_SPEAKING_PART_3_SAMPLES: 'SPEAKING_SAMPLE_BLOG_SPEAKING_PART_3_SAMPLES',
  SPEAKING_SAMPLE_BLOG_SPEAKING_PART_3_GUIDELINE_BLOGS:
    'SPEAKING_SAMPLE_BLOG_SPEAKING_PART_3_GUIDELINE_BLOGS',
  SPEAKING_SAMPLE: 'SPEAKING_SAMPLE',
  SPEAKING_SAMPLE_SSP_1: 'SPEAKING_SAMPLE_SSP_1',
  SPEAKING_SAMPLE_SSP_2: 'SPEAKING_SAMPLE_SSP_2',
  SPEAKING_SAMPLE_SSP_3: 'SPEAKING_SAMPLE_SSP_3',
  SPEAKING_SAMPLE_PART_1: 'SPEAKING_SAMPLE_PART_1',
  SPEAKING_SAMPLE_PART_2: 'SPEAKING_SAMPLE_PART_2',
  SPEAKING_SAMPLE_PART_3: 'SPEAKING_SAMPLE_PART_3',
  SPEAKING_SAMPLE_NEWLY_UPDATED: 'SPEAKING_SAMPLE_NEWLY_UPDATED',
  GRAMMAR_IELTS_BLOG: 'GRAMMAR_IELTS_BLOG',
  GRAMMAR_THEORY: 'GRAMMAR_THEORY',
  GRAMMAR_EXERCISE: 'GRAMMAR_EXERCISE',
  VOCAB_IELTS_BLOG: 'VOCAB_IELTS_BLOG',
  VOCAB_TOPIC: 'VOCAB_TOPIC',
  VOCAB_WRITING: 'VOCAB_WRITING',
  VOCAB_SPEAKING: 'VOCAB_SPEAKING',
  VOCAB_L_AND_R: 'VOCAB_L_AND_R',
  VOCAB_LEARNING: 'VOCAB_LEARNING',
  DICTIONARY: 'DICTIONARY',
} as const;

export type TrendingType = (typeof TrendingType)[keyof typeof TrendingType];

export const BlogEditorLeftToolBarGroupEnum = {
  MEDIA: 'MEDIA',
  TABLE: 'TABLE',
  STYLING_OBJECT: 'STYLING_OBJECT',
  SPECIAL_OBJECTS: 'SPECIAL_OBJECTS',
  EXERCISE: 'EXERCISE',
} as const;

export type BlogEditorLeftToolBarGroupEnum =
  (typeof BlogEditorLeftToolBarGroupEnum)[keyof typeof BlogEditorLeftToolBarGroupEnum];

export const BlogEditorLeftToolBarActionEnum = {
  VOCAB_LIST: 'VOCAB_LIST',
  VOCAB_LIST_NEWS: 'VOCAB_LIST_NEWS',
  VOCAB_CARD: 'VOCAB_CARD',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  FRAME: 'FRAME',
  FILE: 'FILE',
  AUDIO: 'AUDIO',
  IMAGE_SLIDE_SHOW: 'IMAGE_SLIDE_SHOW',
  IMAGE_SLIDE_TEXT: 'IMAGE_SLIDE_TEXT',
  TABLE: 'TABLE',
  CARD_LOGIC: 'CARD_LOGIC',
  FAQ: 'FAQ',
  DIVIDER: 'DIVIDER',
  BORDER_AND_SHADING: 'BORDER_AND_SHADING',
  BOOK_REVIEW: 'BOOK_REVIEW',
  CENTER_REVIEW: 'CENTER_REVIEW',
  TEACHER_REVIEW: 'TEACHER_REVIEW',
  COURSE_REVIEW: 'COURSE_REVIEW',
  CALCULATOR: 'CALCULATOR',
  CONVERTER: 'CONVERTER',
  PAPER_EXAM: 'PAPER_EXAM',
  IELTS_CALENDAR_COMPUTER_BASED: 'IELTS_CALENDAR_COMPUTER_BASED',
  IELTS_PURPOSE_CONVERTER: 'IELTS_PURPOSE_CONVERTER',
  IELTS_SAMPLE_W1: 'IELTS_SAMPLE_W1',
  IELTS_SAMPLE_W2A: 'IELTS_SAMPLE_W2A',
  IELTS_SAMPLE_W2G: 'IELTS_SAMPLE_W2G',
  IELTS_SAMPLE_S1: 'IELTS_SAMPLE_S1',
  IELTS_SAMPLE_S2: 'IELTS_SAMPLE_S2',
  IELTS_SAMPLE_S3: 'IELTS_SAMPLE_S3',
  QUIZ: 'QUIZ',
  EXERCISE_MULTIPLE_CHOICE: 'EXERCISE_MULTIPLE_CHOICE',
  EXERCISE_GAP_FILL: 'EXERCISE_SUGAP_FILL',
  EXERCISE_SHORT_ANSWER: 'EXERCISE_SHORT_ANSWER',
  EXERCISE_GAP_FILL_WITH_HINT: 'EXERCISE_GAP_FILL_WITH_HINT',
  HYPER_LINK: 'HYPERLINK',
  EMPTY_SPACE: 'EMPTY_SPACE',
} as const;

export type BlogEditorLeftToolBarActionEnum =
  (typeof BlogEditorLeftToolBarActionEnum)[keyof typeof BlogEditorLeftToolBarActionEnum];

export const BlogEditorRightToolBarGroupEnum = {
  CONTENT: 'CONTENT',
  FORMAT: 'FORMAT',
  EXPLANATION: 'EXPLANATION',
} as const;

export type BlogEditorRightToolBarGroupEnum =
  (typeof BlogEditorRightToolBarGroupEnum)[keyof typeof BlogEditorRightToolBarGroupEnum];

export const BlogEditorRightToolBarActionMarkEnum = {
  H2: 'FORMAT_H2',
  H3: 'FORMAT_H3',
  H4: 'FORMAT_H4',
  PARAGRAPH: 'FORMAT_PARAGRAPH',
  BULLET_POINT: 'BULLET_POINT',
  EMOJI: 'INSERT_EMOJI',
  QUOTE: 'INSERT_QUOTE',
  NUMBER_POINT: 'NUMBER_POINT',
  BOLD: 'FORMAT_BOLD',
  ITALIC: 'FORMAT_ITALIC',
  OLD_UNDERLINE: 'FORMAT_OLD_UNDERLINE',
  UNDERLINE: 'FORMAT_UNDERLINE',
  STRIKE_THROUGH: 'FORMAT_STRIKE_THROUGH',
  TEXT_COLOR: 'FORMAT_TEXT_COLOR',
  HIGHLIGHT_TEXT_COLOR: 'HIGHLIGHT_TEXT_COLOR',
  TITLE_CASE: 'FORMAT_TITLE_CASE',
  UPPER_CASE: 'FORMAT_UPPER_CASE',
  LOWER_CASE: 'FORMAT_LOWER_CASE',
  ROUND_TEXT: 'ROUND_TEXT',
  RECORD: 'RECORD',
  CALCULATOR: 'CALCULATOR',
  K12_ELEMENT_QUOTE: 'K12_ELEMENT_QUOTE',
  HYPERLINK: 'HYPERLINK',
  MATH: 'MATH',

  ALIGN_LEFT: 'FORMAT_ALIGN_LEFT',
  ALIGN_CENTER: 'FORMAT_ALIGN_CENTER',
  ALIGN_RIGHT: 'FORMAT_ALIGN_RIGHT',
  ALIGN_JUSTIFY: 'FORMAT_ALIGN_JUSTIFY',

  INDENT_INCREASE: 'FORMAT_INDENT_INCREASE',
  INDENT_DECREASE: 'FORMAT_INDENT_DECREASE',
  EMPTY_SPACE: 'EMPTY_SPACE',

  CITE: 'FORMAT_CITE',
  ABBR: 'FORMAT_ABBR',
  Q: 'FORMAT_Q',
} as const;

export type BlogEditorRightToolBarActionMarkEnum =
  (typeof BlogEditorRightToolBarActionMarkEnum)[keyof typeof BlogEditorRightToolBarActionMarkEnum];

export const BlogEditorContextMenuActionEnum = {
  COPY: 'COPY',
  PASTE: 'PASTE',
  PASTE_WITHOUT_FORMAT: 'PASTE_WITHOUT_FORMAT',
  HYPERLINK: 'HYPERLINK',
  DATABASE_OBJECTS: 'DATABASE_OBJECTS',
  INSERT_HOVER_CARD: 'INSERT_HOVER_CARD',
  BORDER_AND_SHADING: 'BORDER_AND_SHADING',
  VOCAB_NEWS: 'VOCAB_NEWS',
} as const;

export type BlogEditorContextMenuActionEnum =
  (typeof BlogEditorContextMenuActionEnum)[keyof typeof BlogEditorContextMenuActionEnum];

export const EditorDatabaseObjectTypeEnum = {
  BOOK: 'BOOK',
  SAMPLE_W1: 'SAMPLE_W1',
  SAMPLE_W2: 'SAMPLE_W2',
  SAMPLE_S1: 'SAMPLE_S1',
  SAMPLE_S2: 'SAMPLE_S2',
  SAMPLE_S3: 'SAMPLE_S3',
  CALCULATOR: 'CALCULATOR',
  CONVERTER_IELTS_TO_TOEIC: 'CONVERTER_IELTS_TO_TOEIC',
  CONVERTER_IELTS_SCORE_TO_OBJECTIVE: 'CONVERTER_IELTS_SCORE_TO_OBJECTIVE',
  IELTS_ROADMAP: 'IELTS_ROADMAP',
  CALENDAR_IELTS_TEST_ONLINE: 'CALENDAR_IELTS_TEST_ONLINE',
  CALENDAR_IELTS_TEST_OFFLINE: 'CALENDAR_IELTS_TEST_OFFLINE',
} as const;

export type EditorDatabaseObjectTypeEnum =
  (typeof EditorDatabaseObjectTypeEnum)[keyof typeof EditorDatabaseObjectTypeEnum];

export const ErrorCode = {
  DUPLICATED_DATA: 'DUPLICATED_DATA',
} as const;

export type ErrorCode = (typeof ErrorCode)[keyof typeof ErrorCode];

export const WritingSampleTypeEnum = {
  WRITING_TASK_1: 'WRITING_TASK_1',
  WRITING_TASK_2_ACADEMIC: 'WRITING_TASK_2_ACADEMIC',
  WRITING_TASK_1_GENERAL: 'WRITING_TASK_2_GENERAL',
} as const;

export type WritingSampleTypeEnum =
  (typeof WritingSampleTypeEnum)[keyof typeof WritingSampleTypeEnum];

export const SpeakingSampleTypeEnum = {
  SPEAKING_PART_1: 'SPEAKING_PART_1',
  SPEAKING_PART_2: 'SPEAKING_PART_2',
  SPEAKING_PART_3: 'SPEAKING_PART_3',
} as const;

export type SpeakingSampleTypeEnum =
  (typeof SpeakingSampleTypeEnum)[keyof typeof SpeakingSampleTypeEnum];

export const SampleTopicResourceTypeEnum = {
  WRITING_TASK_2_ACADEMIC: 'SAMPLE_WRITING_TASK_2_ACADEMIC',
  SPEAKING_PART_1: 'SAMPLE_SPEAKING_PART_1',
  SPEAKING_PART_2: 'SAMPLE_SPEAKING_PART_2',
  SPEAKING_PART_3: 'SAMPLE_SPEAKING_PART_3',
} as const;

export type SampleTopicResourceTypeEnum =
  (typeof SampleTopicResourceTypeEnum)[keyof typeof SampleTopicResourceTypeEnum];

export const SAMPLE_TYPE_NAME = {
  // WRITING
  WRITING_TASK_1: 'writing_task_1',
  WRITING_TASK_2_ACADEMIC: 'writing_task_2_academic',
  WRITING_TASK_2_GENERAL: 'writing_task_2_general',

  // SPEAKING
  SPEAKING_PART_1: 'speaking_part_1',
  SPEAKING_PART_2: 'speaking_part_2',
  SPEAKING_PART_3: 'speaking_part_3',

  // CHART_TYPE
  BAR_CHART: 'bar_chart',
  PIE_CHART: 'pie_chart',
  TABLE: 'table',
  LINE: 'line',
  MIXED: 'mixed',
  PROCESS: 'process',
  MAP: 'map',

  // QUESTION_TYPE
  AGREE_AND_DISAGREE: 'agree_and_disagree',
  DISCUSS_BOTH_VIEWS: 'discuss_both_views',
  TWO_PART_QUESTIONS: 'two_part_questions',
  PROBLEMS_CAUSES_SOLUTIONS: 'problems_causes_solutions',
  ADVANTAGES_AND_DISADVANTAGES: 'advantages_and_disadvantages',

  // DOCUMENT_TYPE
  THANK_LETTER: 'thank_letter',
  REQUEST_LETTER: 'request_letter',
  COMPLAINT_LETTER: 'complaint_letter',
  APOLOGY_LETTER: 'apology_letter',
  ADVICE_SEEKING_LETTER: 'advice_seeking_letter',
  APPLICATION_LETTER: 'application_letter',
};

export const SampleQuarterEnum = {
  QUARTER_3_2021: 'QUARTER_3_2021',
  QUARTER_2_2021: 'QUARTER_2_2021',
  QUARTER_1_2021: 'QUARTER_1_2021',
  QUARTER_3_2020: 'QUARTER_3_2020',
  QUARTER_2_2020: 'QUARTER_2_2020',
  QUARTER_1_2020: 'QUARTER_1_2020',
  BEFORE_2020: 'BEFORE_2020',
} as const;

export type SampleQuarterEnum = (typeof SampleQuarterEnum)[keyof typeof SampleQuarterEnum];

export const LocationEnum = {
  HCM: 'HCM',
  NATION: 'NATION',
  ONLINE: 'ONLINE',
} as const;

export const LOCATION_FILTER_OPTION = [
  {
    label: ALL,
    value: undefined,
  },
  {
    label: 'TP Hồ Chí Minh',
    value: LocationEnum.HCM,
  },
  {
    label: 'Toàn quốc',
    value: LocationEnum.NATION,
  },
  {
    label: 'Online',
    value: LocationEnum.ONLINE,
  },
];

export const COURSE_FILTER_OPTION = [
  {
    label: ALL,
    value: undefined,
  },
  {
    label: 'Cơ bản',
    value: EnglishLevel.BASIC,
  },
  {
    label: 'Cấp tốc',
    value: EnglishLevel.INTERMEDIATE,
  },
  {
    label: 'Nâng cao',
    value: EnglishLevel.ADVANCED,
  },
];

export const SharingExperienceTableType = {
  BOOK: 'BOOK',
  CENTER: 'CENTER',
  IELTS_GENERAL: 'IELTS_GENERAL',
} as const;

export type SharingExperienceTableType =
  (typeof SharingExperienceTableType)[keyof typeof SharingExperienceTableType];

export const DocumentTypeEnum = {
  BOOKS: 'BOOKS',
  BOOK: 'BOOK',
  WEBSITE_APP_STUDY: 'WEBSITE_APP_STUDY',
} as const;

export const DOCUMENT_TYPE_FILTER_OPTION = [
  {
    label: ALL,
    value: undefined,
  },
  {
    label: 'Bộ sách',
    value: DocumentTypeEnum.BOOKS,
  },
  {
    label: 'Sách lẻ',
    value: DocumentTypeEnum.BOOK,
  },
  {
    label: 'Web/ App tự học',
    value: DocumentTypeEnum.WEBSITE_APP_STUDY,
  },
];

export const DESKTOP_WIDTH = '1080px';
export const TABLET_WIDTH = '712px';
export const MOBILE_WIDTH = 'calc(100vw - 32px)';
export const LG_MOBILE_WIDTH = '396px';

export const SORT_BY_ORDERING_ASC = 'ordering,asc';
export const SORT_BY_LAST_MODIFIED_DESC = 'lastModifiedAt,desc';

export const ContentGroupEnum = {
  READING_IELTS_BLOG: 'READING_IELTS_BLOG',
  LISTENING_IELTS_BLOG: 'LISTENING_IELTS_BLOG',
  ALL_GROUP: 'ALL_GROUP',
  FULL_TEST: 'FULL_TEST',
  LINEAR_THINKING: 'LINEAR_THINKING',
  PASSAGE: 'PRACTICE_PASSAGE',
  SECTION: 'PRACTICE_SECTION',
  DO_DICTATION: 'DICTATION',
  BOOK_REVIEW_IELTS_BLOG: 'BOOK_REVIEW_IELTS_BLOG',
  CENTER_REVIEW_IELTS_BLOG: 'CENTER_REVIEW_IELTS_BLOG',
  COURSE_REVIEW_IELTS_BLOG: 'COURSE_REVIEW_IELTS_BLOG',
  TEACHER_REVIEW_IELTS_BLOG: 'TEACHER_REVIEW_IELTS_BLOG',
  IELTS_INFO_BLOG: 'IELTS_INFO_BLOG',
  BOOK_ON_IELTS_INFO_BLOG: 'BOOK_ON_IELTS_INFO_BLOG',
  CENTER_ON_IELTS_INFO_BLOG: 'CENTER_ON_IELTS_INFO_BLOG',
  HIGH_SCHOOL: 'HIGH_SCHOOL',
  COLLEGE: 'COLLEGE',
} as const;

export type ContentGroupEnum = (typeof ContentGroupEnum)[keyof typeof ContentGroupEnum];

/**
 *  - SELF_OWNED: User already login & the referral code is belong to current user
 *  - SOMEONE_ELSE_OWNED: User already login & the referral code is belong to another user
 *  - VALID: User not login yet (Not pass token) & the referral code is valid
 *  - INVALID: User not login yet (Not pass token) & the referral code is invalid
 */
export const ReferralCodeValidation = {
  SELF_OWNED: 'SELF_OWNED',
  SOMEONE_ELSE_OWNED: 'SOMEONE_ELSE_OWNED',
  VALID: 'VALID',
  INVALID: 'INVALID',
} as const;

export type ReferralCodeValidation =
  (typeof ReferralCodeValidation)[keyof typeof ReferralCodeValidation];

export interface ImageObj {
  url: string;
  alt?: string;
  width?: number;
  height?: number;
}

export const DATA_EMPTY_VALUE = '-';

export const LinkSettingEnum = {
  OPEN_NEW_TAB: 'OPEN_NEW_TAB',
  NO_FOLLOW: 'NO_FOLLOW',
} as const;

export type LinkSettingEnum = (typeof LinkSettingEnum)[keyof typeof LinkSettingEnum];

export const LinkActionTypeEnum = {
  AUTO: 'AUTO',
  COPY_TO_CLIPBOARD: 'COPY_TO_CLIPBOARD',
  DIRECT: 'DIRECT',
} as const;

export type LinkActionTypeEnum = (typeof LinkActionTypeEnum)[keyof typeof LinkActionTypeEnum];

export const LandingPageSectionEnum = {
  TRENDING: 'TRENDING',
  LISTING: 'LISTING',
  RELATED_CONTENT: 'RELATED_CONTENT',
  BLOG: 'BLOG',
} as const;

export type LandingPageSectionEnum =
  (typeof LandingPageSectionEnum)[keyof typeof LandingPageSectionEnum];

export const BookPageSectionEnum = {
  CAMBRIDGE: 'CAMBRIDGE',
  ACTUAL_TEST: 'ACTUAL_TEST',
  PRACTICE_TEST: 'PRACTICE_TEST',
  GUIDE_AND_TRAINER: 'GUIDE_AND_TRAINER',
} as const;

export type BookPageSectionEnum = (typeof BookPageSectionEnum)[keyof typeof BookPageSectionEnum];

export const HomePageSectionEnum = {
  TRENDING: 'TRENDING',
  ONLINE_TEST: 'ONLINE_TEST',
  LINEAR_THINKING: 'LINEAR_THINKING',
  LISTENING_PRACTICE_TEST: 'LISTENING_PRACTICE_TEST',
  READING_PRACTICE_TEST: 'READING_PRACTICE_TEST',
  SPEAKING_SAMPLE: 'SPEAKING_SAMPLE',
  WRITING_SAMPLE: 'WRITING_SAMPLE',
  DICTATION: 'DICTATION',
} as const;

export type HomePageSectionEnum = (typeof HomePageSectionEnum)[keyof typeof HomePageSectionEnum];

export const GrammarPageSectionEnum = {
  TRENDING: 'TRENDING',
  THEORY: 'THEORY',
  EXERCISE: 'EXERCISE',
} as const;

export type GrammarPageSectionEnum =
  (typeof GrammarPageSectionEnum)[keyof typeof GrammarPageSectionEnum];

export const VocabularyPageSectionEnum = {
  COMMON: 'COMMON',
  TOPIC: 'TOPIC',
  READING_LISTENING: 'READING_LISTENING',
  WRITING: 'WRITING',
  SPEAKING: 'SPEAKING',
  LEARNING: 'LEARNING',
} as const;

export type VocabularyPageSectionEnum =
  (typeof VocabularyPageSectionEnum)[keyof typeof VocabularyPageSectionEnum];

export const SectionDescriptionSectionTypeEnum = {
  OUTLINE: 'OUTLINE',
  QUESTIONS: 'QUESTIONS',
  VOCABS: 'VOCABS',
  FEEDBACKS: 'FEEDBACKS',
  SAMPLES: 'SAMPLES',
} as const;

export type SectionDescriptionSectionTypeEnum =
  (typeof SectionDescriptionSectionTypeEnum)[keyof typeof SectionDescriptionSectionTypeEnum];

export const AudioTypeEnum = {
  GOOGLE: 'GOOGLE',
  UPLOAD: 'UPLOAD',
} as const;

export type AudioTypeEnum = (typeof AudioTypeEnum)[keyof typeof AudioTypeEnum];

export const NewsContentTypeEnum = {
  NEWS: 'NEWS',
  VIDEO: 'VIDEO',
  KNOWLEDGE: 'KNOWLEDGE',
} as const;

export type NewsContentTypeEnum = (typeof NewsContentTypeEnum)[keyof typeof NewsContentTypeEnum];

export const NewsLevelEnum = {
  BASIC: 'BASIC',
  INTERMEDIATE: 'INTERMEDIATE',
  ADVANCED: 'ADVANCED',
} as const;

export type NewsLevelEnum = (typeof NewsLevelEnum)[keyof typeof NewsLevelEnum];

export const NewsContentTypeOptions = [
  {
    displayName: 'Tin tức',
    value: NewsContentTypeEnum.NEWS,
  },
  {
    displayName: 'Video',
    value: NewsContentTypeEnum.VIDEO,
  },
  {
    displayName: 'Kiến thức',
    value: NewsContentTypeEnum.KNOWLEDGE,
  },
];

export const NewsLevelOptions = [
  {
    displayName: 'Cơ bản',
    value: NewsLevelEnum.BASIC,
  },
  {
    displayName: 'Trung cấp',
    value: NewsLevelEnum.INTERMEDIATE,
  },
  {
    displayName: 'Nâng cao',
    value: NewsLevelEnum.ADVANCED,
  },
];

export const PageSharingActionEnum = {
  ACCESS_PAGE: 'ACCESS_PAGE',
  REGISTER_ACCOUNT: 'REGISTER_ACCOUNT',
} as const;

export type PageSharingActionEnum =
  (typeof PageSharingActionEnum)[keyof typeof PageSharingActionEnum];

export const RewardStatusEnum = {
  PENDING: 'PENDING',
  CONTACTED: 'CONTACTED',
  SHIPPING: 'SHIPPING',
  DELIVERY_FAILED: 'DELIVERY_FAILED',
  DELIVERED: 'DELIVERED',
} as const;

export type RewardStatusEnum = (typeof RewardStatusEnum)[keyof typeof RewardStatusEnum];

export const AnswerTextTypeEnum = {
  SHORT: 'SHORT',
  LONG: 'LONG',
} as const;
export type AnswerTextTypeEnum = (typeof AnswerTextTypeEnum)[keyof typeof AnswerTextTypeEnum];

export const PacketTypeEnum = {
  ONE_MONTH: 'DOLPRO_1M',
  THREE_MONTH: 'DOLPRO_3M',
  SIX_MONTH: 'DOLPRO_6M',
  ONE_YEAR: 'DOLPRO_1Y',
} as const;

export type PacketTypeEnum = (typeof PacketTypeEnum)[keyof typeof PacketTypeEnum];

export const MyTestFilterStatusEnum = {
  NEW: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
} as const;

export type MyTestFilterStatusEnum =
  (typeof MyTestFilterStatusEnum)[keyof typeof MyTestFilterStatusEnum];

export const PermissionStatusEnum = {
  NOT_DETERMINED: 'NOT_DETERMINED',
  ALLOWED: 'ALLOWED',
  DENIED: 'DENIED',
} as const;

export type PermissionStatusEnum = (typeof PermissionStatusEnum)[keyof typeof PermissionStatusEnum];

export const DoAssignmentStatusEnum = {
  NOT_OPEN: 'NOT_OPEN',
  OPEN: 'OPEN',
  SUBMITTED: 'SUBMITTED',
  MARKING: 'MARKING',
  MARKED: 'MARKED',
  EXPIRED: 'EXPIRED',
  IN_PROGRESS: 'IN_PROGRESS',
  WAITING_FOR_RESUBMITTED: 'WAITING_FOR_RESUBMITTED',
} as const;

export type DoAssignmentStatusEnum =
  (typeof DoAssignmentStatusEnum)[keyof typeof DoAssignmentStatusEnum];

export const SavingStateEnum = {
  OFFLINE: 'offline',
  SAVING: 'saving',
  SAVED_IDLE: 'saved_idle',
  SAVED: 'saved',
} as const;

export type SavingStateEnum = (typeof SavingStateEnum)[keyof typeof SavingStateEnum];

export const MarkRatingEnum = {
  EXCELLENT: 'EXCELLENT',
  GOOD: 'GOOD',
  FAIR: 'FAIR',
  MORE_WORK_NEEDED: 'MORE_WORK_NEEDED',
  NEED_MORE_PRACTICE: 'NEED_MORE_PRACTICE',
} as const;

export type MarkRatingEnum = (typeof MarkRatingEnum)[keyof typeof MarkRatingEnum];

// https://www.figma.com/file/2wLJErhkpGQ1y3RVpYZWr6/LMS-2022-New?node-id=3923%3A392154
export const AssignmentProgressHistoryTypeEnum = {
  OPENED: 'OPENED',
  STARTED: 'STARTED',
  SUBMITTED: 'SUBMITTED',
  REQUEST_RESUBMITTED: 'REQUEST_RESUBMITTED',
  FINISH_EDIT_MARKING: 'FINISH_EDIT_MARKING',
  TA_START_EDIT_MARKING: 'TA_START_EDIT_MARKING',
  TA_FINISH_EDIT_MARKING: 'TA_FINISH_EDIT_MARKING',
  TEACHER_START_EDIT_MARKING: 'TEACHER_START_EDIT_MARKING',
  TEACHER_START_MODIFY_APPROVED: 'TEACHER_START_MODIFY_APPROVED',
  TEACHER_FINISH_MODIFY_APPROVED: 'TEACHER_FINISH_MODIFY_APPROVED',
  ASSIGN_TA: 'ASSIGN_TA',
  MARKING: 'MARKING',
  TA_MARKING: 'TA_MARKING',
  TEACHER_MARKING: 'TEACHER_MARKING',
  TEACHER_MARKED: 'TEACHER_MARKED',
  MARKED: 'MARKED',
  APPROVED: 'APPROVED',
  SENT_TO_STUDENT: 'SENT_TO_STUDENT',
  OTHER_TEACHER_SENT_TO_STUDENT: 'OTHER_TEACHER_SENT_TO_STUDENT',
  TA_SENT_TO_STUDENT: 'TA_SENT_TO_STUDENT',
  REMOVED_BY_TEACHER: 'REMOVED_BY_TEACHER',
  REMOVED_BY_SYSTEM: 'REMOVED_BY_SYSTEM',
  STUDENT_FEEDBACK: 'STUDENT_FEEDBACK',
  TEACHER_START_EDIT_APPROVING: 'TEACHER_START_EDIT_APPROVING',
  EXTENDED: 'EXTENDED',
  MARKER_REMOVED_FROM_COURSE: 'MARKER_REMOVED_FROM_COURSE',
} as const;

export type AssignmentProgressHistoryTypeEnum =
  (typeof AssignmentProgressHistoryTypeEnum)[keyof typeof AssignmentProgressHistoryTypeEnum];

export const LAYER = {
  LAYER1: 100,
  LAYER2: 200,
  LAYER3: 300,
  LAYER4: 400,
  LAYER5: 500,
  LAYER6: 600,
  LAYER7: 700,
  LAYER8: 800,
  LAYER9: 900,
  LAYER10: 1000,
  LAYER11: 1100,
} as const;

export const SKILL_STATUS_DROPDOWN_FILTER_OPTIONS = [
  { key: null, value: 'All' },
  { key: 'READING', value: 'Reading' },
  { key: 'LISTENING', value: 'Listening' },
  { key: 'WRITING', value: 'Writing' },
  { key: 'SPEAKING', value: 'Speaking' },
  { key: 'VOCAB', value: 'Vocabulary' },
  { key: 'GRAMMAR', value: 'Grammar' },
  { key: 'SUMMARIZE', value: 'Tổng hợp' },
];

export const SKILL_STATUS_FILTER_OPTIONS = [
  { label: 'All', value: null },
  { label: 'Reading', value: 'READING' },
  { label: 'Listening', value: 'LISTENING' },
  { label: 'Writing', value: 'WRITING' },
  { label: 'Speaking', value: 'SPEAKING' },
  { label: 'Vocabulary', value: 'VOCAB' },
  { label: 'Grammar', value: 'GRAMMAR' },
  { label: 'Tổng hợp', value: 'SUMMARIZE' },
];

export const SKILL_STATUS_READING_FILTER_OPTIONS = [
  { label: 'Reading', value: 'READING' },
  { label: 'Listening', value: 'LISTENING' },
  { label: 'Writing', value: 'WRITING' },
  { label: 'Speaking', value: 'SPEAKING' },
  { label: 'Vocabulary', value: 'VOCAB' },
  { label: 'Grammar', value: 'GRAMMAR' },
];

export const LISTING_STATUS_OPTIONS = [
  { label: 'All Status', value: null },
  { label: 'Listed', value: 'LISTED' },
  { label: 'Unlisted', value: 'UNLISTED' },
  { label: 'Not listed yet', value: 'NOT_LISTED_YET' },
];

export const LEVEL_REPORT_OPTIONS = [
  {
    label: ALL,
    value: undefined,
  },
  {
    label: 'Cơ bản',
    value: EnglishLevel.BASIC,
  },
  {
    label: 'Nâng cao',
    value: EnglishLevel.ADVANCED,
  },
];

export const AudioUploadTypeEnum = {
  SHORT_AUDIO: 'SHORT_AUDIO',
  LONG_AUDIO: 'LONG_AUDIO',
} as const;

export type AudioUploadTypeEnum = (typeof AudioUploadTypeEnum)[keyof typeof AudioUploadTypeEnum];

export const VoiceTypeEnglishEnum = {
  EN_GB_WAVENET_A: 'en-GB-Wavenet-A',
  EN_GB_WAVENET_B: 'en-GB-Wavenet-B',
  EN_GB_WAVENET_C: 'en-GB-Wavenet-C',
  EN_GB_WAVENET_D: 'en-GB-Wavenet-D',
  EN_GB_WAVENET_F: 'en-GB-Wavenet-F',
  EN_US_WAVENET_G: 'en-US-Wavenet-G',
  EN_US_WAVENET_H: 'en-US-Wavenet-H',
  EN_US_WAVENET_I: 'en-US-Wavenet-I',
  EN_US_WAVENET_J: 'en-US-Wavenet-J',
  EN_US_WAVENET_A: 'en-US-Wavenet-A',
  EN_US_WAVENET_B: 'en-US-Wavenet-B',
  EN_US_WAVENET_C: 'en-US-Wavenet-C',
  EN_US_WAVENET_D: 'en-US-Wavenet-D',
  EN_US_WAVENET_E: 'en-US-Wavenet-E',
  EN_US_WAVENET_F: 'en-US-Wavenet-F',
  EN_AU_WAVENET_A: 'en-AU-Wavenet-A',
  EN_AU_WAVENET_B: 'en-AU-Wavenet-B',
  EN_AU_WAVENET_C: 'en-AU-Wavenet-C',
  EN_AU_WAVENET_D: 'en-AU-Wavenet-D',
  EN_IN_WAVENET_D: 'en-IN-Wavenet-D',
  EN_IN_WAVENET_A: 'en-IN-Wavenet-A',
  EN_IN_WAVENET_B: 'en-IN-Wavenet-B',
  EN_IN_WAVENET_C: 'en-IN-Wavenet-C',
} as const;

export type VoiceTypeEnglishEnum = (typeof VoiceTypeEnglishEnum)[keyof typeof VoiceTypeEnglishEnum];

export const SUPPORT_VOICE_TYPES = [
  VoiceTypeEnglishEnum.EN_US_WAVENET_G,
  VoiceTypeEnglishEnum.EN_US_WAVENET_H,
  VoiceTypeEnglishEnum.EN_US_WAVENET_I,
  VoiceTypeEnglishEnum.EN_US_WAVENET_J,
  VoiceTypeEnglishEnum.EN_US_WAVENET_A,
  VoiceTypeEnglishEnum.EN_US_WAVENET_B,
  VoiceTypeEnglishEnum.EN_US_WAVENET_C,
  VoiceTypeEnglishEnum.EN_US_WAVENET_D,
  VoiceTypeEnglishEnum.EN_US_WAVENET_E,
  VoiceTypeEnglishEnum.EN_US_WAVENET_F,
  VoiceTypeEnglishEnum.EN_GB_WAVENET_A,
  VoiceTypeEnglishEnum.EN_GB_WAVENET_B,
  VoiceTypeEnglishEnum.EN_GB_WAVENET_C,
  VoiceTypeEnglishEnum.EN_GB_WAVENET_D,
  VoiceTypeEnglishEnum.EN_GB_WAVENET_F,
];

export const SyllabusResourceTypeEnum = {
  MATERIAL: 'MATERIAL',
  ASSIGNMENT: 'ASSIGNMENT',
  EXERCISE: 'EXERCISE',
  VOCAB_SET: 'VOCAB_SET',
  DICTATION: 'DICTATION',
  ONLINE_TEST: 'ONLINE_TEST',
  SAMPLE: 'SAMPLE',
  AI_MOCK_TEST: 'AI_MOCK_TEST',
  SAT_TEST: 'SAT_TEST',
  TOEIC_ASSIGNMENT: 'TOEIC_ASSIGNMENT',
} as const;

export type SyllabusResourceTypeEnum =
  (typeof SyllabusResourceTypeEnum)[keyof typeof SyllabusResourceTypeEnum];

export const HomeworkSubweekStatusEnum = {
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  NOT_OPEN: 'NOT_OPEN',
} as const;

export type HomeworkSubweekStatusEnum =
  (typeof HomeworkSubweekStatusEnum)[keyof typeof HomeworkSubweekStatusEnum];

export const SYLLASBUS_RESOURCE_TYPE_NAME = {
  [SyllabusResourceTypeEnum.MATERIAL]: 'Materials',
  [SyllabusResourceTypeEnum.ASSIGNMENT]: 'Assignments',
  [SyllabusResourceTypeEnum.EXERCISE]: 'Exercises',
  [SyllabusResourceTypeEnum.VOCAB_SET]: 'Vocabulary',
  [SyllabusResourceTypeEnum.SAMPLE]: 'Samples',
  [SyllabusResourceTypeEnum.AI_MOCK_TEST]: 'AI mock test',
  [SyllabusResourceTypeEnum.ONLINE_TEST]: 'Online tests',
  [SyllabusResourceTypeEnum.DICTATION]: 'Dictation',
  [SyllabusResourceTypeEnum.SAT_TEST]: 'Online tests',
  [SyllabusResourceTypeEnum.TOEIC_ASSIGNMENT]: 'Assignments',
};

export const ActivityTypeEnum = {
  READING: 'READING',
  LISTENING: 'LISTENING',
  DICTATION: 'DICTATION',
  SAMPLE_WRITING: 'SAMPLE_WRITING',
  SAMPLE_SPEAKING: 'SAMPLE_SPEAKING',
  NEWS: 'NEWS',
} as const;

export type ActivityTypeEnum = (typeof ActivityTypeEnum)[keyof typeof ActivityTypeEnum];

export const ContentWordTypeEnum = {
  HIDE: 'hide',
  UNHIDE: 'unhide',
  ERROR: 'error',
} as const;

export type ContentWordTypeEnum = (typeof ContentWordTypeEnum)[keyof typeof ContentWordTypeEnum];

export const KeyboardKeyEnum = {
  SPACE_KEY: 'Space',
  BACKSPACE_KEY: 'Backspace',
} as const;

export type KeyboardKeyEnum = (typeof KeyboardKeyEnum)[keyof typeof KeyboardKeyEnum];

export const DictationTypeEnum = {
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
} as const;

export type DictationTypeEnum = (typeof DictationTypeEnum)[keyof typeof DictationTypeEnum];

export const RoadmapTestTypeEnum = {
  EXERCISE: 'EXERCISE',
  VOCAB_SET: 'VOCAB_SET',
  DICTATION: 'DICTATION',
  BLOGS: 'READING',
  SAMPLE: 'SAMPLE',
  ONLINE_TEST: 'ONLINE_TEST',
  AI_MOCK_TEST: 'AI_MOCK_TEST',
  MATERIAL: 'MATERIAL',
} as const;

export type RoadmapTestTypeEnum = (typeof RoadmapTestTypeEnum)[keyof typeof RoadmapTestTypeEnum];

export const SpeechErrorTypeEnum = {
  NONE: 'None',
  MISPRONOUNCE: 'Mispronounce',
} as const;

export type SpeechErrorTypeEnum = (typeof SpeechErrorTypeEnum)[keyof typeof SpeechErrorTypeEnum];

export const AudioPlayerStateEnum = {
  NOT_STARTED: 'NOT_STARTED',
  PLAYING: 'PLAYING',
  PAUSED: 'PAUSED',
} as const;

export type AudioPlayerStateEnum = (typeof AudioPlayerStateEnum)[keyof typeof AudioPlayerStateEnum];

export const CourseExtraPracticesEnum = {
  BLOGS: 'READING',
  MATERIAL: 'MATERIAL',
  ASSIGNMENT: 'ASSIGNMENT',
  EXERCISE: 'EXERCISE',
  VOCAB_SET: 'VOCAB_SET',
  DICTATION: 'DICTATION',
  ONLINE_TEST: 'ONLINE_TEST',
  SAMPLE: 'SAMPLE',
  AI_MOCK_TEST: 'AI_MOCK_TEST',
  SAT_TEST: 'SAT_TEST',
  ROADMAP: 'ROADMAP',
  TOEIC_ASSIGNMENT: 'TOEIC_ASSIGNMENT',
  EXTRA_ASSIGNMENT: 'EXTRA_ASSIGNMENT',
} as const;

export type CourseExtraPracticesEnum =
  (typeof CourseExtraPracticesEnum)[keyof typeof CourseExtraPracticesEnum];

export const CourseLevelEnum = {
  IELTS_PRE: 'PRE',
  IELTS_4_0: '4.0',
  IELTS_5_0: '5.0',
  IELTS_6_0: '6.0',
  IELTS_6_5: '6.5',
  IELTS_7_0: '7.0',
  IELTS_8_0: '8.0',
  IELTS_INT_0: 'INT0',
  IELTS_INT_1: 'INT1',
  IELTS_INT_2: 'INT2',
} as const;

export type CourseLevelEnum = (typeof CourseLevelEnum)[keyof typeof CourseLevelEnum];

export const RegistrationStatusEnum = {
  BINH_THUONG: 'bình thường',
  BAO_LUU: 'bảo lưu',
  BAO_LUU_LAN_2: 'bảo lưu lần 2',
  BAO_LUU_LAN_3: 'bảo lưu lần 3',
  BAO_LUU_LAN_4: 'bảo lưu lần 4',
  BAO_LUU_HOC_LAI: 'bảo lưu học lại',
  DOI_LOP: 'đổi lớp',
  CHUYEN_TU_LOP_CU: 'chuyển từ lớp cũ',
  HO_TRO_HOC_LAI: 'hỗ trợ học lại',
  REFUND: 'refund',
  BO_LOP: 'bỏ lớp',
  HOC_SONG_SONG: 'học song song',
} as const;

export type RegistrationStatusEnum =
  (typeof RegistrationStatusEnum)[keyof typeof RegistrationStatusEnum];

export const RegistrationStatusEngEnum = {
  NORMAL: 'NORMAL',
  RESERVE_COURSE: 'RESERVE_COURSE',
  RESERVE_REJOIN_COURSE: 'RESERVE_REJOIN_COURSE',
  MOVE_FROM_OLD_COURSE_TO_NEW_COURSE: 'MOVE_FROM_OLD_COURSE_TO_NEW_COURSE',
  REJOIN_COURSE: 'REJOIN_COURSE',
  CHANGE_COURSE: 'CHANGE_COURSE',
  REFUND: 'REFUND',
  LEAVE_COURSE: 'LEAVE_COURSE',
  PARALLEL_LEARNING: 'PARALLEL_LEARNING',
} as const;

export type RegistrationStatusEngEnum =
  (typeof RegistrationStatusEngEnum)[keyof typeof RegistrationStatusEngEnum];

export const REGISTRATION_STATUS_CONTENT = {
  [RegistrationStatusEnum.BINH_THUONG]: 'Bình thường',
  [RegistrationStatusEnum.HOC_SONG_SONG]: 'Học song song',
  [RegistrationStatusEngEnum.PARALLEL_LEARNING]: 'Học song song',
  [RegistrationStatusEngEnum.NORMAL]: 'Bình thường',
  [RegistrationStatusEnum.BAO_LUU]: 'Bảo lưu',
  [RegistrationStatusEngEnum.RESERVE_COURSE]: 'Bảo lưu',
  [RegistrationStatusEnum.BAO_LUU_LAN_2]: 'Bảo lưu',
  [RegistrationStatusEnum.BAO_LUU_LAN_3]: 'Bảo lưu',
  [RegistrationStatusEnum.BAO_LUU_LAN_4]: 'Bảo lưu',
  [RegistrationStatusEnum.BAO_LUU_HOC_LAI]: 'Bảo lưu học lại',
  [RegistrationStatusEngEnum.RESERVE_REJOIN_COURSE]: 'Bảo lưu học lại',
  [RegistrationStatusEnum.DOI_LOP]: 'Đổi lớp',
  [RegistrationStatusEngEnum.CHANGE_COURSE]: 'Đổi lớp',
  [RegistrationStatusEnum.CHUYEN_TU_LOP_CU]: 'Chuyển từ lớp cũ',
  [RegistrationStatusEngEnum.MOVE_FROM_OLD_COURSE_TO_NEW_COURSE]: 'Chuyển từ lớp cũ',
  [RegistrationStatusEnum.HO_TRO_HOC_LAI]: 'Hỗ trợ học lại',
  [RegistrationStatusEngEnum.REJOIN_COURSE]: 'Hỗ trợ học lại',
  [RegistrationStatusEnum.REFUND]: 'Refund',
  [RegistrationStatusEngEnum.REFUND]: 'Refund',
  [RegistrationStatusEnum.BO_LOP]: 'Bỏ lớp',
  [RegistrationStatusEngEnum.LEAVE_COURSE]: 'Bỏ lớp',
};

export const INVALID_REGISTRATION_STATUS = [
  RegistrationStatusEngEnum.LEAVE_COURSE,
  RegistrationStatusEngEnum.REFUND,
];

export const DROP_COURSE_STATUS = [
  RegistrationStatusEngEnum.RESERVE_COURSE,
  RegistrationStatusEngEnum.REFUND,
  RegistrationStatusEngEnum.LEAVE_COURSE,
  RegistrationStatusEngEnum.CHANGE_COURSE,
];

export const NoteStatusEnum = {
  NOTE_ON_TIME: 'NOTE_ON_TIME',
  LATE_WITH_NOTE: 'LATE_WITH_NOTE',
  LATE_WITHOUT_NOTE: 'LATE_WITHOUT_NOTE',
  MUST_BE_NOTE: 'MUST_BE_NOTE',
  NO_NOTE_NEEDED: 'NO_NOTE_NEEDED',
  NOT_YET_DUE: 'NOT_YET_DUE',
} as const;

export type NoteStatusEnum = (typeof NoteStatusEnum)[keyof typeof NoteStatusEnum];

export const NoteTypeEnum = {
  START_COURSE_NOTE: 'START_COURSE_NOTE',
  MID_COURSE_NOTE: 'MID_COURSE_NOTE',
  END_COURSE_NOTE: 'END_COURSE_NOTE',
} as const;

export type NoteTypeEnum = (typeof NoteTypeEnum)[keyof typeof NoteTypeEnum];

export const NOTE_TYPE_NAME = {
  [NoteTypeEnum.START_COURSE_NOTE]: 'Note đầu khóa',
  [NoteTypeEnum.MID_COURSE_NOTE]: 'Note giữa khóa',
  [NoteTypeEnum.END_COURSE_NOTE]: 'Note cuối khóa',
};

export const TeacherPhaseNoteStatusEnum = {
  COMPLETE: 'COMPLETE',
  COMPLETE_LATE: 'COMPLETE_LATE',
  NOT_COMPLETE: 'NOT_COMPLETE',
  LATE: 'LATE',
  NOT_YET_DUE: 'NOT_YET_DUE',
} as const;

export type TeacherPhaseNoteStatusEnum =
  (typeof TeacherPhaseNoteStatusEnum)[keyof typeof TeacherPhaseNoteStatusEnum];

export const StudyTemplateEnum = {
  SYLLABUS: 'SYLLABUS',
  EXTRA: 'EXTRA',
  ROADMAP: 'ROADMAP',
} as const;

export type StudyTemplateEnum = (typeof StudyTemplateEnum)[keyof typeof StudyTemplateEnum];

export const BAO_LUU_THOI_HOC_STATUS = [
  RegistrationStatusEnum.BAO_LUU,
  RegistrationStatusEnum.BAO_LUU_LAN_2,
  RegistrationStatusEnum.BAO_LUU_LAN_3,
  RegistrationStatusEnum.BAO_LUU_LAN_4,
  RegistrationStatusEnum.BO_LOP,
  RegistrationStatusEnum.REFUND,
  RegistrationStatusEnum.DOI_LOP,
  RegistrationStatusEngEnum.RESERVE_COURSE,
  RegistrationStatusEngEnum.LEAVE_COURSE,
  RegistrationStatusEngEnum.REFUND,
  RegistrationStatusEngEnum.CHANGE_COURSE,
];

export const BAO_LUU_THOI_HOC_STATUS_TEXT = {
  [RegistrationStatusEnum.BAO_LUU]: 'bảo lưu',
  [RegistrationStatusEnum.BAO_LUU_LAN_2]: 'bảo lưu',
  [RegistrationStatusEnum.BAO_LUU_LAN_3]: 'bảo lưu',
  [RegistrationStatusEnum.BAO_LUU_LAN_4]: 'bảo lưu',
  [RegistrationStatusEnum.BO_LOP]: 'nghỉ học',
  [RegistrationStatusEnum.REFUND]: 'nghỉ học',
  [RegistrationStatusEnum.DOI_LOP]: 'đổi lớp',
  [RegistrationStatusEngEnum.RESERVE_COURSE]: 'bảo lưu',
  [RegistrationStatusEngEnum.LEAVE_COURSE]: 'nghỉ học',
  [RegistrationStatusEngEnum.REFUND]: 'nghỉ học',
  [RegistrationStatusEngEnum.CHANGE_COURSE]: 'đổi lớp',
};

export const SCALE_PERCENTAGE = [
  { key: 1, value: '100%' },
  { key: 0.9, value: '90%' },
  { key: 0.8, value: '80%' },
  { key: 0.7, value: '70%' },
  { key: 0.6, value: '60%' },
  { key: 0.5, value: '50%' },
  { key: 0.4, value: '40%' },
  { key: 0.3, value: '30%' },
  { key: 0.2, value: '20%' },
  { key: 0.1, value: '10%' },
];

export const CompanyEnum = {
  DOL_ENGLISH: 'DOL_ENGLISH',
  UEF: 'UEF',
} as const;

export type CompanyEnum = (typeof CompanyEnum)[keyof typeof CompanyEnum];

export const COMPANY_CONTENT = {
  [CompanyEnum.DOL_ENGLISH]: 'DOL English',
  [CompanyEnum.UEF]: 'UEF',
};

export const COMPANY_FILTER_OPTIONS = [
  { label: 'DOL English', value: CompanyEnum.DOL_ENGLISH },
  { label: 'UEF', value: CompanyEnum.UEF },
];

export const SortStatusEnum = {
  DEFAULT: 'DEFAULT',
  DESC: 'DESC',
  ASC: 'ASC',
} as const;

export type SortStatusEnum = (typeof SortStatusEnum)[keyof typeof SortStatusEnum];

export const MarketEnum = {
  CHINA: 'CHINA',
  GLOBAL: 'GLOBAL',
  DOL: 'DOL',
} as const;

export type MarketEnum = (typeof MarketEnum)[keyof typeof MarketEnum];

export const systemAvatarUrl =
  'https://gqefcpylonobj.vcdn.cloud/directus-upload/37980943-b517-4c9a-96e1-a3692cfea3a1.png';
