export const apiUrls = {
  goUser: {
    PIXEL_PAGE_VIEW: '/page-view',
  },
  onlineTest: {
    GET_PUBLISHED_READING: '/readings/published',
    GET_PUBLISHED_EXAMPLE: '/pd/samples',
    TEST: '/tests',
    GET_TEST_DETAIL: '/tests/:testId/detail',
    GET_TEST_PREVIEW: '/tests/:testId/preview',
    GET_ACTIVE_STORIES: '/daily-learning/stories/active',

    // Data Entry: IELTS Full Test
    GET_TESTS: '/tests',
    POST_TEST_CREATE: '/tests',
    GET_TEST_DETAIL_BY_ID: '/tests/:testId',
    PUT_TEST_UPDATE_BY_ID: '/tests/:testId',
    DELETE_TEST_BY_ID: '/tests/:testId',
    POST_TEST_ADD_SECTION: '/tests/:testId/add-section',
    PUT_TEST_UPDATE_SECTION: '/tests/:testId/update-section',
    DELETE_TEST_REMOVE_SECTION: '/tests/:testId/remove-section',

    USER_START_TEST: (testId) => `/tests/${testId}/start-test`,
    USER_START_OR_RESUME_FULL_TEST: (testId) => `/tests/${testId}/start-or-resume-test`,

    TEST_SECTION: '/test-sections',
    GET_TEST_SECTION_DETAIL: '/test-sections/:testSectionId/detail',
    GET_TEST_SECTION_PREVIEW: '/test-sections/:testSectionId/preview',
    DELETE_TEST_SECTION: '/test-sections/:testSectionId',
    USER_START_PRACTICE_TEST: (testSectionId) => `/test-sections/${testSectionId}/start-test`,
    USER_START_OR_RESUME_PRACTICE_TEST: (testSectionId) =>
      `/test-sections/${testSectionId}/start-or-resume-test`,
    TEST_SECTION_SETUP: (testSectionId) => `/test-sections/${testSectionId}/setup`,
    SAVE_TEST_SECTION_SETUP: (testSectionId) => `/test-sections/${testSectionId}/setup/save`,
    PUBLISH_TEST_SECTION_SETUP: (testSectionId) => `/test-sections/${testSectionId}/setup/publish`,
    DISCARD_DRAFT_TEST_SECTION_SETUP: (testSectionId) =>
      `/test-sections/${testSectionId}/setup/discard-draft`,
    GET_AVAILABLE_TEST_SECTION: '/test-sections/available',

    USER_TEST: '/user-test/:userTestId',
    STUDENT_DO_ONLINE_TEST_AUTO_SAVE: 'v2/user-tests/:userTestId',
    STUDENT_ONLINE_TEST_VIEW_DETAIL: 'v2/user-tests/:userTestId',
    STUDENT_ONLINE_TEST_VIEW_RESULT: 'v2/user-tests/:userTestId/result',
    STUDENT_ONLINE_TEST_INFO: 'student-management/students/info/:contactId',
    STUDENT_ONLINE_TEST_VIEW_HISTORY: 'v2/user-tests/attempts',
    STUDENT_ONLINE_TEST_ATTEMPT_LIST: 'v2/user-tests/attempts/list',
    USER_TEST_INPROGRESS: '/user-test/users/me',
    USER_TEST_IN_PROGRESS_OVERVIEW: '/user-test/users/me/overview',

    BOOKS: '/books',

    VOCAB: '/vocabs',
    USER_TABLE_VIEW: '/user/table-view',

    TOPICS: '/topics',
    DICTATIONS: '/dictations',
    DICTATIONS_BASIC_INFO: '/dictations/:id/basic-info',
    DICTATION_BY_ID: '/dictations/:id',
    PUBLISH_DICTATION: '/dictations/:id/publish',
    START_DICTATION: '/dictations/:id/start',
    ACADEMIC_DICTATION: '/dictations/academic',

    USER_DICTATION_BY_ID: '/user-dictations/:id',
    SUBMIT_USER_DICTATION: '/user-dictations/:id/submit',
    GET_USER_DICTATION_OVERVIEW: '/user-dictations/users/me',
    // BLOG Management
    CREATE_IELTS_BLOG: '/blog-management/new',
    UPDATE_IELTS_BLOG: '/blog-management/update',
    PUBLISH_IELTS_BLOG: '/blog-management/publish',
    GET_IELTS_BLOG_MANAGEMENT_LIST: '/blog-management',
    GET_IELTS_BLOG_MANAGEMENT_DETAIL: '/blog-management/:id',
    DELETE_IELTS_BLOG_MANAGEMENT_BY_ID: '/blog-management/:id',

    CREATE_READINDG: '/readings',
    UPDATE_READINDG: '/readings/:id',
    CLONE_READING_BY_ID: '/readings/:id/duplicate',
    PUBLISH_READINGS: '/readings/:id/publish',

    IELTS_SAMPLE: '/samples',
    IELTS_SAMPLE_DETAIL: '/samples/:id',
    MARK_IELTS_SAMPLE_USED: '/samples/:id/mark-used',
    PUBLISH_IELTS_SAMPLE: '/samples/:id/publish',

    // Daily Learning
    DAILY_LEARNING_NEWS: '/daily-learning/news',
    DAILY_LEARNING_NEWS_DETAIL: '/daily-learning/news/:id',
    PUBLISH_DAILY_LEARNING_NEWS_DETAIL: '/daily-learning/news/:id/publish',
    DAILY_LEARNING_FACT: '/daily-learning/facts',
    DAILY_LEARNING_FACT_DETAIL: '/daily-learning/facts/:id',
    ACTIVE_DAILY_LEARNING_FACT_DETAIL: '/daily-learning/facts/:id/activate',
    DAILY_LEARNING_RIDDLE: '/daily-learning/riddles',
    DAILY_LEARNING_RIDDLE_DETAIL: '/daily-learning/riddles/:id',
    ACTIVE_DAILY_LEARNING_RIDDLE_DETAIL: '/daily-learning/riddles/:id/activate',
    DAILY_LEARNING_STORIES: '/daily-learning/stories',
    DAILY_LEARNING_STORIES_DETAIL: '/daily-learning/stories/:id',
    ACTIVE_DAILY_LEARNING_STORIES_DETAIL: '/daily-learning/stories/:id/activate',
    DAILY_LEARNING_VOCABS: '/daily-learning/vocabs',
    DAILY_LEARNING_VOCABS_DETAIL: '/daily-learning/vocabs/:id',
    ACTIVE_DAILY_LEARNING_VOCABS_DETAIL: '/daily-learning/vocabs/:id/activate',
    DAILY_LEARNING_QUIZZES_PROGRESS: '/daily-learning/news/:id/quizzes/progress',

    // DICTIONARY
    GET_DICTIONARY_BY_ID: '/dictionary/:id',
    GET_DICTIONARY_LIST: '/dictionary',
    GET_DIC_TOPIC: '/dictionary/topic',
    CREATE_DICTIONARY: '/dictionary/create',
    UPDATE_DICTIONARY: '/dictionary/update',
    PUBLISH_DICTIONARY: '/dictionary/publish',

    GET_MY_TEST_IDS: '/user-test/me/test-ids',
    GET_MY_DICTATION_IDS: '/user-dictations/me/dictation-ids',

    GET_USER_TEST_HISTORY: '/user-test/users/me/history',
    GET_USER_DICTATION_HISTORY: '/user-dictations/completed',

    GET_TEST_FOR_ENTRANCE: '/test-and-sections',
    GET_TEST_DETAIL_FOR_FINAL: '/test-and-sections/detail',
  },
  pageManagement: {
    GET_DICTIONARY_PAGE: '/dictionary/paged',
    GET_PRACTICE_TESTS: '/practice-test/overview',
    GET_LISTED_IELTS_BLOG_MANAGEMENT_LIST: '/ielts-blogs/listed',
    GET_LISTED_IELTS_BLOG_FOR_LANDING: '/ielts-blogs/landing',
    GET_BLOGS_OVERVIEW: '/blogs/overview',
    GET_NEW_DICTIONARY: '/dictionary/new',
    GET_DICTIONARY_STATISTIC: '/dictionary/statistic',
    GET_DICTIONARY_DETAIL: '/dictionary:url',
    GET_DICTIONARY_RELATIVE_BY_WORD: '/dictionary/relative',
    ALL_URLS: '/page',
    PAGE_BY_ID: '/page/:id',
    GET_ALL_PREVIEW_PAGES: '/page/preview',
    ALL_URLS_MANA: '/page/all-url',
    ALL_URLS_SITE_MAP: '/page/sitemap/urls',
    CHECK_URL: '/page/urls/check',
    PUBLISH_PAGE: '/page/publish',
    UN_PUBLISH_PAGE: '/page/un-publish',
    PUT_SAVE_PAGE: '/page/save',
    CHECK_URL_BLOG_POSTING: 'page/blog-posting/check-url',
    REVIEW_BLOG_POSTING: '/page/blog-posting/schema/preview',
    PUT_DISCARD_DRAFT_PAGE: '/page/discard-draft/:id',
    GET_TEST_INFORMATION_DETAIL: '/page/tests/:testType/:testId',
    BOOKMARK_PAGE: '/page/:id/bookmark/me',
    USER_BOOKMARK: '/page/bookmark/me',
    GET_TRENDING_ITEM: '/trending',
    GET_TRENDING_ITEM_SHARING: '/trending/blogs/sharing',
    PUT_TRENDING_ITEM: '/trending',

    GET_SEO_PAGE_INFO: '/page/find',
    GET_BOOK_SEO_PAGE_BY_ID: '/page/books/:id',
    ALL_LANDINGS: '/de/pages/landing',
    ALL_FULL_TEST: '/full-test',
    NEWLY_UPDATED_TESTS: '/full-test/newly-updated',
    ALL_PRACTICE_TEST: '/practice-test',
    GET_PRACTICE_TEST_DETAIL: '/practice-test/:testSectionId',
    PUT_UPDATE_PRACTICE_TEST_LISTING_ORDER: '/practice-test/listing-order/update',
    GET_PRACTICE_TEST_LISTING_ORDER: '/practice-test/listing-order',
    GET_IELTS_BLOG_MANAGEMENT_LIST: '/ielts-blogs',

    // DICTIONARY

    GET_DICTIONARY_LIST: '/dictionary',
    GET_DICTIONARY_SEO_PAGE_BY_ID: '/dictionary/seo-page',
    GET_DICTIONARY_TRENDING: '/trending/dictionary',

    PUT_UPDATE_PRACTICE_TEST_ORDERING: (id, order) => `/practice-test/ordering/${id}/${order}`,
    PUT_UPDATE_PRACTICE_TEST_ORDERING_TO_TOP: (id) => `/practice-test/ordering/${id}/to-top`,
    PUT_UPDATE_PRACTICE_TEST_ORDERING_TO_BOTTOM: (id) => `/practice-test/ordering/${id}/to-bottom`,
    PUT_UPDATE_PRACTICE_TEST_LISTING_STATUS: (id) => `/practice-test/listing/${id}`,
    GET_TRENDING_PRACTICE_TESTS: '/trending/practice-tests',

    BOOKS: '/books',
    BOOK_ORDERING_TO_TOP: '/books/ordering/:id/to-top',
    BOOK_ORDERING_TO_BOTTOM: '/books/ordering/:id/to-bottom',
    BOOK_ORDERING_TO_SPECIFIC_ORDER: '/books/ordering/:id/:order',
    BOOK_ACTION_LIST: '/books/:id/list',
    BOOK_ACTION_UN_LIST: '/books/:id/un-list',

    BOOKS_WITH_DETAIL: '/books/detail',
    BOOK_BY_ID: '/books/:id',
    REORDER_TESTS_OF_BOOK: (bookId) => `/books/${bookId}/reorder-tests`,
    REORDER_BOOK: '/books/ordering/:id/:order',
    REORDER_NEWLY_UPDATE_BOOK: (bookId, order) =>
      `/full-test/newly-updated/${bookId}/ordering/${order}`,

    GET_BLOG_DETAIL: '/blogs/:blogId',
    PUT_UPDATE_BLOG_ORDERING: '/blogs/ordering/:id/:order',
    PUT_UPDATE_BLOG_ORDERING_TO_TOP: '/blogs/ordering/:id/to-top',
    PUT_UPDATE_BLOG_ORDERING_TO_BOTTOM: '/blogs/ordering/:id/to-bottom',
    PUT_UPDATE_BLOG_STATUS_LISTED: '/blogs/:id/list',
    PUT_UPDATE_BLOG_STATUS_UN_LISTED: '/blogs/:id/un-list',
    COUNT_BLOG_VIEWS: '/blogs/:seoPageId/count-views',

    DICTATIONS: '/dictations',
    ORDER_DICTATION: '/dictations/ordering/:id/:order',
    ORDER_DICTATION_TO_TOP: '/dictations/ordering/:id/to-top',
    ORDER_DICTATION_TO_BOTTOM: '/dictations/ordering/:id/to-bottom',
    UPDATE_DICTATION_LISTING_STATUS: '/dictations/listing/:id',
    GET_DICTATION_DETAIL: '/dictations/:dictationId',
    GET_DICTATION_HISTORY_DETAIL: '/user-dictations/:id',
    GET_DICTATION_IN_PROGRESS: '/dictations/me',
    UPDATE_TRENDING: '/trending',
    GET_TRENDING_DICTATIONS: '/trending/dictations',
    GET_TRENDING_BOOKS: '/trending/books/overview',

    GET_NEWLY_UPDATED_TESTS: '/full-test/landing-newly-updated',
    IE_BLOG_SEO_INFO: (testId) => `/page/ielts/${testId}`,
    DETAIL_SEO_INFO: (testId) => `/page/${testId}`,

    PUT_UPDATE_IE_BLOG_ORDERING: '/ielts-blogs/ordering/:type/:id/:order',
    PUT_UPDATE_IE_BLOG_ORDERING_TO_TOP: '/ielts-blogs/ordering/:type/:id/to-top',
    PUT_UPDATE_IE_BLOG_ORDERING_TO_BOTTOM: '/ielts-blogs/ordering/:type/:id/to-bottom',
    PUT_UPDATE_IE_BLOG_STATUS_LISTED: '/ielts-blogs/:id/list',
    PUT_UPDATE_IE_BLOG_STATUS_UNLISTED: '/ielts-blogs/:id/un-list',

    GET_PRACTICE_SAMPLES: '/samples/list',
    GET_SAMPLE_LISTED: '/samples/listed',
    GET_PRACTICE_SAMPLE_DETAIL: '/samples/detail/:sampleId',
    GET_TRENDING_SAMPLES: '/trending/samples',
    PUT_UPDATE_SAMPLE_LISTING_STATUS: '/samples/:sampleId/listing-status',
    POST_UPDATE_SAMPLE_ORDERING: '/samples/:sampleId/ordering/:order',
    POST_UPDATE_SAMPLE_ORDERING_TO_TOP: '/samples/:sampleId/ordering/to-top',
    POST_UPDATE_SAMPLE_ORDERING_TO_BOTTOM: '/samples/:sampleId/ordering/to-bottom',

    GET_IELTS_BLOGS: '/ielts-blogs',
    GET_IELTS_BLOGS_OVERVIEW: '/ielts-blogs/overview',

    GET_VOCAB_GRAMMAR_BLOGS: '/ielts-blogs/vocab-grammar',
    GET_VOCAB_GRAMMAR_BLOGS_LANDING: '/ielts-blogs/vocab-grammar/landing',
    GET_VOCAB_GRAMMAR_BLOGS_TRENDING: '/ielts-blogs/vocab-grammar/trending',
    PUT_UPDATE_VOCAB_GRAMMAR_ORDERING: '/ielts-blogs/vocab-grammar/ordering/:id',

    UPDATE_DAILY_NEWS_LISTING_STATUS: '/daily-learning/news/:id/listing-status/:status',
    UPDATE_DICTIONARY_LISTING: '/dictionary/listing/:id',

    UPDATE_EXAM_QUESTIONS_PREDICTION: '/samples/exam-questions-predictions',
    GET_IN_PROGRESS_RESOURCE: '/resource-rest/users/recent',
    GET_RECOMMEND_PRACTICE_TESTS: '/practice-test/recommendation',
    GET_RECOMMEND_DICTATIONS: '/dictations/recommendation',

    GET_NEWS_SITES: '/news-sites',
    GET_NEWS_SITE_DETAIL: '/news-sites/:newsSiteId',
    POST_NEWS_SITE_CREATE: '/news-sites',
    PUT_NEWS_SITE_DETAIL: '/news-sites/:newsSiteId',
    DELETE_NEWS_SITE_DETAIL: '/news-sites/:newsSiteId',

    GET_NEWS_SITES_QUOTED: '/news-sites/quoted',
    PUT_NEWS_SITES_QUOTED: '/news-sites/:newsSiteId/quoted',

    GET_NEWS_SITES_FEATURED: '/news-sites/featured',
    PUT_NEWS_SITES_FEATURED: '/news-sites/featured',

    GET_NEWS_SITES_ARTICLES: '/news-sites/articles',
    GET_NEWS_SITES_ARTICLE_DETAIL: '/news-sites/articles/:newsArticleId',
    POST_NEWS_SITES_ARTICLE_CREATE: '/news-sites/articles',
    PUT_NEWS_SITES_ARTICLE_DETAIL: '/news-sites/articles/:newsArticleId',
    DELETE_NEWS_SITES_ARTICLE_DETAIL: 'news-sites/articles/:newsArticleId',

    PUT_NEWS_SITES_ARTICLE_LISTING_STATUS: '/news-sites/articles/:newsArticleId/listing-status',
    PUT_NEWS_SITES_ARTICLE_ORDERING_TO_TOP: '/news-sites/articles/:newsArticleId/ordering/to-top',
    PUT_NEWS_SITES_ARTICLE_ORDERING_TO_BOTTOM:
      '/news-sites/articles/:newsArticleId/ordering/to-bottom',
    PUT_NEWS_SITES_ARTICLE_ORDERING: '/news-sites/articles/:newsArticleId/ordering',

    GET_MONTHLY_SAMPLE_ANNOUNCEMENT: '/samples/monthly',
    GET_WEEKLY_NEWS_ANNOUNCEMENT: '/daily-learning/news/weekly',
  },
  landing: {
    GET_PREVIEW_PAGE: '/preview',
  },
  membership: {
    CREATE_PREMIUM_USER: '/premium-users/unregistered',
    CHECK_PHONE_NUMBER: '/premium-users/phone/:phone',
    GET_USER: '/premium-users',
    VALIDATE_USER: '/premium-users/promotion/batch/validate',
    IMPORT_USER: '/premium-users/promotion/batch',
    EDIT_USER: '/premium-users/:id/promotion',
    GET_USER_BY_PHONE: '/premium-users/phone/:phone',
    GET_REWARD: '/dp-earnings',
    GET_LIST_GIFT_CODE: '/gift-codes',
    CREATE_GIFT_CODE: '/gift-codes',
    UPDATE_GIFT_CODE: '/gift-codes/:id',
    GET_GIFT_CODE: '/gift-codes/:id',
    DELETE_USER: '/premium-users/:id',
    DELETE_HISTORY_RECORD: '/dp-earnings/:id',
    POST_DAILY_CODE: '/api/gift-codes/daily-codes/generate',

    GET_MY_DP_EARNINGS: '/dp-earnings/me',
    GET_MOST_SHARED_PAGES: '/pages/latest-daily-learning-news',
    GET_LEARNING_GROUPS: '/social-networks/learning-groups',
    CLAIM_GIFT_CODE: '/gift-codes/redeem',
    CLAIM_DAILY_CODE: '/gift-codes/daily-codes/redeem',
    GET_MY_PREMIUM_USER_INFO: '/premium-users/me?everPurchasedIncluded=true',
    POST_RECORD_PAGE_SHARING: '/dp-earnings/page-sharing',
    SEND_REFERRAL_EMAIL: '/dp-earnings/referral/send-email',
    GET_MY_REWARD_INFO: '/user-rewards/me',
    GET_USER_REWARDS: '/user-rewards',
    POST_CLAIM_USER_REWARD: '/rewards/redeem',
    PUT_UPDATE_USER_REWARD_STATUS: '/user-rewards/:id',
    POST_RECORD_REFERRAL: '/dp-earnings/referral',
    GET_MATCHING_REWARD: '/rewards/matching',
    GET_USER_REWARD: '/rewards',
    POST_REDEEM_REWARD: '/user-rewards/claim',
    POST_REVIEWD_DOL: '/dp-earnings/dol-reviewed',
  },
  verifyToken: {
    REQUEST_OTP: '/otp/request',
    VERIFY_OTP: '/otp/verify',
  },
  searchService: {
    DICTIONARY_SEARCH_RESULT: '/dictionary/result',
    DICTIONARY_SEARCH_SUGGESTION: '/dictionary/suggestion',
  },
  userService: {
    UPDATE_USER_TUTORIAL: '/api/user-profile/me',
    UPDATE_PURCHASES: '/api/subscriptions/purchase',
    LINK_USER: '/api/users/link',
    LINK_USER_BY_ID: '/api/users/link/by-id',
    UNLINK_USER_BY_ID: '/api/users/unlink/by-id',
    REQUEST_ADD_EMAIL: '/api/user-profile/email',
    EMAIL_VERIFICATION_STATUS: '/api/user-profile/email',
    USER_IDENTITIES: '/api/users/identities/me',
    GET_INTERNAL_USER: '/api/users/internal',
    GET_USER_AND_HSCONTACT: '/get-user-by-id-or-email',
    GET_USER_BY_ID: '/.internal/users/:userId',
  },
  activityService: {
    GET_USER_ACTIVITY: '/user-activity/users',
    POST_BAN_ACCOUNT: '/users/ban',
    POST_UNBAN_ACCOUNT: '/users/un-ban',
    POST_TRACKING: '/user-activity/users/tracking-activity',
  },
  kafkaRest: {
    UPDATE_USER_ACTIVITY: '/topics/activity.data-update.user-status',
    RECORD_RESOURCE_INTERACTION: '/topics/academic-resource-interaction',
    USER_ACTIVITY_TRACKING: '/topics/user-activity',
  },
  auth0Service: {
    AUDIENCE: '/api/v2/',
    GET_USER: '/api/v2/users/:sub',
    LINK_ACCOUNT: '/api/v2/users/:sub/identities',
    UNLINK_ACCOUNT: '/api/v2/users/:sub/identities/:provider/:userId',
  },
  authorization: {
    APPS: '/apps',
    APP_BY_ID: '/apps/:id',
    ACTIVITIES: '/activities',
    ACTIVITY_BY_ID: '/activities/:id',
    PERMISSIONS: '/permissions',
    REPLACE_DATA: '/data/replace',
    REFRESH_AUTHORIZATION_DATA: '/data/refresh',
  },
  cms: {
    TYPE_REVIEWS: '/:type-reviews',
    TYPE_REVIEWS_COUNT: '/:type-reviews/count',
    BOOK_REVIEWS: '/book-reviews',
    BOOK_REVIEW_BY_ID: '/book-reviews/:id',
    CENTER_REVIEWS: '/center-reviews',
    CENTER_REVIEW_BY_ID: '/center-reviews/:id',
    COURSE_REVIEWS: '/course-reviews',
    COURSE_REVIEW_BY_ID: '/course-reviews/:id',
    TEACHER_REVIEWS: '/teacher-reviews',
    TEACHER_REVIEW_BY_ID: '/teacher-reviews/:id',
    GET_CHARACTERS: '/charactors',
    GET_AVATAR_GALLERIES: '/avatar-galleries',
    GET_COURSE_PROGRAMS: '/course-programs',
    GET_ENTRANCE_TEST_PROGRAM: '/entrance-test-programs',
    GET_COURSE_LEVELS: '/course-levels',
    GET_JSON_CONTAINERS: '/json-containers',
    GET_COURSE_PROGRAM: '/course-programs',
    GET_COURSE_CENTER: '/centers',
  },
  exercise: {
    GET_LIST_EXERCISE: '/exercises',
    CREATE_EXERCISE: '/exercises',
    UPDATE_EXERCISE: '/exercises/:id',
    GET_EXERCISE: '/exercises/:id',
    PUBLISH_EXERCISE: '/exercises/:id/publish',

    GET_PUBLISHED_EXERCISES: '/exercises/list/published',

    // Exercise
    POST_START_EXERCISE: '/exercises/:exerciseId/start',
    POST_EXERCISE_PREVIEW: '/exercises/:exerciseId/preview',
    GET_EXERCISE_PROGRESS: '/exercises/progress',
    PUT_SUBMIT_EXERCISE_PAGE_ANSWERS: '/exercises/progress/:progressId/pages/:pageId/submit',
    PUT_DELETE_EXERCISE_ANSWER: '/exercises/progress/:progressId/pages/:pageId/delete',
    PUT_UPDATE_EXERCISE: '/exercises/progress/:progressId',
    PUT_COMPLETE_EXERCISE: '/exercises/progress/:progressId/complete',

    // Exercise V2
    POST_START_EXERCISE_V2: '/v2/exercises/:exerciseId/start',
    POST_EXERCISE_PREVIEW_V2: '/v2/exercises/:exerciseId/preview',
    GET_EXERCISE_ATTEMPTS_V2: '/v2/exercises/progress/attempts',
    GET_EXERCISE_PROGRESS_V2: '/v2/exercises/progress',
    GET_EXERCISE_PROGRESS_DETAIL_V2: '/v2/exercises/progress/:progressId',
    PUT_SUBMIT_EXERCISE_PAGE_ANSWERS_V2: '/v2/exercises/progress/:progressId/pages/:pageId/submit',
    PUT_DELETE_EXERCISE_ANSWER_V2: '/v2/exercises/progress/:progressId/pages/:pageId/delete',
    PUT_UPDATE_EXERCISE_V2: '/v2/exercises/progress/:progressId',
    PUT_COMPLETE_EXERCISE_V2: '/v2/exercises/progress/:progressId/complete',
  },
  courseAppSheet: {
    GET_STUDENT_ATTENDANCE_DETAIL: '/student-attendances/:id',
    GET_STUDENT_MY_SCHEDULE_UPCOMING: '/student-schedule/session/upcoming',
    GET_STUDENT_MY_SCHEDULE_EXTRA: '/student-schedule/session/extra',
    GET_STUDENT_MY_ABSENT_SCHEDULE: '/student-course/absent-schedules',
    POST_STUDENT_MY_REQUEST: '/request-form/submit',
    GET_STUDENT_MY_COURSE: '/student-course/course-with-status',
    GET_STUDENT_MY_REQUEST_FORM: '/request-form/paged',
    GET_STUDENT_MY_REQUEST_FORM_DETAIL: '/request-form/:id',
    POST_CS_ACTION_STUDENT_REQUEST: '/request-form/cs-action/:id',
    GET_STUDENT_MY_REQUEST_FORM_CHECK: '/request-form/check',
    DELETE_STUDENT_MY_REQUEST_FORM: '/request-form/:id/delete',

    GET_EXPECTED_STUDENTS: '/student-management/courses/:subClassKey/students',
    GET_STUDENT_INFO_BY_REGISTRATION_KEY_AND_SUBCLASS_KEY:
      '/hs-contact/by-subclass-key-and-registration-key',
    GET_COURSE_TEACHERS: '/course-appsheet/teachers',
    GET_COURSE_BY_KEYS: '/course-appsheet/by-keys',
    GET_COURSE_FOR_NOTE: '/course-appsheet/students/:registrationKey/course-note',
    GET_COURSE_APP_SHEET_CENTER_DIRECTOR_LISTING: '/course-appsheet/paged',
    GET_COURSE_APP_SHEET_LISTING: '/course-appsheet/paged',
    GET_COURSE_APP_SHEET_TEACHER_IN_PROGRESS_LISTING: '/course-appsheet/in-progress/teacher/paged',
    GET_COURSE_APP_SHEET_TEACHER_UPCOMING_LISTING: '/course-appsheet/upcoming/teacher/paged',
    GET_COURSE_APP_SHEET_TEACHER_COMPLETED_LISTING: '/course-appsheet/completed/teacher/paged',
    GET_COURSE_APP_SHEET_SUPPLY_CLASS: '/supply-class/paged',
    GET_COURSE_APP_SHEET_SESSION_EXTRA_WEEKEND: '/session-extra-weekend/paged',
    POST_SYNC_COURSE_SHEET_TO_FINAL_TEST: '/course-sheet/cron/sync-all-sheet',
    POST_SYNC_CS_IN_CHARGE: '/course-sheet/op-course-cs-incharge/sync-full',
    SYNC_CONTACT_INFO_FROM_HUBSPOT: '/hs-contact/:hsObjectId/sync-from-hubspot',
    GET_HS_CONTACT_DETAIL: '/hs-contact/:hsObjectId',
    GET_COURSE_APP_SHEET_DETAIL: '/course-appsheet/:key',
    GET_CS_IN_CHARGE_BY_SUB_CLASS_KEY: '/cs-in-charge/course/:subClassKey/info',
    GET_COURSE_APP_SHEET_TEACHER_INFO: '/course-appsheet/:subClassKey/teacher-info',
    GET_COURSE_SHEET_OP_COURSE_PUBLISH_COURSE:
      '/course-sheet-manager/op-course-info/paged/publish-courses',
    GET_COURSE_SHEET_OP_COURSE_ALL_COURSE: '/course-sheet-manager/op-course-info/paged/all-courses',
    GET_COURSE_SHEET_OP_COURSE_PUBLISH_COURSE_TEACHER_ONLY:
      '/course-sheet-manager/op-course-info/paged/publish-courses/me',
    SEARCH_COURSE_SHEET_OP_COURSE_BY_NAME: '/course-sheet-manager/op-course-info/paged',
    SEARCH_COURSE_SHEET_OP_COURSE_BY_NAME_TEACHER_ONLY:
      '/course-sheet-manager/op-course-info/paged/me',
    SEARCH_COURSE_SHEET_OP_COURSE_BY_NAME_SPECIFIC_TEACHER:
      '/course-sheet-manager/op-course-info/paged/:teacherId',
    GET_COURSE_SHEET_OP_COURSE_INFO_FULL: '/course-sheet-manager/op-course-info/full/:key',
    GET_COURSE_SHEET_OP_COURSE_ROLLING_INFO:
      '/course-sheet-manager/op-course-info/full/:key/rolling/:subClassKey',
    GET_COURSE_SUGGESTED_SUBCLASSES:
      '/course-sheet-manager/op-course-info/full/:opCourseKey/subclass-suggest',
    PUT_UPDATE_COURSE_APP_SHEET: '/course-appsheet/:subClassKey',
    PUT_UPDATE_COURSE_APP_SHEET_PICK_CHECKLIST: '/course-appsheet/:subClassKey/pick-checklist',
    POST_PUBLISH_COURSE_APP_SHEET: '/course-appsheet/:subClassKey/publish',
    POST_PUBLISH_OLD_COURSE_APP_SHEET: '/course-appsheet/:subClassKey/publish-old-course',
    GET_SUBCLASSES_NAVIGATE_INFO: '/course-appsheet/subclass/:opCourseKey/navigate-info',
    GET_SUBCLASS_COURSE_STUDENT_LIST: '/course-appsheet/subclass/:subClassKey/registrations',
    GET_STUDENT_MANAGEMENT_BY_SUBCLASS_KEY: '/course-appsheet/subclass/:subClassKey/students',
    GET_COURSE_SESSION_BY_SUBCLASS_KEY: '/course-appsheet/schedule-data/:subClassKey',
    GET_ALL_TEACHER_COURSE_SESSION_BY_SUBCLASS_KEY:
      '/course-appsheet/schedule-data/:subClassKey/teachers',
    GET_COURSE_SESSION_ACTUAL_BY_SUBCLASS_KEY: '/course-appsheet/session-actual/:subClassKey',
    PUT_UPDATE_MY_HS_CONTACT: '/hs-contact/me',
    POST_UPDATE_STUDENT_AVATAR: '/student-course/update-avatar',

    GET_COURSE_SESSION_DETAIL_BY_SUBCLASS_KEY_AND_SYLLABUS_CODE:
      '/course-appsheet/schedule-data/:subClassKey/session/:scheduleDataKey',
    GET_COURSE_SESSION_DETAIL_WITHOUT_SUBCLASS_KEY: '/session/:scheduleDataKey',

    POST_RESYNC_AUTO_MARK_ATTENDANCE:
      '/zoom-room-link/course/:subClassKey/session/:scheduleDataKey/resync-auto-mark-attendance',
    GET_COURSE_SESSION_ATTENDANCE_BY_SUBCLASS_KEY_AND_SYLLABUS_CODE:
      '/course-appsheet/schedule-data/:subClassKey/session/:scheduleDataKey/attendances',
    GET_COURSE_SESSION_ATTENDANCE_WITHOUT_SUBCLASS_KEY: '/session/:scheduleDataKey/attendances',
    SEARCH_COURSE_SHEET_SYLLABUS_CODE:
      '/course-appsheet/schedule-data/:subClassKey/search-syllabus-codes',
    SEARCH_SYLLABUS_CODE_BY_SCHEDULE_DATA_KEY: '/session/:scheduleDataKey/search-syllabus-codes',
    SEARCH_COURSE_SHEET_ROOM: '/course-sheet-manager/op-schedule-room/search',
    SEARCH_COURSE_XEP_BU: '/course-appsheet/course-xep-bu/search',
    UPDATE_COURSE_ACTUAL_SESSION:
      '/course-appsheet/schedule-data/:subClassKey/session/:scheduleDataKey/update-actual',
    UPDATE_COURSE_ACTUAL_MAKEUP_SESSION:
      '/course-appsheet/schedule-data/:subClassKey/session/:scheduleDataKey/update-makeup-attendance',
    UPDATE_COURSE_ACTUAL_ATTENDANCE:
      '/course-appsheet/schedule-data/:subClassKey/session/:scheduleDataKey/update-attendance',
    UPDATE_EXTRA_ATTENDANCE: '/session-extra-weekend/schedule/:scheduleDataKey/publish',
    UPDATE_COURSE_ATTENDANCE_EXPIRED_DATE:
      '/course-appsheet/schedule-data/:subClassKey/session/:scheduleDataKey/extend-attendance',
    UPDATE_COURSE_SESSION_SUPPLY_TEACHER:
      '/course-appsheet/schedule-data/:subClassKey/session/:scheduleDataKey/update-supply-teacher',
    REMOVE_COURSE_SESSION_SUPPLY_TEACHER:
      '/course-appsheet/schedule-data/:subClassKey/session/:scheduleDataKey/remove-supply-teacher',
    GET_COURSE_STUDENT_ATTENDANCES_BY_SUBCLASS_KEY:
      '/student-attendances/subclass/:subClassKey/list',
    GET_COURSE_STUDENT_ATTENDANCES_BY_SUBCLASS_KEY_FOR_SINGLE_STUDENT:
      '/student-attendances/subclass/:subClassKey/list/:hsObjectId',
    GET_HS_USER_FINAL_TEST_PAGED: '/hs-contact/final-test/paged',
    GET_STUDENT_MY_COURSE_IN_PROGRESS: '/student-course/my-course/in-progress',
    GET_STUDENT_MY_COURSE_REMINDER: '/student-course/my-course/reminder',
    GET_STUDENT_ALL_COURSE_STATISTIC: '/student-course/my-course/statistic',
    GET_STUDENT_IN_PROGRESS_COURSE_STATISTIC: '/student-course/my-course/in-progress/statistic',
    GET_STUDENT_COMPLETED_COURSE_STATISTIC: '/student-course/my-course/completed/statistic',
    GET_STUDENT_LASTEST_COMPLETED_COURSE_STATISTIC:
      '/student-course/my-course/latest-completed-course/statistic',
    GET_STUDENT_MY_COURSE_UPCOMING: '/student-course/my-course/upcoming',
    GET_STUDENT_MY_COURSE_COMPLETED: '/student-course/my-course/statistic/completed',
    GET_STUDENT_MY_ASSIGNMENT_NEED_TO_COMPLETE: '/student-course/my-course/assignment/suggest',
    GET_STUDENT_SUGGEST_PRACTICE: '/student-course/my-course/suggested-practices',
    GET_STUDENT_COURSE_PUBLISHED: '/student-course/my-course/published',
    GET_STUDENT_COURSE_PUBLISHED_EXIST: '/student-course/my-course/published/exist',

    GET_EXISTING_COURSE_CHECK: '/student-course/my-existing-course/check',
    GET_STUDENT_MY_CERTIFICATIONS: '/student-course/certifications/me',
    GET_STUDENT_CERTIFICATIONS_BY_COURSE: '/certifications/by-course',

    GET_COURSE_STUDENT_REGISTRATION_INFO: '/student-course/my-course/:subClassKey/info/me',
    GET_COURSE_STUDENT_GENERAL_INFO: '/student-course/my-course/:subClassKey/general-info',
    GET_COURSE_STUDENT_BASIC_INFO: '/student-course/course/:subClassKey/basic-info',
    GET_ROLLING_COURSE_STUDENT_GENERAl_INFO:
      '/student-course/my-course/registration/:registrationKey/rolling-course',
    GET_COURSE_STUDENT_ATTENDANCE_INFO:
      '/student-attendances/:courseId/statistics/:registrationKey',

    // student feedback
    GET_COURSE_STUDENT_FEEDBACK_ANSWERS: '/course-sheet-manager/op-feedback/:subClassKey/detail',
    GET_COURSE_STUDENT_FEEDBACK_REVIEW_TEACHER:
      '/course-sheet-manager/op-feedback/:subClassKey/teacher',
    GET_COURSE_STUDENT_FEEDBACK_COURSE_AND_CENTER:
      '/course-sheet-manager/op-feedback/:subClassKey/course-center',
    POST_OPEN_COURSE_FEEDBACK: '/course-sheet-manager/op-feedback/:subClassKey/open',
    POST_ROLLBACK_COURSE_FEEDBACK: '/course-sheet-manager/op-feedback/:subClassKey/rollback',

    GET_STUDENT_NOTE_BY_SUBCLASS_KEY: '/student-note/:subClassKey/full',
    GET_STUDENT_NOTE_STATISTIC: '/student-note/:subClassKey/statistic',
    GET_COURSE_PHASE_FOR_NOTE: '/student-note/:subClassKey/course-phase',
    GET_COURSE_ALL_TEACHER_NOTE: '/student-note/:subClassKey/normal-course/all-teacher',
    GET_ALL_RELATED_NOTE: '/student-note/:subClassKey/student/:registrationKey/rolling',
    GET_ALL_NOTE_NORMAL_COURSE: '/student-note/:subClassKey/student/:registrationKey/all-note',
    GET_STUDENT_NORMAL_COURSE_NOTE_DETAIL:
      '/student-note/:subClassKey/student/:registrationKey/note-detail',
    GET_TEACHER_NOTE_STATISTIC: '/student-note/:subClassKey/note-statistic',
    GET_STUDENT_INFO_FOR_NOTE: '/student-note/:subClassKey/student/:registrationKey',
    GET_STUDENT_NOTE_TEACHER_SCHEDULE: '/course-appsheet/:subClassKey/teacher-schedule',

    PUT_STUDENT_NOTE_BY_SUBCLASS_KEY: '/student-note/:subClassKey/:registrationKey/update',
    ADD_STUDENT_NOTE: '/student-note/:subClassKey/student/:registrationKey/add-note',
    ADD_MULTI_STUDENT_NOTE:
      '/student-note/:subClassKey/student/:registrationKey/rolling/update-note',
    GET_SIMPLE_RELATED_NOTE: '/student-note/:subClassKey/student/:registrationKey/related-note',

    GET_COURSE_APP_SHEET_STUDENT_DETAIL_FOR_EMAIL:
      '/email/course/:subClassKey/student/:studentRegistrationKey',

    // student management
    GET_STUDENT_SESSION_INFO:
      'student-management/courses/:courseId/session-attendance/:registrationKey/info',
    GET_STUDENT_INFO: 'student-management/:registrationKey/student-info',
    GET_STUDENT_SESSION_DETAIL:
      'student-management/session/:scheduleDataKey/student/:registrationKey/overview',
    GET_STUDENT_NOTE_DETAIL: 'student-note/:courseId/student/:registrationKey/detail',
    GET_STUDENT_ASSIGNMENT_LIST:
      '/teacher/courses/student-management/course/:courseId/student/:registrationKey/assignments',
    GET_STUDENT_INFO_BASIC_BY_SUBCLASS_KEY_PAGED:
      '/hs-contact/by-subclass-key/:subClassKey/basic/paged',
    GET_COURSE_RELATED_PERSON_BY_SUBCLASS_KEY_AND_REGISTRATION_KEY:
      '/student-management/courses/:subClassKey/keys/:studentRegistrationKey/related-person',
    GET_COURSE_CONTACT_RELATED_PERSON: '/student-management/contact/related-person',
    GET_STUDENT_MY_PERSONAL_INFO: '/student-management/personal-info/me',

    GET_STUDENT_MANAGEMENT_FEEDBACK:
      'course-sheet-manager/op-feedback/course/:subClassKey/student/:registrationKey/feedback',
    GET_STUDENT_DICTATION_LIST:
      '/student-management/students/:registrationKey/courses/:courseId/dictations',
    GET_STUDENT_VOCAB_SET_LIST:
      '/student-management/students/:registrationKey/courses/:courseId/vocab-sets',
    GET_STUDENT_SAT_ONLINE_TEST_LIST:
      '/student-management/students/:registrationKey/courses/:courseId/sat-tests',
    GET_STUDENT_ONLINE_TEST_LIST:
      '/student-management/students/:registrationKey/courses/:courseId/online-tests',
    GET_STUDENT_SAMPLE_LIST:
      '/student-management/students/:registrationKey/courses/:courseId/samples',
    GET_STUDENT_BLOG_LIST: '/student-management/students/:registrationKey/courses/:courseId/blogs',
    GET_STUDENT_EXERCISE_LIST:
      '/student-management/students/:registrationKey/courses/:courseId/exercises',

    GET_COURSE_PRACTICE: '/resources/course/:courseId/total-practice',
    GET_STUDENT_AI_MOCK_TEST_LIST:
      '/student-management/students/:registrationKey/courses/:courseId/ai-mock-tests',
    GET_LIST_OF_SESSIONS_BY_SUBCLASS_KEY:
      '/course-sheet-manager/op-schedule-data/session-info/:subClassKey/list-all',

    GET_COURSE_USER_RELATED_PERSON: '/course-appsheet/:subClassKey/related-person',

    GET_MANAGED_BY_ME_SESSION_NEED_ATTENDANCE_PAGED:
      '/managed-by-me/attendances/sessions/need-attendance/paged',
    GET_MANAGED_BY_ME_SESSION_NEED_ATTENDANCE_COURSE_DETAIL_PAGED:
      '/managed-by-me/attendances/sessions/need-attendance/course/:subClassKey/paged',
    GET_MANAGED_BY_ME_SESSION_ALL_COURSE_DETAIL_PAGED:
      '/managed-by-me/attendances/sessions/all/course/:subClassKey/paged',
    GET_COURSE_IN_PROGRESS_N_COMPLETED_MISSING_PAGED:
      '/managed-by-me/attendances/courses/in-progress-n-completed/missing/paged',
    GET_SESSIONS_COMPLETED_LAST_MONTH: '/managed-by-me/attendances/sessions/completed/last-month',
    GET_SESSIONS_PAGED: '/managed-by-me/attendances/sessions/paged',
    GET_SESSIONS_COURSE_DETAIL_PAGED:
      '/managed-by-me/attendances/sessions/course/:subClassKey/paged',
    GET_COURSES_ATTENDANCES_PAGED: '/managed-by-me/attendances/courses/all/paged',
    GET_STUDENTS_NEED_CONTACT_PAGED: '/managed-by-me/attendances/students/need-contact/paged',
    GET_STUDENTS_NEED_CONTACT_COURSE_DETAIL_PAGED:
      '/managed-by-me/attendances/students/need-contact/course/:subClassKey/paged',
    GET_STUDENTS_NEED_CONTACT_STATISTIC:
      '/managed-by-me/attendances/students/need-contact/statistic',
    GET_COURSES_IN_PROGRESS_N_COMPLETED_NEED_CONTACT_PAGED:
      '/managed-by-me/attendances/courses/in-progress-n-completed/need-contact/paged',
    GET_STUDENTS_NEED_CONTACT_ARCHIVED_PAGED:
      '/managed-by-me/attendances/students/need-contact/archived/paged',
    GET_STUDENTS_NEED_CONTACT_ARCHIVED_COURSE_DETAIL_PAGED:
      '/managed-by-me/attendances/students/need-contact/course/:subClassKey/archived/paged',
    GET_STUDENTS_RED_FLAG_PAGED: '/managed-by-me/attendances/students/red-flag/paged',
    GET_STUDENTS_RED_FLAG_SESSION_STATISTIC:
      '/managed-by-me/attendances/students/red-flag-session/statistic',
    GET_STUDENTS_RED_FLAG_IN_COURSE_STATISTIC:
      '/managed-by-me/attendances/students/red-flag-in-course/statistic',
    GET_STUDENTS_RED_FLAG_OVERVIEW:
      '/managed-by-me/attendances/courses/:subClassKey/student/:registrationKey/red-flag/overview',
    GET_STUDENTS_RED_FLAG_IN_COURSE_PAGED:
      '/managed-by-me/attendances/students/red-flag-in-course/paged',
    GET_STUDENTS_RED_FLAG_COURSE_DETAIL_PAGED:
      '/managed-by-me/attendances/students/red-flag/course/:subClassKey/paged',
    GET_COURSES_IN_PROGRESS_N_COMPLETED_RED_FLAG_PAGED:
      '/managed-by-me/attendances/courses/in-progress-n-completed/red-flag/paged',
    GET_STUDENTS_RED_FLAG_ARCHIVED_PAGED:
      '/managed-by-me/attendances/students/red-flag/archived/paged',
    GET_STUDENTS_RED_FLAG_ARCHIVED_COURSE_DETAIL_PAGED:
      '/managed-by-me/attendances/students/red-flag/course/:subClassKey/archived//paged',

    GET_STUDENTS_NEED_MAKE_UP_SESSION_PAGED:
      '/managed-by-me/attendances/students/need-make-up-session/paged',
    GET_STUDENTS_NEED_MAKE_UP_SESSION_NORMAL_PAGED:
      '/managed-by-me/attendances/students/need-make-up-session/normal/paged',
    GET_STUDENTS_NEED_MAKE_UP_SESSION_SPECIAL_PAGED:
      '/managed-by-me/attendances/students/need-make-up-session/special/paged',
    GET_STUDENTS_NEED_MAKE_UP_SESSION_BOOK_MARKED_PAGED:
      '/managed-by-me/attendances/students/need-make-up-session/book-marked/paged',
    GET_STUDENTS_NEED_MAKE_UP_SESSION_INTERRUPTED_PAGED:
      '/managed-by-me/attendances/students/need-make-up-session/interrupted/paged',
    GET_STUDENTS_NEED_MAKE_UP_SESSION_ARCHIVED_PAGED:
      '/managed-by-me/attendances/students/need-make-up-session/archived/paged',

    GET_STUDENTS_NEED_MAKE_UP_SESSION_COURSE_DETAIL_PAGED:
      '/managed-by-me/attendances/students/need-make-up-session/course/:subClassKey/paged',
    GET_STUDENTS_NEED_MAKE_UP_SESSION_NORMAL_COURSE_DETAIL_PAGED:
      '/managed-by-me/attendances/students/need-make-up-session/normal/course/:subClassKey/paged',
    GET_STUDENTS_NEED_MAKE_UP_SESSION_SPECIAL_COURSE_DETAIL_PAGED:
      '/managed-by-me/attendances/students/need-make-up-session/special/course/:subClassKey/paged',
    GET_STUDENTS_NEED_MAKE_UP_SESSION_BOOK_MARKED_COURSE_DETAIL_PAGED:
      '/managed-by-me/attendances/students/need-make-up-session/book-marked/course/:subClassKey/paged',
    GET_STUDENTS_NEED_MAKE_UP_SESSION_ARCHIVED_COURSE_DETAIL_PAGED:
      '/managed-by-me/attendances/students/need-make-up-session/course/:subClassKey/archived/paged',

    GET_COURSES_IN_PROGRESS_N_COMPLETED_NEED_MAKE_UP_SESSION_PAGED:
      '/managed-by-me/attendances/courses/in-progress-n-completed/need-make-up-session/paged',

    GET_GENERAL_STATISTIC_TEACHER: '/managed-by-me/attendances/statistic/general/teacher',
    GET_GENERAL_STATISTIC_COURSE_DETAIL:
      '/managed-by-me/attendances/statistic/general/course/:subClassKey',
    GET_ATTENDANCE_MISSING_TEACHER_PAGED:
      '/managed-by-me/attendances/statistic/attendance/missing/teacher/paged',
    GET_ATTENDANCE_LATE_TEACHER_PAGED:
      '/managed-by-me/attendances/statistic/attendance/late/teacher/paged',
    GET_GENERAL_STATISTIC_STUDENT: '/managed-by-me/attendances/statistic/general/student',
    GET_GENERAL_STATISTIC_STUDENT_COURSE_DETAIL:
      '/managed-by-me/attendances/statistic/general/student/course/:subClassKey',
    GET_ATTENDANCE_MISSING_STUDENT_TOP_5:
      '/managed-by-me/attendances/statistic/attendance/missing/student/top-5',
    GET_ATTENDANCE_MISSING_STUDENT_TOP_5_COURSE_DETAIL:
      '/managed-by-me/attendances/statistic/attendance/missing/student/top-5/course/:subClassKey',
    GET_ATTENDANCE_MISSING_STUDENT_COURSE_PAGED:
      '/managed-by-me/attendances/statistic/attendance/missing/student/course/:subClassKey/paged',
    GET_ATTENDANCE_RED_FLAG_STUDENT_TOP_5:
      '/managed-by-me/attendances/statistic/attendance/red-flag/student/top-5',
    GET_ATTENDANCE_RED_FLAG_STUDENT_TOP_5_COURSE_DETAIL:
      '/managed-by-me/attendances/statistic/attendance/red-flag/student/top-5/course/:subClassKey',
    GET_ATTENDANCE_RED_FLAG_STUDENT_COURSE_PAGED:
      '/managed-by-me/attendances/statistic/attendance/red-flag/student/course/:subClassKey/paged',
    POST_MARK_STUDENT_ATTENDANCE_NEED_CONTACT:
      '/managed-by-me/attendances/student/:id/need-contact',
    POST_MARK_STUDENT_ATTENDANCE_NEED_CONTACT_CANCEL_PRESENT_ONLINE:
      '/managed-by-me/attendances/student/:id/need-contact/cancel-present-online',

    POST_MARK_STUDENT_ATTENDANCE_NEED_CONTACT_ARCHIVED:
      '/managed-by-me/attendances/student/:id/need-contact/archived',
    POST_MARK_STUDENT_ATTENDANCE_NEED_CONTACT_UNARCHIVED:
      '/managed-by-me/attendances/student/:id/need-contact/unarchived',

    POST_MARK_STUDENT_ATTENDANCE_NEED_INTERVENTION:
      '/managed-by-me/attendances/student/:id/need-intervention',
    POST_MARK_STUDENT_ATTENDANCE_NEED_INTERVENTION_ARCHIVED:
      '/managed-by-me/attendances/student/:id/need-intervention/archived',
    POST_MARK_STUDENT_ATTENDANCE_NEED_INTERVENTION_UNARCHIVED:
      '/managed-by-me/attendances/student/:id/need-intervention/unarchived',

    POST_MARK_STUDENT_RED_FLAG_IN_COURSE_NEED_INTERVENTION:
      '/managed-by-me/attendances/student-red-flag-in-course/:id/need-intervention',
    POST_MARK_STUDENT_RED_FLAG_IN_COURSE_NEED_INTERVENTION_ARCHIVED:
      '/managed-by-me/attendances/student-red-flag-in-course/:id/need-intervention/archived',
    POST_MARK_STUDENT_ATTENDANCE_NEED_INTERVENTION_UNARCHIVED_UNARCHIVED:
      '/managed-by-me/attendances/student-red-flag-in-course/:id/need-intervention/unarchived',

    POST_MARK_STUDENT_ATTENDANCE_NEED_MAKE_UP_SESSION:
      '/managed-by-me/attendances/student/:id/need-make-up-session',
    POST_MARK_STUDENT_ATTENDANCE_NEED_MAKE_UP_SESSION_ARCHIVED:
      '/managed-by-me/attendances/student/:id/need-make-up-session/archived',
    POST_BOOKMARK_MAKEUP_SESSION:
      '/managed-by-me/attendances/student/:id/need-make-up-session/bookmark',
    POST_UN_BOOKMARK_MAKEUP_SESSION:
      '/managed-by-me/attendances/student/:id/need-make-up-session/un-bookmark',

    POST_MARK_STUDENT_ATTENDANCE_NEED_MAKE_UP_SESSION_UNARCHIVED:
      '/managed-by-me/attendances/student/:id/need-make-up-session/unarchived',
    GET_LIST_RELATE_TO_SCHEDULE_KEY:
      '/course-sheet-manager/op-schedule-data/relate-to-schedule-data-key/:scheduleDataKey',
    GET_CDCM_LIST_RELATE_TO_SCHEDULE_KEY:
      '/course-sheet-manager/op-schedule-data/cdcm/relate-to-schedule-data-key/:scheduleDataKey',
    GET_CDCM_SPECIAL_LIST_RELATE_TO_SCHEDULE_KEY:
      '/course-sheet-manager/op-schedule-data/cdcm/special/relate-to-schedule-data-key',
    GET_LIST_RELATE_TO_SUB_CLASS_KEY:
      '/course-sheet-manager/op-schedule-data/relate-to-course/:subClassKey',

    POST_MARK_STUDENT_ATTENDANCE_NEED_MAKE_UP_SESSION_UPSERT:
      '/managed-by-me/attendances/student/:id/need-make-up-session/upsert',
    POST_MARK_STUDENT_ATTENDANCE_NEED_MAKE_UP_SESSION_ONE_TO_TWO_UPSERT:
      '/managed-by-me/attendances/student/:id/need-make-up-session/one-to-two/upsert',
    POST_MARK_STUDENT_ATTENDANCE_NEED_MAKE_UP_SESSION_TWO_TO_ONE_UPSERT:
      '/managed-by-me/attendances/student/:id/need-make-up-session/two-to-one/upsert',
    POST_MARK_STUDENT_ATTENDANCE_NEED_MAKE_UP_SESSION_PROJECTED_UPDATE:
      '/managed-by-me/attendances/student/:id/need-make-up-session/projected/update',
    POST_MARK_STUDENT_ATTENDANCE_NEED_MAKE_UP_SESSION_CANCEL:
      '/managed-by-me/attendances/student/:id/need-make-up-session/cancel',

    GET_MANAGED_BY_ME_SCHEDULE_INFO_DETAIL:
      '/managed-by-me/attendances/schedule-info/:scheduleDataKey',
    GET_STUDENT_COURSE_DETAIL_SESSION_HISTORY_GENERAL_INFO:
      '/managed-by-me/attendances/students/:subClassKey/session/history/general-info',
    GET_STUDENT_COURSE_DETAIL_SESSION_HISTORY_MARK_PRESENT_ONLINE:
      '/managed-by-me/attendances/students/:subClassKey/session/history/mark-present-online',
    GET_MAKE_UP_SESSION_ATTENDANCES:
      '/managed-by-me/attendances/course/:subClassKey/schedule/:scheduleDataKey/make-up-session/attendances',
    GET_HISTORY_MAKE_UP_SESSION_BY_ATTENDANCE_ID:
      '/managed-by-me/attendances/make-up-session/by-attendance-id/:attendanceAbsentId',

    GET_HISTORY_MAKE_UP_SESSION_BY_CONTACT_ID:
      '/managed-by-me/attendances/make-up-session/by-contact-id/:contactId',

    GET_LIST_SCHEDULE_MAKE_UP_SESSION_BY_STUDENT_HUBSPOT_ID:
      '/course-sheet-manager/op-schedule-data/makeup-session/schedule-by-hubspot-id/:studentHsObjectId',
    // trial course
    GET_TRIAL_COURSE_STUDENT_REGISTRATION_INFO_PUBLIC:
      '/student-course/my-course-trial/:trialCourseKey/info/me',
    GET_TRIAL_COURSE_INFO_PUBLIC: '/trial-course/by-key/:trialCourseKey',
    GET_STUDENT_MY_CERTIFICATIONS_TRIAL: '/student-course/certifications-trial/me',

    POST_MARK_UNKNOWN_ZOOM_ONLINE: '/managed-by-me/attendances/student/:id/unknown-zoom-online',
    POST_MARK_UNKNOWN_ZOOM_ONLINE_ARCHIVED:
      '/managed-by-me/attendances/student/:id/unknown-zoom-online/archived',
    POST_MARK_UNKNOWN_ZOOM_ONLINE_UNARCHIVED:
      '/managed-by-me/attendances/student/:id/unknown-zoom-online/unarchived',
    GET_UNKNOWN_ZOOM_ONLINE_PAGED: '/managed-by-me/attendances/students/unknown-zoom-online/paged',
    GET_UNKNOWN_ZOOM_ONLINE_STATISTIC:
      '/managed-by-me/attendances/students/unknown-zoom-online/statistic',
    GET_UNKNOWN_ZOOM_ONLINE_ARCHIVED_PAGED:
      '/managed-by-me/attendances/students/unknown-zoom-online/archived/paged',
    GET_COURSES_IN_PROGRESS_N_COMPLETED_UNKNOWN_ZOOM_ONLINE_PAGED:
      '/managed-by-me/attendances/courses/in-progress-n-completed/unknown-zoom-online/paged',
    GET_STUDENTS_UNKNOWN_ZOOM_ONLINE_COURSE_DETAIL_PAGED:
      '/managed-by-me/attendances/students/unknown-zoom-online/course/:subClassKey/paged',

    GET_CS_NOTE_MANAGEMENT_BY_PHASE_PAGED: '/cs-note/manage-by-course-phase/paged',
    GET_CS_NOTE_MANAGEMENT_BY_PHASE_STATISTIC: '/cs-note/manage-by-course-phase/statistic',
    GET_CS_NOTE_MANAGEMENT_BY_COURSE_PAGED: '/cs-note/manage-by-course/paged',
    UPDATE_ATTENDANCE_EXTRA_EXPIRED_DATE: '/session/:scheduleDataKey/extend-attendance',
    UPDATE_SESSION_EXTRA_SUPPLY_TEACHER: '/session/:scheduleDataKey/update-supply-teacher',
    REMOVE_SESSION_EXTRA_SUPPLY_TEACHER: '/session/:scheduleDataKey/remove-supply-teacher',

    // SEARCH STUDENT
    SEARCH_STUDENT_BY_SUB_CLASS_KEY_AND_KEY_WORD: '/student-filter',

    CENTER_DIRECTOR_ADJUST_ATTENDANCE_ABSENT_TO_PRESENT:
      '/attendance-adjustment/attendance/:attendanceId/present-to-absent',
    CENTER_DIRECTOR_ADJUST_ATTENDANCE_UNKNOWN: '/attendance-adjustment/attendance/unknown',
    GET_ADJUSTMENT_SESSION_ATTENDANCE_BY_SCHEDULE_DATA_KEY:
      '/attendance-adjustment/session/:scheduleDataKey',

    GET_STUDENT_NEED_TO_REMIND_FEEDBACK: '/course-sheet-manager/op-feedback/need-to-remind',
    GET_STUDENT_NEED_TO_REMIND_ENTRANCE_FORM: '/entrance-form-manager/need-to-remind',
    GET_STUDENT_NEED_TO_REMIND_ENTRANCE_FORM_STATISTIC:
      '/entrance-form-manager/need-to-remind/statistic',
    GET_STUDENT_NEED_TO_REMIND_FEEDBACK_STATISTIC:
      '/course-sheet-manager/op-feedback/need-to-remind/statistic',
    GET_STUDENT_NEED_TO_REMIND_FEEDBACK_BY_COURSE:
      '/course-sheet-manager/op-feedback/need-to-remind-by-course',
    POST_MARK_STUDENT_FEEDBACK_NEED_REMIND:
      '/course-sheet-manager/op-feedback/reminder-tracking-log/:id/remind',

    POST_MARK_STUDENT_ENTRANCE_FORM_NEED_REMIND:
      '/entrance-form-manager/reminder-tracking-log/:id/remind',

    GET_ADJUST_ATTENDANCE_LISTING_PAGED: '/attendance-adjustment/paged',
    POST_CANCEL_ADJUST_ATTENDANCE: '/attendance-adjustment/:id/cancel-adjustment',

    GET_STUDENT_REQUEST_XEP_HOC_BU_FORM: '/request-form/xep-hoc-bu/paged',
    GET_STUDENT_REQUEST_GIA_HAN_TAI_KHOAN_FORM: '/request-form/gia-han-tai-khoan/paged',
    GET_STUDENT_REQUEST_GIA_HAN_TAI_KHOAN_FORM_STATISTIC:
      '/request-form/gia-han-tai-khoan/statistic',

    GET_STUDENT_REQUEST_DANG_KY_THI_FORM: '/request-form/dang-ky-thi/paged',
    GET_STUDENT_REQUEST_DANG_KY_THI_FORM_STATISTIC: '/request-form/dang-ky-thi/statistic',
    GET_STUDENT_REQUEST_HOC_SONG_SONG_FORM: '/request-form/hoc-song-song/paged',
    GET_STUDENT_REQUEST_HOC_SONG_SONG_FORM_STATISTIC: '/request-form/hoc-song-song/statistic',

    GET_STUDENT_REQUEST_DOI_LOP_FORM: '/request-form/doi-lop/paged',
    GET_STUDENT_REQUEST_DOI_LOP_FORM_STATISTIC: '/request-form/doi-lop/statistic',
    GET_STUDENT_REQUEST_HO_TRO_HOC_LAI_FORM: '/request-form/ho-tro-hoc-lai/paged',
    GET_STUDENT_REQUEST_HO_TRO_HOC_LAI_FORM_STATISTIC: '/request-form/ho-tro-hoc-lai/statistic',
    GET_STUDENT_REQUEST_BAO_DIEM_THI_FORM: '/request-form/bao-diem-thi/paged',
    GET_STUDENT_REQUEST_BAO_DIEM_THI_FORM_STATISTIC: '/request-form/bao-diem-thi/statistic',
    GET_STUDENT_REQUEST_BAO_LUU_HOC_LAI_FORM: '/request-form/bao-luu-hoc-lai/paged',
    GET_STUDENT_REQUEST_BAO_LUU_HOC_LAI_STATISTIC: '/request-form/bao-luu-hoc-lai/statistic',
    GET_STUDENT_REQUEST_BAO_LUU_FORM: '/request-form/bao-luu/paged',
    GET_STUDENT_REQUEST_BAO_LUU_STATISTIC: '/request-form/bao-luu/statistic',
    GET_STUDENT_REQUEST_XIN_GIAY_XAC_NHAN_FORM: '/request-form/xin-giay-xac-nhan/paged',
    GET_STUDENT_REQUEST_XIN_GIAY_XAC_NHAN_STATISTIC: '/request-form/xin-giay-xac-nhan/statistic',
    GET_STUDENT_REQUEST_ACTION_HISTORY: '/request-form/history/:id',
    GET_STUDENT_REQUEST_XEP_HOC_BU_FORM_STATISTIC: '/request-form/xep-hoc-bu/statistic',
  },
  midFinalTest: {
    MERGE_DUPLICATE_USER_FINAL_TEST_FROM_OLD_HS_OBJECT_ID: '/hs-contact/merge-user-final-test',
    GET_TEACHER_FINAL_TEST_STATISTIC_PAGED: '/teacher-statistic/paged',
    GET_TEACHER_FINAL_TEST_HAVE_ACTIVE_IN_LAST_3_DAYS: '/teacher-statistic/have-active/last-3-days',
    GET_TATM_FINAL_TEST_HAVE_ACTIVE_IN_LAST_3_DAYS: '/ta-statistic/have-active/last-3-days',

    GET_COURSE_SHEET_STUDENT_FINAL_TEST_PROGRESS_LIST:
      '/course-sheet/final-test/:courseSheetFinalTestId/list-student-progress',
    GET_COURSE_SHEET_STUDENT_FINAL_TEST_PROGRESS_FOR_TEACHER_LIST:
      '/course-sheet/final-test/:courseSheetFinalTestId/teacher/list-student-progress',

    VALIDATION_TEST_NAME: '/final-tests/info/existed',
    GET_TEACHER_FINAL_TEST_OVERVIEW: '/user-final-tests/marking/overview',
    GET_TEACHER_FINAL_TEST_MARKING_STATISTIC:
      '/user-final-tests/marking/teacher-statistic/:teacherId',
    GET_TEACHER_FINAL_TEST_MARKING_STATISTIC_DETAIL:
      '/user-final-tests/marking/teacher-statistic/:teacherId/detail',

    GET_TATM_FINAL_TEST_MARKING_STATISTIC: '/user-final-tests/marking/ta-statistic/:userId',
    GET_TEACHER_FINAL_TEST_STATISTIC_DETAIL_HISTORIES:
      '/user-final-tests/marking/teacher-statistic/:teacherId/history',
    GET_TATM_FINAL_TEST_STATISTIC_DETAIL_HISTORIES:
      '/user-final-tests/marking/ta-statistic/:userId/history',
    GET_MARKING_HISTORIES_BY_SKILL: '/marking/skill-history/:id/skill/:skillType/histories',
    GET_MARKING_HISTORY_VERSION_DETAIL: '/marking/skill-history/version-history/:versionId',
    PUT_MARKING_APPLY_HISTORY_VERSION:
      '/marking/skill-history/:id/skill/:skillType/histories/:versionId',
    PUT_MARKING_REVERT_HISTORY_TO_ORIGINAL_VERSION:
      '/marking/skill-history/:id/skill/:skillType/histories-rollback',

    GET_USER_FINAL_TEST_PAGED: '/user-final-tests/marking/paged',
    GET_USER_FINAL_TEST_ECCS_PAGED: '/user-final-tests/eccs/paged',
    GET_HS_USER_FINAL_TEST_PAGED: '/hs-contact/uft/paged',
    GET_USER_FINAL_TEST_TEACHER_PAGED: '/user-final-tests/teacher/paged',
    GET_USER_FINAL_TEST_TATM_PAGED: '/user-final-tests/tatm/paged',
    GET_TEACHER_COURSE_SHEET_PAGED: '/course-sheet/final-test/paged/teacher',
    GET_TEACHER_COURSE_SHEET_CS: '/course-sheet/final-test/paged/cs',
    GET_TEACHER_COURSE_SHEET_DETAIL: '/course-sheet/final-test/detail/:id/teacher',
    GET_USER_FINAL_TEST_SKILL_TIME_SPENT: '/user-final-tests/:id/skill/:skill/time-spent',
    POST_SUBMIT_MARKED_SKILL_TO_STUDENT: '/user-final-tests/:id/skill/:skill/sent-to-student',

    GET_MY_COURSE_USER_FINAL_TEST_PAGED: '/user-final-tests/marking/my-course/paged',
    GET_TEACHER_IN_SUBMIT_PAGED: '/user-final-tests/marking/offline/in-submit/paged',
    GET_TEACHER_MARKED_PAGED: '/user-final-tests/marking/marked/paged',
    GET_TEACHER_MISSING_SKILLS_PAGED: '/user-final-tests/marking/missing-skill/paged',
    GET_TEACHER_IN_MARKING_PAGED: '/user-final-tests/marking/in-marking/paged',
    GET_TEACHER_WAITING_PAGED: '/user-final-tests/marking/waiting/paged',
    GET_FINAL_TEST: '/final-tests/:id',
    GET_LIST_FINAL_TEST: '/final-tests',
    PUBLISH_FINAL_TEST: '/final-tests/:id/publish',
    UPDATE_FINAL_TEST: '/final-tests/:id',
    CREATE_FINAL_TEST: '/final-tests',
    GET_FINAL_DETAIL: '/final-tests/:id',
    DELETE_FINAL_TEST: '/final-tests/:id',

    GET_FINAL_TEST_MARKING_HISTORY: '/user-final-tests/:id/history/marking',
    GET_FINAL_TEST_SKILL_STATUS_SUMMARIZE:
      '/user-final-tests/:id/skills/status-summarize/:historyType',

    POST_ONLINE_OFFLINE_FINAL_TEST_SUBMISSION_SUBMIT: '/user-final-tests/:id/submission/submit',
    PUT_OFFLINE_FINAL_TEST_SUBMISSION_SKILLS_SAVE:
      '/offline/user-final-tests/:id/submission/skills',

    GET_COURSE_FINAL_TEST_INFO_FOR_CURRENT_USER: '/user-final-tests/courses/:courseId/me',
    GET_COURSE_FINAL_TEST_FOR_STUDENT: '/student/:subClassKey/:studentRegistrationKey/final-test',
    GET_FINAL_TEST_INFO: '/user-final-tests/:id/info',
    POST_FINAL_TEST_CALCULATE_SCORE: '/user-final-tests/:id/marking/calculate-score',

    PUT_ONLINE_SAVE_READING_MARKING: '/online/user-final-tests/:id/marking/reading',
    POST_ONLINE_SUBMIT_READING_MARKING: '/online/user-final-tests/:id/marking/reading/submit',
    PUT_ONLINE_SAVE_LISTENING_MARKING: '/online/user-final-tests/:id/marking/listening',
    POST_ONLINE_SUBMIT_LISTENING_MARKING: '/online/user-final-tests/:id/marking/listening/submit',
    PUT_ONLINE_SAVE_WRITING_MARKING: '/online/user-final-tests/:id/marking/writing',
    POST_ONLINE_SUBMIT_WRITING_MARKING: '/online/user-final-tests/:id/marking/writing/submit',
    PUT_ONLINE_SAVE_SPEAKING_MARKING: '/online/user-final-tests/:id/marking/speaking',
    POST_ONLINE_SUBMIT_SPEAKING_MARKING: '/online/user-final-tests/:id/marking/speaking/submit',

    PUT_ONLINE_SAVE_GRAMMAR_MARKING: '/online/user-final-tests/:id/marking/grammar',
    POST_ONLINE_SUBMIT_GRAMMAR_MARKING: '/online/user-final-tests/:id/marking/grammar/submit',

    PUT_ONLINE_SAVE_VOCAB_MARKING: '/online/user-final-tests/:id/marking/vocab',
    POST_ONLINE_SUBMIT_VOCAB_MARKING: '/online/user-final-tests/:id/marking/vocab/submit',

    PUT_OFFLINE_UPDATE_MARKING_SKILL: '/offline/user-final-tests/:id/marking/skills',

    PUT_CHANGE_ONLINE_TO_OFFLINE_SKILL: '/user-final-tests/:id/make-online-to-offline-progress',

    POST_CONFIRM_LEAVE_BLANK_AT_SKILL: '/user-final-tests/:id/skill/:skillType/confirm-leave-blank',
    POST_CONFIRM_RE_SUBMIT: '/user-final-tests/:id/skill/:skillType/request-re-submit',
    POST_UPDATE_BLANK_SKILL_SCORE: '/user-final-tests/:id/skill/:skillType/update-blank-score',
    GET_SKILL_WHO_EDIT: '/user-final-tests/:id/skill/:skillType/check-edit',
    POST_START_INPUT_ON_SKILL: '/user-final-tests/:id/skill/:skillType/start-input',
    POST_END_INPUT_ON_SKILL: '/user-final-tests/:id/skill/:skillType/end-input',

    GET_SKILL_MARKING_WHO_EDIT: '/user-final-tests/:id/skill/:skillType/check-edit-marking',
    POST_START_MARKING_ON_SKILL: '/user-final-tests/:id/skill/:skillType/start-marking',
    POST_END_MARKING_ON_SKILL: '/user-final-tests/:id/skill/:skillType/end-marking',

    GET_SKILL_REPORT_ONLINE: '/online/user-final-tests/:id/detail/skills/:skillType',
    GET_SKILL_REPORT_OFFLINE: '/offline/user-final-tests/:id/detail/skills/:skillType',
    VALIDATION_USER: '/online-final-tests/:userFinalTestId/check',
    GET_ONLINE_FINAL_TEST_PROGRESS: '/online-final-tests/:userFinalTestId/progress',
    GET_ONLINE_FINAL_TEST_DATA_FOR_EMAIL: '/resources/:userFinalTestId/data-for-email',
    GET_ONLINE_FINAL_TEST_ROLLING_DATA_FOR_EMAIL: '/resources/:userFinalTestId/data-for-email',
    POST_START_ONLINE_FINAL_TEST_PROGRESS: '/online-final-tests/:userFinalTestId/start',

    POST_START_ONLINE_FINAL_TEST_READING_PRACTICE_TEST:
      '/online-final-tests/:userFinalTestId/reading/practice-test/:testId/start',
    POST_START_ONLINE_FINAL_TEST_QUIZ_TEST:
      '/online-final-tests/:userFinalTestId/quiz-test/:testId/start',
    POST_SAVE_ONLINE_FINAL_TEST_QUIZ_TEST: '/online-final-tests/:userFinalTestId/quiz-test/:testId',
    PUT_SAVE_ONLINE_FINAL_TEST_READING_PRACTICE_TEST:
      '/online-final-tests/:userFinalTestId/reading/practice-test/:testId',
    POST_SUBMIT_ONLINE_FINAL_TEST_READING_PRACTICE_TEST:
      '/online-final-tests/:userFinalTestId/reading/practice-test/:testId/submit',
    POST_SUBMIT_ONLINE_FINAL_TEST_QUIZ_TEST:
      '/online-final-tests/:userFinalTestId/quiz-test/:testId/submit',

    POST_START_ONLINE_FINAL_TEST_READING_FULL_TEST:
      '/online-final-tests/:userFinalTestId/reading/full-test/:testId/start',
    PUT_SAVE_ONLINE_FINAL_TEST_READING_FULL_TEST:
      '/online-final-tests/:userFinalTestId/reading/full-test/:testId',
    POST_SUBMIT_ONLINE_FINAL_TEST_READING_FULL_TEST:
      '/online-final-tests/:userFinalTestId/reading/full-test/:testId/submit',

    POST_START_ONLINE_FINAL_TEST_LISTENING_PRACTICE_TEST:
      '/online-final-tests/:userFinalTestId/listening/practice-test/:testId/start',
    PUT_SAVE_ONLINE_FINAL_TEST_LISTENING_PRACTICE_TEST:
      '/online-final-tests/:userFinalTestId/listening/practice-test/:testId',
    POST_SUBMIT_ONLINE_FINAL_TEST_LISTENING_PRACTICE_TEST:
      '/online-final-tests/:userFinalTestId/listening/practice-test/:testId/submit',

    POST_START_ONLINE_FINAL_TEST_LISTENING_FULL_TEST:
      '/online-final-tests/:userFinalTestId/listening/full-test/:testId/start',

    GET_COLLECT_LISTENING_AUDIO:
      '/online-final-tests/:userFinalTestId/progress/listening/collect-audios',
    PUT_SAVE_ONLINE_FINAL_TEST_LISTENING_FULL_TEST:
      '/online-final-tests/:userFinalTestId/listening/full-test/:testId',
    POST_SUBMIT_ONLINE_FINAL_TEST_LISTENING_FULL_TEST:
      '/online-final-tests/:userFinalTestId/listening/full-test/:testId/submit',

    POST_START_ONLINE_FINAL_TEST_WRITING_TEST:
      '/online-final-tests/:userFinalTestId/writing/:assignmentId/start',
    PUT_SAVE_ONLINE_FINAL_TEST_WRITING_TEST:
      '/online-final-tests/:userFinalTestId/writing/:assignmentId',
    POST_SUBMIT_ONLINE_FINAL_TEST_WRITING_TEST:
      '/online-final-tests/:userFinalTestId/writing/:assignmentId/submit',
    POST_START_ONLINE_FINAL_TEST_SPEAKING_TEST:
      '/online-final-tests/:userFinalTestId/speaking/:assignmentId/start',
    PUT_SAVE_ONLINE_FINAL_TEST_SPEAKING_TEST:
      '/online-final-tests/:userFinalTestId/speaking/:assignmentId',
    POST_SUBMIT_ONLINE_FINAL_TEST_SPEAKING_TEST:
      '/online-final-tests/:userFinalTestId/speaking/:assignmentId/submit',

    POST_SUBMIT_ONLINE_OFFLINE_TEST_MARKING: '/user-final-tests/:id/marking/submit',
    POST_SUBMIT_SUBCLASS_ONLINE_OFFLINE_TEST_MARKING:
      '/user-final-tests/:id/:subClassKey/marking/submit',

    POST_REMARK_ONLINE_OFFLINE_TEST_MARKING: '/user-final-tests/:id/marking/remarked',
    POST_REMARK_SUBCLASS_ONLINE_OFFLINE_TEST_MARKING:
      '/user-final-tests/:id/:subClassKey/marking/remarked',
    GET_USER_FINAL_TEST_SKILL_INFO_POPOVER: '/user-final-tests/:id/:subClassKey/marking/remarked',

    POST_OFFLINE_START_MARKING: '/offline/user-final-tests/:id/marking/start',
    POST_ONLINE_START_MARKING: '/online/user-final-tests/:id/marking/start',

    GET_COURSE_SHEET_FINAL_TEST_LISTING: '/course-sheet/final-test/paged',

    GET_FINAL_TEST_FROM_SELECTED_COURSE_ID: '/course-sheet-manager/final-test/by-opcourse-id/:id',
    CREATE_COURSE_SHEET_FINAL_TEST: '/course-sheet/final-test',
    UPDATE_COURSE_SHEET_FINAL_TEST_DEADLINE: '/course-sheet/final-test/:id/extend-deadline',
    UPDATE_COURSE_SHEET_FINAL_TEST_TEST_MODE: '/course-sheet/final-test/:id/change-mode',
    CHECK_COURSE_HAVE_FINAL_TEST:
      '/course-sheet-manager/op-course-info/:opCourseKey/have-final-test',
    GET_STUDENT_FINAL_NEED_TO_COMPLTE:
      '/course-sheet/final-test/:opCourseKey/student/:studentRegistrationKey/skill-progress',

    GET_COURSE_SHEET_FINAL_TEST_DETAIL: '/course-sheet/final-test/:id',
    COURSE_SHEET_FINAL_TEST_USER_CHECK: '/course-sheet/final-test/:id/check',
    GET_COURSE_SHEET_FINAL_TEST_STATISTIC:
      '/course-sheet-final-test-progress-statistic/by-course-sheet-id/:courseSheetFinalTestId',
    POST_FORCE_INPUT_OFFLINE_COURSE_SHEET:
      '/user-final-tests/:userFinalTestId/:courseSheetFinalTestId/force-online-to-offline-progress',
    POST_RECREATE_COURSE_SHEET_PROGRESS:
      '/course-sheet/final-test/:courseSheetFinalTestId/re-create-progress',
    GET_FINAL_TEST_BY_TEST_ID: '/final-tests/by-test-id/:finalTestId',
    LIST_COURSE_SHEET_UFT_SKILL_STATUSES_BY_EMAILS:
      '/course-sheet/final-test/op-course/:opCourseKey/list-skill-status/by-emails',

    GET_COURSE_SHEET_FINAL_TEST_HISTORY_OF_USER:
      '/course-sheet/final-test/by-hs-object-id/:hsObjectId',
    DELETE_COURSE_APP_SHEET: '/course-sheet/final-test/:id',
    SEARCH_FINAL_TEST_TEACHER: '/teacher-statistic/search',
    OPEN_COURSE_SHEET_FINAL_TEST_NEW_SKILLS:
      '/urgent/course-sheet-final-test/:courseSheetFinalTestId/open-new-skills',
    POST_FORCE_ONLINE_COURSE_SHEET:
      '/user-final-tests/:userFinalTestId/:courseSheetFinalTestId/force-offline-to-online-progress',
    DELETE_USER_COURSE_SHEET_PROGRESS:
      '/user-final-tests/:userFinalTestId/:courseSheetFinalTestId/delete-progress',
    GET_URGENT_DIFF_TEST_OF_FINAL_TEST: '/urgent/diff-test-list/:courseSheetFinalTestId',
    GET_BASE_FINAL_TEST: '/urgent/base-final-test-progress/:id',
    CHECK_COURSE_OR_SUBCLASS_HAVE_FINAL_TEST: '/course-sheet/final-test/:key/exist-final-test',
    GET_TATM_FINAL_TEST_STATISTIC_PAGED: '/ta-statistic/paged',
    GET_FINAL_TEST_SKILL_INFO_POPOVER: '/user-final-tests/:id/info-skill-popover',
    RESELECT_REGISTRATION_KEY_FINAL_TEST:
      '/user-final-tests/:userFinalTestId/reselect-registration',
    GET_ALL_REGISTRATION_KEY_OF_STUDENT_FROM_FINAL_TEST:
      '/user-final-tests/:userFinalTestId/registration-keys',
    GET_TEACHER_ASSIGNMENT_MARKING_STATISTIC_PAGED: '/employee-marking-statistic/:teacherId/paged',
  },
  entranceTest: {
    GET_SKILL_ENTRANCE_TEST_OVERVIEW: '/student-do-test/:userEntranceTestId/progress',
    GET_LIST_ENTRANCE_TEST: '/entrance-tests',
    CREATE_ENTRANCE_TEST: '/entrance-tests',
    UPDATE_ENTRANCE_TEST: '/entrance-tests/:id',
    GET_ENTRANCE_TEST: '/entrance-tests/:id',
    PUBLISH_ENTRANCE_TEST: '/entrance-tests/:id/publish',
    DELETE_ENTRANCE_TEST: '/entrance-tests/:id',
    VALIDATION_TEST_NAME: '/entrance-tests/info/existed',

    GET_LIST_REPORT_TEST: '/reports',
    GET_LIST_REPORT_TEST_DETAIL: '/reports/:id',

    GET_USER_ENTRANCE_TEST: '/user-entrance-tests',
    CREATE_USER_ENTRANCE_TEST: '/user-entrance-tests',
    UPDATE_USER_ENTRANCE_TEST: '/user-entrance-tests/:id',
    DELETE_USER_ENTRANCE_TEST: '/user-entrance-tests/:id',
    SEARCH_MARKING_FORM: '/marking-forms/search',
    SEARCH_REPORT: '/reports/search',
    GET_DEALS: '/deals',
    SEARCH_CONTACTS: '/hubspot/contacts',
    VALIDATION_DEAL: '/user-entrance-tests/existing',

    VALIDATION_TEST_EMAIL: '/student-do-test/:userEntranceTestId/check',
    GET_ONLINE_ENTRANCE_TEST_PROGRESS: '/student-do-test/:userEntranceTestId/progress',
    GET_ONLINE_ENTRANCE_TEST_PROGRESS_PREVIEW: '/user-entrance-tests/:entranceTestId/preview',
    POST_START_ONLINE_ENTRANCE_TEST_PROGRESS: '/student-do-test/:userEntranceTestId/start',

    PUT_AUTO_SAVE_SKILL: '/student-do-test/:userEntranceTestId/:skillType',
    POST_START_ONLINE_ENTRANCE_TEST_READING_PRACTICE_TEST:
      '/online-entrance-tests/:userEntranceTestId/reading/practice-test/:testId/start',
    // PUT_SAVE_ONLINE_ENTRANCE_TEST_READING_PRACTICE_TEST:
    //   '/online-entrance-tests/:userEntranceTestId/reading/practice-test/:testId',
    POST_SUBMIT_ONLINE_ENTRANCE_TEST_READING_PRACTICE_TEST:
      '/student-do-test/:userEntranceTestId/:skillType',

    POST_START_ONLINE_ENTRANCE_TEST_READING_FULL_TEST:
      '/online-entrance-tests/:userEntranceTestId/reading/full-test/:testId/start',
    PUT_SAVE_ONLINE_ENTRANCE_TEST_READING_FULL_TEST:
      '/online-entrance-tests/:userEntranceTestId/reading/full-test/:testId',
    POST_SUBMIT_ONLINE_ENTRANCE_TEST_READING_FULL_TEST:
      '/online-entrance-tests/:userEntranceTestId/reading/full-test/:testId/submit',

    POST_START_ONLINE_ENTRANCE_TEST_LISTENING_PRACTICE_TEST:
      '/online-entrance-tests/:userEntranceTestId/listening/practice-test/:testId/start',
    PUT_SAVE_ONLINE_ENTRANCE_TEST_LISTENING_PRACTICE_TEST:
      '/online-entrance-tests/:userEntranceTestId/listening/practice-test/:testId',
    POST_SUBMIT_ONLINE_ENTRANCE_TEST_LISTENING_PRACTICE_TEST:
      '/online-entrance-tests/:userEntranceTestId/listening/practice-test/:testId/submit',

    POST_START_ONLINE_ENTRANCE_TEST_LISTENING_FULL_TEST:
      '/online-entrance-tests/:userEntranceTestId/listening/full-test/:testId/start',
    PUT_SAVE_ONLINE_ENTRANCE_TEST_LISTENING_FULL_TEST:
      '/online-entrance-tests/:userEntranceTestId/listening/full-test/:testId',
    POST_SUBMIT_ONLINE_ENTRANCE_TEST_LISTENING_FULL_TEST:
      '/online-entrance-tests/:userEntranceTestId/listening/full-test/:testId/submit',

    POST_ONLINE_START_MARKING: '/marking-user-entrance-tests/:id/marking/start',
    GET_ONLINE_MARKING_PROGRESS: '/online/user-entrance-tests/:id/marking/progress',
    GET_ONLINE_READING_MARKING: '/online/user-entrance-tests/:id/marking/reading/:assignmentId',
    PUT_ONLINE_SAVE_READING_MARKING: '/online/user-entrance-tests/:id/marking/reading',
    POST_ONLINE_SUBMIT_READING_MARKING: '/online/user-entrance-tests/:id/marking/reading/submit',
    GET_ONLINE_LISTENING_MARKING: '/online/user-entrance-tests/:id/marking/listening/:assignmentId',
    PUT_ONLINE_SAVE_LISTENING_MARKING: '/online/user-entrance-tests/:id/marking/listening',
    POST_ONLINE_SUBMIT_LISTENING_MARKING:
      '/online/user-entrance-tests/:id/marking/listening/submit',
    GET_ONLINE_WRITING_MARKING: '/online/user-entrance-tests/:id/marking/writing/:assignmentId',
    PUT_ONLINE_SAVE_WRITING_MARKING: '/online/user-entrance-tests/:id/marking/writing',
    POST_ONLINE_SUBMIT_WRITING_MARKING: '/online/user-entrance-tests/:id/marking/writing/submit',
    GET_ONLINE_SPEAKING_MARKING: '/online/user-entrance-tests/:id/marking/speaking/:assignmentId',
    PUT_ONLINE_SAVE_SPEAKING_MARKING: '/online/user-entrance-tests/:id/marking/speaking',
    POST_ONLINE_SUBMIT_SPEAKING_MARKING: '/online/user-entrance-tests/:id/marking/speaking/submit',
    GET_ONLINE_MARKING: '/online/user-entrance-tests/:userEntranceTestId/marking',

    GET_LIST_USER_ENTRANCE_TEST: '/user-entrance-tests',
    GET_ALL_CONTACTS: '/contacts',
    /** FOR OFFLINE TEST SUBMISSION **/
    START_SUBMIT_OFFLINE_TEST: '/offline/user-entrance-tests/:id/submission/start',
    GET_USER_OFFLINE_TEST_SKILLS: '/user-entrance-tests/:id/skills',
    GET_USER_OFFLINE_TEST_SKILL_DETAIL: '/user-entrance-tests/:id/skills/:skill',
    SUBMIT_OFFLINE_TEST_SKILL: '/user-entrance-tests-progress/offline/:id',
    CHANGE_ONLINE_TO_OFFLINE_TEST_SKILL: '/user-entrance-tests-progress/online-to-offline/:id',
    FORCE_MARKING_TEST_SKILL_TO_BLANK: '/user-entrance-tests-progress/to-blank/:id/:skillType',

    PUT_AUTO_SAVE_OFFLINE_TEST_SKILL: '/user-entrance-tests-progress/offline/:id/auto-save',

    GET_QUICK_FEED_BACK_OFFLINE_TEST_STATUS:
      '/offline/user-entrance-tests/:id/quick-feedback/status',
    GET_QUICK_FEED_BACK_OFFLINE_TEST_SKILL:
      '/offline/user-entrance-tests/:id/quick-feedback/skills/:skill',
    SUBMIT_QUICK_FEED_BACK_OFFLINE_TEST_SKILL:
      '/offline/user-entrance-tests/:id/quick-feedback/skills',

    GET_LIST_MARKING_USER_ENTRANCE_TEST: '/user-entrance-tests',

    UPDATE_ENTRANCE_TEST_DUEDATE: '/user-entrance-tests/:id/due-date',

    GET_ENTRANCE_TEST_INFO: '/marking-user-entrance-tests/:id/marking/over-view',

    GET_ENTRANCE_TEST_MARKING_HISTORY: '/user-entrance-test-history/:id/marking',
    GET_ENTRANCE_TEST_MARKING_HISTORY_MENU: '/user-entrance-test-history/marking/:id/:skillType',

    SEND_TO_TM: '/user-entrance-tests/:id/finish',

    POST_START_ONLINE_ENTRANCE_TEST_WRITING_TEST:
      '/online-entrance-tests/:userEntranceTestId/writing/:assignmentId/start',
    PUT_SAVE_ONLINE_ENTRANCE_TEST_WRITING_TEST:
      '/online-entrance-tests/:userEntranceTestId/writing/:assignmentId',
    POST_SUBMIT_ONLINE_ENTRANCE_TEST_WRITING_TEST:
      '/online-entrance-tests/:userEntranceTestId/writing/:assignmentId/submit',
    POST_START_ONLINE_ENTRANCE_TEST_SPEAKING_TEST:
      '/online-entrance-tests/:userEntranceTestId/speaking/:assignmentId/start',

    PUT_UPDATE_DO_TEST_SKILL: '/student-do-test/:userEntranceTestId/:skillType/progress',

    PUT_SAVE_ONLINE_ENTRANCE_TEST_SPEAKING_TEST:
      '/online-entrance-tests/:userEntranceTestId/speaking/:assignmentId',
    POST_SUBMIT_ONLINE_ENTRANCE_TEST_SPEAKING_TEST:
      '/online-entrance-tests/:userEntranceTestId/speaking/:assignmentId/submit',

    POST_OFFLINE_START_MARKING: '/marking-user-entrance-tests/:id/marking/start',
    GET_OFFLINE_OVERVIEW: '/offline/user-entrance-tests/:id/overview',
    GET_OFFLINE_MARKING_SKILL: '/offline/user-entrance-tests/:id/marking/skills/:skillType',
    POST_OFFLINE_SUBMIT_MARKING_SKILL: '/offline/user-entrance-tests/:id/marking/skills',
    PUT_OFFLINE_UPDATE_MARKING_SKILL: '/offline/user-entrance-tests/:id/marking/skills',
    GET_OFFLINE_MARKING_SKILL_RESOURCE:
      '/offline/user-entrance-tests/:id/skills/:skillType/resources',
    GET_OFFLINE_MARKING_SKILL_USER_TEST:
      '/offline/user-entrance-tests/:id/skills/:skillType/usser-test',
    GET_ONLINE_MARKING_SKILL_RESOURCE:
      '/online/user-entrance-tests/:id/skills/:skillType/resources',
    PUT_OFFLINE_CHANGE_ENTRANCE_TEST_TO_IN_PROGRESS:
      '/offline/user-entrance-tests/:id/skill/:skillType/in-progress',
    PUT_ONLINE_CHANGE_ENTRANCE_TEST_TO_IN_PROGRESS:
      '/online/user-entrance-tests/:id/skill/:skillType/in-progress',

    UPDATE_ENTRANCE_TEST_EC: '/user-entrance-tests/:id/in-charge-user',
    CONFIRM_CONSULTED: '/user-entrance-tests/:id/consulted',
    REQUEST_REMARK: '/user-entrance-tests/:id/marking/request-remark',
    SUBMIT_REMARK: '/user-entrance-tests/:id/marking/submit-remark',

    VALIDATION_BEFORE_MARK: '/marking-user-entrance-tests/:id/start/validate',
    GET_ONLINE_TEST_OVERVIEW: '/marking-user-entrance-tests/:id/marking/starting-overview',
    GET_ENTRANCE_TEST_SKILL_RESOURCE_DETAIL:
      '/offline/user-entrance-tests/:id/skills/:skillType/resources',
    GET_QUICK_FEED_BACK: '/offline/user-entrance-tests/can-quick-feedback',
    GET_ONLINE_ENTRANCE_TEST_OVERVIEW: '/online-entrance-tests/:id/do-test/overview',
    GET_ONLINE_ENTRANCE_TEST_REMINED: '/user-entrance-tests/:id/remind',

    GET_SKILL_ENTRANCE_TEST_STATUS_OFFLINE: '/offline/user-entrance-tests/:id/detail/status',
    GET_SKILL_ENTRANCE_TEST_STATUS_ONLINE: '/online/user-entrance-tests/:id/detail/status',
    GET_SKILL_ENTRANCE_TEST_DETAIL: '/offline/user-entrance-tests/:id/detail/skills/:skillType',

    GET_ONLINE_TEST_OVERALL_RESULT: '/online/user-entrance-tests/:id/marking/overall',
    GET_ENTRANCE_TEST_OVERALL: '/user-entrance-tests/:id/overall',

    POST_SUBMIT_TEST_MARKING: '/marking-user-entrance-tests/:id/finish',
    GET_VALIDATION_HUBSPOT_EMAIL: '/hubspot/contact/existed',
    GET_SKILL_ENTRANCE_TEST_DETAIL_PROGRESS: '/user-entrance-tests/:id/skills/:skillType',
    GET_ENTRANCE_TEST_SKILL_RESOURCE_DETAIL_ONLINE:
      '/online/user-entrance-tests/:id/detail/skills/:skillType/:resourceId',
    GET_SKILL_REPORT_ONLINE: '/online/user-entrance-tests/:id/detail/skills/:skillType',
    GET_LIST_FINAL_TEST: '',
    POST_SYNC_CONTACT_TO_ALL_TESTS: '/contacts/:id/sync-to-all-tests',
    GET_HUBPOT_CONTACT_DETAIL: '/hubspot/contact/:id',
    PUT_CHANGE_SKILL_BLANK: '/user-entrance-tests-progress/offline/:id/:skillType/status',
    GET_CONTACT_DETAIL: '/contacts/:id',

    PUT_CHANGE_SKILL_IN_PROGRESS: '/user-entrance-tests-progress/offline/:id/:skillType/status',

    CONFIRM_MARKING_FINISH: '/marking-user-entrance-tests/:id/done',
    PUT_DROP_TO_SUBMITTED: '/user-entrance-tests/:id/marking/drop-to-submitted',
    PUT_USER_ENTRANCE_TEST_OVERALL: '/user-entrance-tests/:id/overall',
    POST_USER_ENTRANCE_TEST_OVERALL: '/user-entrance-tests/:id/overall',

    PUT_USER_ENTRANCE_TEST_OVERALL_IN_PROGRESS: '/user-entrance-tests/:id/overall/in-progress',
    DROP_TO_SUBMITTED: '/user-entrance-tests/:id/marking/drop-to-submitted',
    GET_ENTRANCE_TEST_HISTORY: '/user-entrance-test-history/:id',
    PUT_ROLL_BACK_HISTORY: '/user-entrance-test-history/:id/rollback',
    PUT_AUTO_SAVE_MARKING_TEST_SKILL: '/marking-user-entrance-tests/:id/marking/:skillType',
    PUT_MARKING_TEST_SKILL_STATUS: '/marking-user-entrance-tests/:id/marking/:skillType/status',
    // EC SUMMARIZE
    GET_EC_SUMMARIZE: '/ec-summarize',
    GET_EC_SUMMARIZE_DETAIL: '/ec-summarize/:id',
    // TA SUMMARIZE
    GET_TA_SUMMARIZE: '/ta-summarize',
    GET_TA_SUMMARIZE_DETAIL: '/ta-summarize/:id',

    EC_SUBMIT_USER_ENTRANCE_TEST_EVIDENCE:
      '/user-entrance-tests/:userEntranceTestId/submit-evidence',
    SUBMIT_EC_PLAN_SENT_USER_ENTRANCE_TEST:
      '/user-entrance-tests/:userEntranceTestId/evidence-planned/submit',
    EC_PLAN_SENT_USER_ENTRANCE_TEST_LISTING:
      '/user-entrance-tests/:userEntranceTestId/evidence-planned',

    // ENTRANCE OVERALL
    GET_ENTRANCE_OVERALL: '/overall',
    GET_ENTRANCE_TIME_FINISH_TEST_OVERALL: '/overall/time-finish-test',
    GET_ENTRANCE_TA_EC_OVERALL: '/overall/ta-ec',
    DELETE_USER_ENTRANCE_TEST_OLD: '/old-user-entrance-test/:id',
    GET_ALL_OLD_TEST: '/old-user-entrance-test',
    IMPORT_OLD_TEST: '/old-user-entrance-test/import',
    UPDATE_OLD_TEST: '/old-user-entrance-test',

    GET_MY_ENTRANCE_OLD_TEST: '/old-user-entrance-test/me',
    GET_MY_ENTRANCE_NEW_TEST: '/user-entrance-tests/me',
  },
  assignment: {
    GET_ASSIGNMENT_EMAIL_DATA_FOR_STUDENT_AUTO_OPEN:
      '/email-data/:subClassKey/:studentRegistrationKey/auto-open',
    GET_ASSIGNMENT_EMAIL_DATA: '/email-data/:courseAssignmentId/:studentRegistrationKey',
    GET_ASSIGNMENT_EMAIL_DATA_FOR_TEACHER: '/email-data/for-teacher/:subClassKey',
    GET_USER_SUMMARIZE_BY_COURSE_ID_AND_USER_ID:
      '/user-activity-summarize/by-course/:courseId/:userId',
    GET_LIST: '/assignments',
    CREATE: '/assignments',
    GET_ASSIGNMENT_DETAIL: '/assignments/:assignmentId',
    ACTION_WITH_ID: '/assignments/:id',
    DUPLICATE_ASSIGNMENT: '/assignments/:id/duplicate',
    PUBLISH: '/assignments/:id/publish',
    POST_TRACKING_EDIT: '/assignments/:id/tracking-edit',

    // Do assignment
    POST_START_ASSIGNMENT: '/assignments/:assignmentId/start',
    GET_VIEW_ASSIGNMENT_PROGRESS: '/learning/assignments/corrections/:progressId',
    GET_ASSIGNMENT_PROGRESS_DETAIL: '/assignments/progresses/:progressId',
    PUT_SAVE_ASSIGNMENT: '/assignments/progresses/:progressId',
    PUT_COMPLETE_ASSIGNMENT: '/assignments/progresses/:progressId/complete',
    GET_COURSE_ASSIGNMENT_LATEST_PROGRESS_STUDENTS:
      '/assignments/progresses/course-assignment/:courseAssignmentId',
    GET_PROGRESS_SUBMIT_HISTORY: '/assignments/progresses/submit-history/:progressId',

    POST_RESET_EXTRA_ASSIGNMENT_PROGRESS: '/assignments/progresses/:progressId/reset',

    // TA
    TA_GET_ALL_ASSIGNMENT: '/marking/ta/corrections/paged',
    TA_START_MARKING: '/marking/ta/corrections/:correctionId/start',
    TA_GET_MY_ASSIGNMENT: '/marking/ta/corrections/me',
    TA_SEND_MARKING_TO_STUDENT: '/marking/ta/corrections/:correctionId/send-to-student',

    // TEACHER
    TEACHER_GET_ALL_ASSIGNMENT: '/marking/teacher/progresses/pending',
    TEACHER_START_MARKING: '/marking/teacher/corrections/assign-to-me',
    TEACHER_SEND_MARKING_TO_STUDENT: '/marking/teacher/corrections/:correctionId/send-to-student',

    TEACHER_GET_ALL_CORRECTIONS: '/marking/teacher/corrections',
    TEACHER_GET_MARKED_CORRECTIONS: '/marking/teacher/corrections/me',

    // Assignment Correction
    GET_ASSIGNMENT_CORRECTION: '/assignments/corrections/:correctionId',
    GET_TA_ASSIGNMENT_CORRECTION: '/marking/ta/corrections/:correctionId',
    GET_TEACHER_ASSIGNMENT_CORRECTION: '/marking/teacher/corrections/:correctionId',
    GET_TEACHER_ASSIGNMENT_CORRECTION_VIEW: '/marking/teacher/corrections/:correctionId/view',
    PUT_UPDATE_ASSIGNMENT_CORRECTION: '/assignments/corrections/:correctionId',
    PUT_FINISH_ASSIGNMENT_CORRECTION: '/assignments/corrections/:correctionId/publish',
    PUT_APPROVE_ASSIGNMENT_CORRECTION: '/assignments/corrections/:correctionId/approved',
    PUT_TEACHER_RATING_ASSIGNMENT_CORRECTION:
      '/assignments/corrections/:correctionId/teacher-rating',
    PUT_TEACHER_FINISH_MARKING: '/marking/teacher/corrections/:correctionId/finish-edit-marking',
    POST_ASSIGNMENT_CORRECTION_AI_SUGGESTION_ADD:
      '/assignments/corrections/:correctionId/ai-suggestions/add',
    GET_ALL_ASSIGNMENT_CORRECTION_AI_SUGGESTIONS:
      '/assignments/corrections/:correctionId/ai-suggestions',

    PUT_TEACHER_START_EDIT_APPROVING:
      '/marking/teacher/corrections/:correctionId/start-edit-approving',
    PUT_TEACHER_FINISH_EDIT_APPROVING:
      '/marking/teacher/corrections/:correctionId/finish-edit-approving',

    PUT_TEACHER_START_MODIFYING_APPROVED:
      '/marking/teacher/corrections/:correctionId/start-modifying-approved',
    PUT_TEACHER_FINISH_MODIFYING_APPROVED:
      '/marking/teacher/corrections/:correctionId/finish-modifying-approved',

    PUT_TA_START_MODIFYING_APPROVED:
      '/marking/ta/corrections/:correctionId/start-modifying-approved',
    PUT_TA_FINISH_MODIFYING_APPROVED:
      '/marking/ta/corrections/:correctionId/finish-modifying-approved',

    PUT_TA_FINISH_MARKING: '/marking/ta/corrections/:correctionId/finish-edit-marking',
    PUT_STUDENT_VIEW_CORRECTION: '/assignments/corrections/:courseAssignmentId/student-view',
    PUT_STUDENT_RATING_CORRECTION: '/assignments/corrections/:courseAssignmentId/student-rating',

    GET_OVERVIEW_MARKED_LATE: '/assignments/corrections/overview/marked-late',
    GET_OVERVIEW_PERFORMANCE_LAST_7_DAYS:
      '/assignments/corrections/overview/performance-last-7-days',
    GET_OVERVIEW_CURRENT: '/assignments/corrections/overview/current',
    GET_TA_MY_CORRECTION_HISTORY: '/marking/ta/corrections/me/history',

    GET_ASSIGNMENT_PROGRESS_HISTORY: '/assignments/corrections/histories',
    GET_STUDENT_ASSIGNMENT_PROGRESS_HISTORY: '/assignments/corrections/events/student',
    GET_ASSIGNMENT_LEADERBOARD: '/learning/assignments/:assignmentId/ranking',
    GET_ALL_CORRECTION_ASSIGNMENT: '/assignments/corrections/all',
    GET_MY_CORRECTION_ASSIGNMENT_STATISTIC: '/assignments/corrections/me/statistic',
    CHANGE_MARKER: '/marking/teacher/corrections/:correctionId/change-marker',
    TRASNSFER_OWNERSHIP_TO_ME:
      '/marking/teacher/corrections/:correctionId/transfer-marking-ownership-to-me',
    REQUEST_RESUBMITTED_CORRECTION: '/assignments/corrections/:correctionId/request-resubmitted',
    TA_GET_MY_REMOVED_ASSIGNMENT: '/marking/ta/corrections/me/removed',
    TA_GET_WAITING_FOR_RESUBMITTED_ASSIGNMENT: '/marking/ta/corrections/waiting-for-resubmitted',

    GET_TA_ASSIGNMENT_ACTIVITY_SUMMARIZE: '/user-activity-summarize/ta',
    GET_TEACHER_ASSIGNMENT_ACTIVITY_SUMMARIZE: '/user-activity-summarize/teacher',
    GET_TEACHER_ASSIGNMENT_MARKING_STATISTIC_PAGED:
      '/user-activity-summarize/by-teacher/:teacherId/statistic',
    GET_LIST_TA_INACTIVITY: '/user-activity-summarize/inactivity-list/ta',
    GET_LIST_TEACHER_INACTIVITY: '/user-activity-summarize/inactivity-list/teacher',

    // TA mark assignment history
    GET_ASSIGNMENT_CORRECTION_HISTORIES: '/assignments/corrections/:correctionId/histories',
    GET_ASSIGNMENT_CORRECTION_HISTORIES_BY_VERSION:
      '/assignments/corrections/:correctionId/histories/:versionId',
    GET_ASSIGNMENT_CORRECTION_ORIGINAL: '/assignments/corrections/:correctionId/original',
    PUT_APPLY_ASSIGNMENT_CORRECTION_VERSION:
      '/assignments/corrections/:correctionId/histories/:versionId',

    GET_PUBLISHED_ASSIGNMENTS: '/assignments/list/published',
    GET_ALL_TEACHER: '/users?role=TEACHER',
    GET_FIREBASE_REALTIME_DB_TOKEN: '/marking/rtdb/token',

    // student
    GET_VIEW_STUDENT_COURSE_ASSIGNMENT_PROGRESS: '/student/assignments/corrections/detail',
    GET_ASSIGNMENT_FOR_ENTRANCE: '/assignments/list/published',
    GET_ASSIGNMENT_PREVIEW: '/assignments/:id',

    POST_TRACKING_DOWNLOAD: '/tracking-download',
  },
  aiMockTest: {
    GET_PUBLISHED_AI_MOCK_TESTS: '/ai-mock-tests/published/paged',
  },
  videoService: {
    GET_VIDEO_LISTING: '/camera/list-video-record',
    GET_VIDEO_DETAIL: '/camera/get-video-record',
    GET_LIST_CAMERA_STATUSES_BY_SCHEDULE_DATA_KEYS: '/camera/get-video-record/status',
    RETRY_SYNC_VIDEO_RECORD: '/camera/retry-sync-video-record',
    UPDATE_STATUS: '/camera/update-status',
  },
  cognitiveService: {
    SPEECH_TO_TEXT_TOKEN: '/stt/issue-token',
  },
  corpWeb: {
    GET_ALL_COURSES: '/courses',
    GET_ALL_HALL_OF_FAME: '/hallOfFameList',
    GET_ALL_TEACHERS_INTRODUCTION: '/teachers',
    GET_ALL_STUDENT_REVIEW: '/studentReviews',
  },
  courseManagement: {
    VALIDATION_EXISTING_STUDENTS_BY_CONTACT_ID:
      '/student-management/students/:studentId/existing-contact-id/:contactId',
    CREATE_COURSE: '/course-management/courses',
    CENTER_DIRECTOR_LIST_COURSE: '/course-management/courses',
    DELETE_COURSE: '/course-management/courses/:id',
    GET_COURSE_GENERAL_INFO: '/course-management/courses/:courseId/general-info',
    COURSE_ACTION_WITH_ID: '/course-management/courses/:id/general-info',
    VALIDATION_COURSE_NAME: '/course-management/courses/check',
    UPDATE_COURSE_STATUS: '/course-management/courses/:id/status',
    COURSE_STUDENT_ACTION: '/course-management/courses/:id/students',
    COURSE_TEACHER_ACTION: '/course-management/courses/:id/teachers',
    COURSE_TA_ACTION: '/course-management/courses/:id/tas',
    STUDENT_ACTION: '/student-management/students/:id',
    GET_STUDENT_COURSES: '/student-management/students/:id/courses',
    UPDATE_COURSE_STUDENT: '/course-management/courses/:id/students',
    CREATE_STUDENT: '/student-management/students',
    IMPORT_STUDENT: '/student-management/students/import',
    SEARCH_STUDENT: '/student-management/students',
    GENERATE_SESSION: '/course-management/courses/:id/sessions/generate',
    CREATE_SESSION: '/course-management/courses/:id/sessions',
    GET_COURSE_SESSION: '/course-management/courses/:id/sessions',
    CM_CREATE_ANNOUNCEMENT: '/cm/courses/in-progress/announcements',
    GET_CLASS_BY_DATE: '/session-management/sessions/me',
    GET_CLASS_OTHER: '/session-management/sessions/other-courses/me',
    GET_CLASS_SYSLABUS: '/course-management/courses/:id/syllabus',
    UPDATE_COURSE_ATTENDANCE: '/session-management/sessions/:id/attendance',
    TEACHER_GET_COURSE_ASSIGNMENT: '/teacher/courses/:id/assignments',
    TEACHER_CHECK_EMPTY_COURSE_EXTRA_ASSIGNMENT: '/teacher/courses/:id/assignments/extra-check',
    TEACHER_GET_COURSE_ASSIGNMENT_STUDENT:
      '/teacher/courses/assignments/:courseAssignmentId/students',
    TEACHER_GET_COURSE_ASSIGNMENT_STUDENT_LIST:
      '/teacher/courses/assignments/:courseAssignmentId/students/paged',
    TEACHER_GET_STUDENT_ASSIGNMENT_DETAIL:
      'teacher/courses/students/:studentRegistrationKey/assignments/:courseAssignmentId',
    TEACHER_OPEN_ASSIGMENT: '/teacher/courses/assignments/:assignmentId/open',
    TEACHER_EXTEND_ASSIGMENT: '/teacher/courses/assignments/:assignmentId/extend',
    GET_SUBSTITUTE_CLASS_SESSION: '/teacher/courses/:id/sessions/substitute',
    GET_COURSE_EXERCISES_STATISTIC:
      '/session-management/courses/:courseId/course-exercises/statistic',
    GET_COURSE_EXERCISE_STUDENTS: '/session-management/course-exercises/:courseExerciseId/students',
    GET_COURSE_EXERCISE_STUDENTS_PAGED:
      '/session-management/course-exercises/:courseExerciseId/students/paged',
    GET_TEACHER_COURSE_WEEKS: '/teacher/courses/:courseId/weeks',
    GET_TEACHER_COURSE_STATISTIC: '/teacher/courses/statistic',
    GET_CM_COURSE_NOT_SCHEDULED: '/center-manager/courses/not-scheduled',

    GET_TEACHER_LIST_UPCOMING_COURSES: '/teacher/courses/upcoming',
    GET_TEACHER_LIST_COMPLETED_COURSES: '/teacher/courses/completed',
    GET_TEACHER_LIST_SUBSTITUTE_COURSES: '/teacher/courses/sessions/substitute',
    DELETE_COURSE_SESSION: '/course-management/courses/sessions/:id/',
    GET_COURSE_SESSION_DETAIL: '/course-management/courses/sessions/:id/',
    // reading
    GET_COURSE_READING_LIST: '/teacher/courses/:courseId/readings',
    GET_COURSE_READING_DETAIL: '/teacher/courses/readings/:courseReadingId',
    GET_USER_COURSE_READING_DETAIL: '/teacher/courses/readings/students/:userCourseReadingId',
    GET_STUDENT_COURSE_READING_LIST: '/teacher/courses/readings/students/:courseReadingId/list',

    // sample
    GET_COURSE_SAMPLE_LIST: '/teacher/courses/:courseId/samples',
    GET_COURSE_SAMPLE_DETAIL: '/teacher/courses/samples/:courseSampleId',
    GET_USER_COURSE_SAMPLE_LIST: '/teacher/courses/samples/:courseSampleId/student/list',
    GET_USER_COURSE_SAMPLE_DETAIL: '/teacher/courses/samples/students/:userCourseSampleId',
    // online-test
    GET_COURSE_ONLINE_TEST_LIST: '/teacher/courses/:courseId/online-tests',
    UPDATE_EXPLANATION_PERMISSIONS: '/teacher/courses/online-tests/explanations',
    GET_ONLINE_TEST_DETAIL_STATS: '/teacher/courses/online-tests/:courseOnlineTestId',
    GET_ONLINE_TEST_STUDENT: '/teacher/courses/online-tests/:courseOnlineTestId/student/paged',
    GET_USER_COURSE_ONLINE_TEST_DETAIL: '/teacher/courses/online-tests/students/:onlineTestId',
    GET_USER_TEST_LIST: '/v2/user-tests',

    // vocab
    GET_TEACHER_COURSE_VOCAB_SET_STATISTIC:
      '/teacher/courses/:courseId/course-vocab-sets/statistic',
    GET_TEACHER_COURSE_VOCAB_SET_STUDENTS:
      '/teacher/courses/course-vocab-sets/:courseVocabSetId/students',
    GET_TEACHER_COURSE_VOCAB_SET_STUDENTS_PAGED:
      '/teacher/courses/course-vocab-sets/:courseVocabSetId/students/paged',
    GET_TEACHER_COURSE_VOCAB_STATISTIC:
      '/teacher/courses/course-vocab-sets/:courseVocabSetId/vocabs/statistic',
    GET_TEACHER_COURSE_VOCAB_REVIEW_STATISTIC: '/teacher/courses/:courseId/vocab-review/statistic',

    // dictation
    GET_TEACHER_COURSE_DICTATION_STATISTIC:
      '/session-management/courses/:courseId/course-dictations/statistic',
    GET_TEACHER_COURSE_DICTATION_STUDENT_STATISTIC:
      '/session-management/course-dictations/:courseDictationId/students',
    GET_TEACHER_COURSE_DICTATION_STUDENT_STATISTIC_PAGED:
      '/session-management/course-dictations/:courseDictationId/students/paged',

    // STUDENT
    POST_START_ASSIGNMENT: '/student/courses/assignments/:subClassKey/:courseAssignmentId/start',
    GET_COURSE_ASSIGNMENT_DETAIL: '/student/courses/assignments/:id',
    GET_USER_COURSE_ASSIGNMENT_DETAIL:
      '/student/courses/:subClassKey/assignments/:courseAssignmentId',

    // extra assignment
    POST_ASSIGN_EXTRA_ASSIGNMENT_FROM_BANK: '/teacher/courses/assignments/extra/assign-from-bank',
    GET_CURRENT_EXTRA_ASSIGNMENT_IN_BANK:
      '/teacher/courses/assignments/extra/get-current-in-bank/:subClassKey',
    POST_FAST_CREATE_EXTRA_ASSIGNMENT:
      '/teacher/courses/assignments/extra/fast-create/:subClassKey',
    PUT_FAST_UPDATE_EXTRA_ASSIGNMENT:
      'teacher/courses/assignments/extra/fast-update/:courseAssignmentId',
    GET_EXTRA_ASSIGNMENT_WITH_ASSIGNMENT_INFO:
      '/teacher/courses/assignments/extra/info/:courseAssignmentId',
    DELETE_EXTRA_ASSIGNMENT_WITH_ASSIGNMENT_INFO:
      '/teacher/courses/assignments/extra/delete/:courseAssignmentId',
  },
  offlineCourseManagement: {
    GET_VOCAB_SETS_IN_WEEK: 'learning-management/courses/:courseId/vocab-sets/in-week',
  },
  announcementManagement: {
    CREATE_ANNOUNCEMENT: '/cm/courses/:courseId/announcements',
    UPDATE_ANNOUNCEMENT: '/cm/courses/announcements/:announcementId',
    GET_COURSE_ANNOUNCEMENT: '/cm/courses/:courseId/announcements',
    GET_ANNOUNCEMENT_DETAIL: '/cm/courses/:courseId/announcements/:announcementId',
    CREATE_SMS: '/cm/courses/:courseId/sms',
    GET_SMS: '/cm/courses/:courseId/sms',
    GET_SMS_FAILED: '/cm/courses/sms/:smsId/students/failed',
    RESEND_SMS_FAILED: '/cm/courses/sms/:smsId/students/resend',
    COUNT_IN_PROGRESS_COURSE: '/cm/courses/in-progress/count',
    GET_SMS_DETAIL: '/cm/courses/sms/:smsId',
    GET_STUDENT_SMS_DETAIL: '/cm/courses/sms/students',
  },
  syllabusManagement: {
    GET_PUBLISHED_SYLLABUSES: '/api/syllabuses/list/published',
    GET_SUPER_SIMPLIFIED_SYLLABUS_DETAIL: '/api/syllabuses/:id/super-simplified',
  },
  syllabus: {
    GET_SYLLABUSES: '/api/syllabuses',
    GET_SYLLABUS_DETAIL: '/api/syllabuses/:id',
    CREATE_SYLLABUS: '/api/syllabuses',
    UPDATE_SYLLABUS: '/api/syllabuses/:id',
    PUBLISH_SYLLABUS: '/api/syllabuses/:id/publish',
    DELETE_SYLLABUS: '/api/syllabuses/:id',
    CLONE_SYLLABUS: 'api/syllabuses/:id/duplicate',
    GET_PUBLISHED_SYLLABUSES: '/api/syllabuses/list/published',
    CENTER_DIRECTOR_GET_SYLLABUSES: '/api/cd/syllabuses',
    CENTER_MANAGER_GET_SYLLABUSES: '/api/cm/syllabuses',
    CD_GET_SYLLABUS_DETAIL: '/api/cd/syllabuses/:id',
    CM_GET_SYLLABUS_DETAIL: '/api/cm/syllabuses/:id',

    GET_SYLLABUS_TREE: '/api/syllabuses/:id/tree',
    GET_ROOT_DETAIL: '/api/syllabuses/:id/root',
    GET_WEEK_DETAIL: '/api/syllabuses/:id/weeks/:weekKey',
    GET_SUB_WEEK_DETAIL: '/api/syllabuses/:id/weeks/:weekKey/sub-weeks/:subWeekKey',
    GET_ROOT_INFO: '/api/syllabuses/:id/root/info',
    GET_WEEK_INFO: '/api/syllabuses/:id/weeks/:weekKey/info',
    GET_SUB_WEEK_INFO: '/api/syllabuses/:id/weeks/:weekKey/sub-weeks/:subWeekKey/info',
    GET_ROOT_ACTIVITIES: '/api/syllabuses/:id/root/activities',
    GET_WEEK_ACTIVITIES: '/api/syllabuses/:id/weeks/:weekKey/activities',
    GET_SUB_WEEK_ACTIVITIES: '/api/syllabuses/:id/sub-weeks/:subWeekKey/activities',
    GET_DUPLICATED_RESOURCES: '/api/syllabuses/:id/resources/duplicated',
    POST_CREATE_WEEKS: '/api/syllabuses/:id/weeks',
    PUT_RENAME_WEEKS: '/api/syllabuses/:id/weeks/:weekKey/rename',
    PUT_MOVE_WEEKS_TO_WEEK: '/api/syllabuses/:id/weeks/move-to-week',
    PUT_MOVE_SUB_WEEKS_TO_ROOT: 'api/syllabuses/:id/weeks/:weekKey/sub-weeks/move-to-root',
    PUT_MOVE_RESOURCES_SUB_WEEKS_TO_ROOT:
      'api/syllabuses/:id/weeks/:weekKey/sub-weeks/:subWeekKey/resources/move-to-root',
    PUT_MOVE_RESOURCES_WEEKS_TO_ROOT: 'api/syllabuses/:id/weeks/:weekKey/resources/move-to-root',
    DELETE_REMOVE_WEEKS: '/api/syllabuses/:id/weeks/remove',
    POST_CREATE_SUB_WEEKS: '/api/syllabuses/:id/weeks/:weekKey/sub-weeks',
    PUT_RENAME_SUB_WEEK: '/api/syllabuses/:id/weeks/:weekKey/sub-weeks/:subWeekKey/rename',
    PUT_MOVE_SUB_WEEKS_TO_WEEK: '/api/syllabuses/:id/weeks/:weekKey/sub-weeks/move-to-week',
    PUT_MOVE_SUB_WEEKS_TO_SUB_WEEK: '/api/syllabuses/:id/weeks/:weekKey/sub-weeks/move-to-sub-week',
    DELETE_REMOVE_SUB_WEEKS: '/api/syllabuses/:id/weeks/:weekKey/sub-weeks/remove',
    PUT_UPDATE_ROOT_RESOURCES: '/api/syllabuses/:id/root/resources',
    PUT_UPDATE_WEEK_RESOURCES: '/api/syllabuses/:id/weeks/:weekKey/resources',
    PUT_UPDATE_SUB_WEEK_RESOURCES:
      '/api/syllabuses/:id/weeks/:weekKey/sub-weeks/:subWeekKey/resources',
    PUT_MOVE_RESOURCES: '/api/syllabuses/:id/resources/move',
    DELETE_REMOVE_RESOURCES: '/api/syllabuses/:id/resources/remove',
    PUT_UPDATE_FINAL_TESTS: '/api/syllabuses/:id/final-tests',
    PUT_REORDER_WEEK: '/api/syllabuses/:id/weeks/reorder',
    PUT_REORDER_SUB_WEEK: '/api/syllabuses/:id/sub-weeks/reorder',
    PUT_REORDER_RESOURCE: '/api/syllabuses/:id/resources/reorder',
  },
  courseService: {
    GET_COURSE_ASSIGNMENT_MANAGE_CURRENT_SITUATION_LIST:
      '/teacher/courses/manage/course/:courseId/assignments',
    RESET_COURSE_ASSIGNMENT: '/learning-management/assignments/:courseAssignmentId/reset',
    GET_TEACHER_COURSES_ASSIGNMENT_MANAGE_COURSE: '/teacher/courses/manage/assignment',
    GET_TEACHER_COURSES_ASSIGNMENT_MANAGE_COURSE_DETAIL:
      '/teacher/courses/manage/assignment/:courseId',
    GET_STUDENT_FINAL_TEST_HISTORIES: '/student/courses/student/:studentId/histories',
    GET_TEACHER_FINAL_TEST_ACTIVITY: '/final-test/teacher-activity/all',

    POST_FORCE_INPUT_OFFLINE:
      '/teacher/courses/:courseId/final-tests/student/tar:studentId/force-input-offline',
    PUT_OPEN_FINAL_TEST_IN_A_COURSE: '/teacher/courses/:courseId/final-tests/open',
    PUT_EXTEND_FINAL_TEST_IN_A_COURSE: '/teacher/courses/:courseId/final-tests/extend',
    PUT_CHANGE_MODE_FINAL_TEST_IN_A_COURSE: '/teacher/courses/:courseId/final-tests/change-mode',
    GET_COURSE_STUDENT_FINAL_TEST_STATISTIC: '/teacher/courses/:courseId/final-tests/statistic',
    GET_COURSE_STUDENT_FINAL_TEST_LIST: '/teacher/courses/:courseId/final-tests/student-progress',

    GET_STUDENT_COURSE_GENERAL_INFO: '/learning-management/courses/:courseId/general-info',
    GET_STUDENT_EXERCISES: '/learning-management/courses/:courseId/exercises',
    GET_STUDENT_EXERCISES_RANK: '/learning-management/courses/:courseId/course-exercises/rank',
    GET_STUDENT_EXERCISES_SKILL_DISPLAY:
      '/learning-management/courses/:courseId/course-exercises/skills',
    GET_STUDENT_EXERCISES_STATISTIC:
      '/learning-management/courses/:courseId/course-exercises/statistic',
    GET_STUDENT_EXERCISE_STATISTIC: '/student/courses/:courseId/exercises/statistic',

    GET_STUDENT_AI_MOCK_TESTS: '/learning-management/courses/:courseId/ai-mock-tests',
    GET_STUDENT_AI_MOCK_TESTS_TYPES: '/learning-management/courses/:courseId/ai-mock-tests/types',
    GET_STUDENT_AI_MOCK_TESTS_STATISTIC:
      '/learning-management/courses/:courseId/ai-mock-tests/statistic',

    GET_MY_CLASS_BY_DATE: '/learning-management/courses/me',
    GET_STUDENT_MY_CLASS: '/learning-management/courses/me',
    GET_MY_TEST_HISTORY: 'student/completed-tests/me',
    GET_STUDENT_COURSE_RESOURCES_STATISTIC:
      '/resources/courses/:subClassKey/student/:studentRegistrationKey/resources-statistic',

    // TEACHER
    TEACHER_GET_ANNOUNCEMENT: '/teacher/courses/:courseId/announcements',

    // STUDENT
    GET_COURSE_STUDENT_DETAIL: '/student-management/students/:studentId',
    GET_COURSE_STUDENT_INFO: '/student-management/students/info',
    STUDENT_GET_ASSIGNMENT_TODO: '/student/courses/:id/assignments/todo',
    STUDENT_GET_ASSIGNMENTS: '/student/courses/:subClassKey/assignments',
    STUDENT_GET_ASSIGNMENTS_OPTIONS: '/student/courses/:subClassKey/assignments/options',
    GET_STUDENT_ASSIGNMENT_STATISTIC:
      '/learning-management/courses/:subClassKey/course-assignments/statistic',
    GET_COURSE_CHECKS: '/learning-management/courses/check',
    GET_STUDENT_IN_COURSE_CHECKS: '/learning-management/courses/:courseId/check',
    STUDENT_GET_ANNOUNCEMENT: '/student/courses/:courseId/announcements',
    STUDENT_GET_ANNOUNCEMENT_DETAIL: '/student/courses/announcements/:announcementId',

    GET_STUDENT_COURSE_OPENING_CLOSED_ASSIGNMENTS: '/student/courses/:id/assignments',

    GET_STUDENT_COURSE_OVERVIEW_SYLLABUS: '/student/courses/:id/overview/syllabus',
    GET_STUDENT_COURSE_RESOURCES_REMINDER: '/student/courses/:courseId/resources/reminder',

    GET_STUDENT_ATTENDANCE_STATISTIC: '/student/courses/:courseId/attendances/statistic',
    GET_STUDENT_ATTENDANCE: '/student/courses/:courseId/attendances',

    GET_COURSE_ASSIGNMENT_DETAIL: '/courses/assignments/:courseAssignmentId',
    GET_COURSES_BY_TEACHER: '/course-management/courses/by-teacher',
    GET_ALL_COURSES: '/course-management/courses/list',

    GET_STUDENT_LISTING: '/student-management/students/list',

    GET_USER_COURSE_READING: '/student/courses/:courseId/readings',
    GET_USER_COURSE_READING_SKILLS: '/student/courses/:courseId/readings/skills',
    GET_READING_STATS: '/student/courses/:courseId/readings/stats',
    GET_RELATED_USER_COURSE_READING:
      '/student/courses/readings/:userCourseReadingId/related-readings',
    START_READING_SESSION: '/student/courses/readings/:userCourseReadingId/sessions',
    MARK_AS_READ_READING: '/student/courses/readings/:userCourseReadingId/finish',
    UPDATE_READING_SESSION: '/student/courses/readings/sessions/:readingSessionId',

    UPDATE_USER_READING_PROGRESS: '/student/courses/readings/:userCourseReadingId/update/progress',
    GET_USER_READING_PROGRESS: '/student/courses/readings/:userCourseReadingId/detail',

    GET_USER_COURSE_READING_INFO: '/student/courses/readings/:userCourseReadingId/info',
    GET_STUDENT_BLOGS_STATISTIC: '/learning-management/courses/:courseId/course-readings/statistic',

    GET_USER_COURSE_SAMPLE: '/student/courses/:courseId/samples',
    GET_USER_COURSE_SAMPLE_OPTIONS: '/student/courses/:courseId/samples/options',
    GET_RELATED_USER_COURSE_SAMPLE: '/student/courses/samples/:userCourseSampleId/related-samples',
    START_SAMPLE_SESSION: '/student/courses/samples/:userCourseSampleId/sessions',
    MARK_AS_READ_SAMPLE: '/student/courses/samples/:userCourseSampleId/finish',
    UPDATE_SAMPLE_SESSION: '/student/courses/samples/sessions/:sampleSessionId',
    GET_USER_COURSE_SAMPLE_INFO: '/student/courses/samples/:userCourseSampleId/info',
    GET_STUDENT_SAMPLE_STATISTIC: '/learning-management/courses/:courseId/course-samples/statistic',

    UPDATE_COURSE_SAMPLE_PROGRESS_BY_ID:
      '/student/courses/samples/:userCourseSampleId/update/progress',
    GET_COURSE_SAMPLE_PROGRESS_BY_ID: '/student/courses/samples/:userCourseSampleId/detail',

    GET_USER_COURSE_ONLINE_TEST: '/student/courses/:courseId/online-tests',
    GET_USER_COURSE_ONLINE_TEST_OPTIONS: '/student/courses/:courseId/online-tests/options',
    GET_STUDENT_ONLINE_TEST_STATISTIC:
      '/learning-management/courses/:courseId/course-online-tests/statistic',

    GET_RESOURSES_STATISTIC_INCLUDE_ROLLING:
      '/resources/courses/student/:registrationKey/resources-statistic-include-rolling',
    // Vocab
    POST_STUDENT_VOCAB_SET_START:
      '/learning-management/courses/:courseId/course-vocab-sets/:courseVocabSetId/start',
    GET_COMPLETED_STUDENTS_VOCAB_SET:
      '/learning-management/courses/:courseId/course-vocab-sets/:courseVocabSetId/completed/students',
    GET_STUDENT_VOCAB_SETS: '/learning-management/courses/:courseId/vocab-sets',
    GET_STUDENT_VOCAB_SETS_SKILLS: '/learning-management/courses/:courseId/vocab-sets/skills',

    START_ONLINE_TEST_SESSION: '/student/courses/online-tests/:userCourseOnlineTestId',
    GET_ONLINE_TEST_DETAIL: '/student/courses/online-tests/:userCourseOnlineTestId',
    GET_ONLINE_TEST_USER_ANSWER_KEY:
      '/student/courses/online-tests/:userCourseOnlineTestId/user-tests/:userTestId',
    GET_STUDENT_VOCAB_SETS_RANK: '/learning-management/courses/:courseId/course-vocab-sets/rank',
    GET_STUDENT_VOCAB_STATISTIC:
      '/learning-management/courses/:courseId/course-vocab-sets/statistic',
    RESET_COURSE_VOCAB_SET: '/learning-management/vocab-sets/:courseVocabSetId/reset',

    // Dictation
    GET_STUDENT_DICTATION: '/learning-management/courses/:courseId/dictations',
    GET_STUDENT_DICTATION_OPTIONS: '/learning-management/courses/:courseId/dictations/options',
    GET_STUDENT_DICTATION_RANK: '/learning-management/courses/:courseId/course-dictations/rank',
    GET_STUDENT_DICTATION_STATISTIC:
      '/learning-management/courses/:courseId/course-dictations/statistic',
    GET_STUDENT_ASSIGNMENT_NEED_TO_COMPLETE:
      '/courses/assignments/:courseId/student/:studentRegistrationKey/need-to-complete',
    REQUEST_GENERATE_RESOURCE_BY_SUB_CLASS_KEY:
      '/course-appsheet/request-generate-resources/:subClassKey',
    RESET_COURSE_DICTATION: '/learning-management/dictations/:courseDictationId/reset',

    // Roadmap
    GET_STUDENT_ROADMAP_STATISTIC:
      '/learning-management/courses/:courseId/student/:studentRegistrationKey/roadmaps/statistic',
    GET_STUDENT_ROADMAPS: '/learning-management/courses/:courseId/roadmaps',
    GET_STUDENT_ROADMAP_PROGRESS: '/learning-management/roadmaps/progress/:progressId',
    GET_STUDENT_ROADMAP_EXTRAS:
      '/learning-management/courses/:courseId/student/:studentRegistrationKey/extra',
    GET_STUDENT_ROADMAP_PRACTICE_STATISTIC:
      '/learning-management/roadmaps/progress/:userRoadmapProgressId/practice/statistic',
    POST_GENERATE_ROADMAP_RESOURCE: '/learning-management/roadmaps/resource/generate',

    POST_GENERATE_TRIAL_SESSION_ID: '/trial-sessions/generate',

    DOWNLOAD_MULTIPLE_FILES: '/download-s3/multiple-files/zip',
    DOWNLOAD_SINGLE_FILE: '/download-s3/single-file',
    DOWNLOAD_ASSIGNMENT_BY_COTACT_ID: '/download-s3/[courseAssignmentId]/by-contact-id',
    DOWNLOAD_ASSIGNMENT_BY_COTACT_IDS: '/download-s3/[courseAssignmentId]/by-contact-ids',
    DOWNLOAD_ASSIGNMENT_BY_COURSE_ASSIGNMENT_ID:
      '/download-s3/by-couse-assignmentId/[courseAssignmentId]',
  },
  vocabV2: {
    GET_PUBLISHED_VOCABULARY: '/vocab-sets/published/paged',
    POST_KNOWLEDGE_VOCAB_ID:
      'course-vocab-sets/:courseVocabSetId/course-vocabs/:courseVocabId/knowledge',
  },
  vocabService: {
    // Vocab set
    POST_VOCAB_SET_CREATE: '/vocab-sets',
    PUT_VOCAB_SET_UPDATE: '/vocab-sets/:vocabSetId',
    DELETE_VOCAB_SET: '/vocab-sets/:vocabSetId',
    POST_VOCAB_SET_PUBLISH: '/vocab-sets/:vocabSetId/publish',
    GET_VOCAB_SET_DETAIL: '/vocab-sets/:vocabSetId',
    GET_VOCAB_SET_LISTING: '/vocab-sets/paged',
    DUPLICATE_VOCAB_SET_LISTING: '/vocab-sets/:vocabSetId/duplicate',
    GET_VOCAB_SET_GENERAL_STATISTIC:
      '/course-vocab-sets/:courseVocabSetId/vocabs/general/statistic',
    GET_VOCAB_SET_PROGRESS: '/course-vocab-sets/:courseVocabSetId/progress/paged',
    GET_VOCAB_SET_HISTORY: '/course-vocab-sets/:courseVocabSetId/history',

    PUT_VOCAB_SET_UPDATE_MEMORIZE_TIP: '/vocab-sets/:vocabSetId/memorize-tip',

    // Vocab
    POST_VOCAB_LINK_VALIDATE: '/vocabs/validate',
    PUT_VOCAB_LIST_UPDATE: '/vocabs',
    POST_VOCAB_LINK_IMPORT: '/vocabs/import',
    GET_VOCAB_IDS: '/vocab-sets/:vocabSetId/vocab-ids',
    GET_VOCABS: '/vocab-sets/:vocabSetId/vocabs',
    GET_VOCAB_DETAIL: '/vocabs/:vocabId',
    PUT_VOCAB_UPDATE: '/vocabs/:vocabId',
    POST_VOCAB_DONE: '/vocabs/:vocabId/done',

    // Vocab test
    GET_VOCAB_TEST_DETAIL: '/vocabs/:vocabId/vocab-test',
    GET_VOCAB_TEST_IDS: '/vocab-sets/:vocabSetId/vocabs/vocab-test-ids',
    GET_VOCAB_TEST_LIST: '/vocab-sets/:vocabSetId/vocab-tests',
    PUT_VOCAB_TEST_UPDATE: '/vocabs/:vocabId/vocab-test',
    PUT_VOCAB_TEST_LIST_UPDATE: '/vocabs/vocab-tests',
    POST_VOCAB_TEST_DONE: '/vocabs/:vocabId/vocab-test/done',

    // Vocab review
    GET_VOCAB_REVIEW_PROGRESS: '/course-vocab-sets/courses/:courseId/vocab-review/paged',
    GET_VOCAB_FILE: '/vocab-sets/:vocabSetId/export-vocabs',

    // Student vocabs set
    GET_STUDENT_VOCAB_SET: '/course-vocab-sets/:courseVocabSetId',
    GET_STUDENT_VOCAB_SET_STATUS: '/course-vocab-sets/:courseVocabSetId/progress/status',
    GET_STUDENT_VOCAB_SET_STATISTIC: '/course-vocab-sets/:courseVocabSetId/statistic',

    // Student vocab
    GET_STUDENT_VOCABS: '/course-vocab-sets/:courseVocabSetId/vocabs',
    GET_STUDENT_VOCABS_STUDY: '/course-vocab-sets/:courseVocabSetId/vocabs/study',
    GET_STUDENT_VOCAB_BASIC_INFO: '/course-vocab-sets/:courseVocabSetId/basic-info',
    POST_STUDENT_VOCAB_SINGLE_CHECK: '/course-vocab/:courseVocabId/single-check',

    // Student vocab progress
    GET_STUDENT_VOCAB_PROGRESS: '/course-vocab-sets/progress/:progressId',
    POST_STUDENT_VOCAB_PROGRESS_START: '/course-vocab-sets/progress/:progressId/start',
    GET_STUDENT_VOCAB_PROGRESS_RESULT: '/course-vocab-sets/progress/:progressId/result',

    // Student vocab test
    POST_STUDENT_VOCAB_TEST_START: '/course-vocab-sets/:courseVocabSetId/vocab-tests/start',
    GET_STUDENT_VOCAB_TESTS: '/course-vocab-sets/:courseVocabSetId/vocab-tests',
    PUT_STUDENT_VOCAB_TEST_PROGRESS_UPDATE: '/course-vocab-sets/test-progress/:progressId',
    POST_STUDENT_VOCAB_TEST_PROGRESS_SUBMIT: '/course-vocab-sets/test-progress/:progressId/submit',
    POST_STUDENT_VOCAB_TEST_PROGRESS_NON_MEMORIZED_START:
      '/course-vocab-sets/test-progress/:progressId/non-memorized/start',

    // Student vocab flashcard
    POST_STUDENT_VOCAB_FLASHCARDS_START: '/course-vocab-sets/:courseVocabSetId/flash-cards/start',
    GET_STUDENT_VOCAB_FLASHCARDS: '/course-vocab-sets/:courseVocabSetId/flash-cards',
    GET_STUDENT_VOCAB_FLASHCARDS_STATISTIC:
      '/course-vocab-sets/:courseVocabSetId/flash-cards/statistic',
    POST_STUDENT_VOCAB_FLASHCARD_DONE:
      '/course-vocab-sets/:courseVocabSetId/flash-cards/:courseVocabId/done',
    POST_STUDENT_VOCAB_FLASHCARDS_FINISH: '/course-vocab-sets/:courseVocabSetId/flash-cards/finish',

    // Student vocab learn
    POST_STUDENT_VOCAB_LEARN_START: '/course-vocab-sets/:courseVocabSetId/vocab-learn/start',
    POST_STUDENT_VOCAB_LEARN_PROGRESS_CHECK: '/course-vocab-sets/learn-progress/:progressId/check',
    POST_STUDENT_VOCAB_LEARN_PROGRESS_FINISH:
      '/course-vocab-sets/learn-progress/:progressId/finish',
    POST_STUDENT_VOCAB_LEARN_PROGRESS_NON_MEMORIZED_START:
      '/course-vocab-sets/learn-progress/:progressId/non-memorized/start',

    // Student vocab write sentence
    POST_STUDENT_VOCAB_WRITE_SENTENCE_START:
      '/course-vocab-sets/:courseVocabSetId/write-sentences-progress/start',
    POST_STUDENT_VOCAB_WRITE_SENTENCE_PROGRESS_CHECK:
      '/course-vocab-sets/write-sentences-progress/:progressId/check',
    POST_STUDENT_VOCAB_WRITE_SENTENCE_PROGRESS_FINISH:
      '/course-vocab-sets/write-sentences-progress/:progressId/finish',

    // Student vocab pronunciation
    POST_STUDENT_VOCAB_PRONUNCIATION_START:
      '/course-vocab-sets/:courseVocabSetId/pronunciation-progress/start',
    POST_STUDENT_VOCAB_PRONUNCIATION_PROGRESS_CHECK:
      '/course-vocab-sets/pronunciation-progress/:progressId/check',
    POST_STUDENT_VOCAB_PRONUNCIATION_PROGRESS_FINISH:
      '/course-vocab-sets/pronunciation-progress/:progressId/finish',

    // Student vocab matching
    POST_STUDENT_VOCAB_MATCHING_START: '/course-vocab-sets/:courseVocabSetId/vocab-matching/start',
    PUT_STUDENT_VOCAB_MATCHING_UPDATE: '/course-vocab-sets/matching-progress/:progressId',
    POST_STUDENT_VOCAB_MATCHING_FINISH: '/course-vocab-sets/matching-progress/:progressId/finish',

    // Student vocab review
    GET_STUDENT_VOCAB_REVIEW_CHECK: '/course-vocab-sets/courses/:courseId/vocab-review/check',
    POST_STUDENT_VOCAB_REVIEW_START: '/course-vocab-sets/courses/:courseId/vocab-review/start',
    POST_STUDENT_VOCAB_REVIEW_PROGRESS_START:
      '/course-vocab-sets/review-progress/:progressId/start',
    GET_STUDENT_VOCAB_REVIEW_PROGRESS: '/course-vocab-sets/review-progress/:progressId',
    GET_STUDENT_VOCAB_REVIEW_PROGRESS_RESULT:
      '/course-vocab-sets/review-progress/:progressId/result',
    POST_STUDENT_VOCAB_REVIEW_PROGRESS_CHECK:
      '/course-vocab-sets/review-progress/:progressId/check',
    POST_STUDENT_VOCAB_REVIEW_PROGRESS_FINISH:
      '/course-vocab-sets/review-progress/:progressId/finish',
    POST_STUDENT_VOCAB_REVIEW_REDO: '/course-vocab-sets/review-progress/:progressId/redo',
    PUT_STUDENT_VOCAB_REVIEW_PROGRESS_UPDATE: '/course-vocab-sets/review-progress/:progressId',
    POST_STUDENT_VOCAB_REVIEW_PROGRESS_SUBMIT:
      '/course-vocab-sets/review-progress/:progressId/submit',
  },
  quizTest: {
    POST_CREATE_MC: '/quiz-tests',
    PUT_UPDATE_MC: '/quiz-tests',
    PUT_PUBLISH_MC: '/quiz-tests/publish',
    GET_MC_DETAIL: '/quiz-tests/:id',
    GET_MC_DETAIL_BY_TEST_ID: '/quiz-tests/by-test-id/:id',
    GET_MC_LIST: '/quiz-tests',
  },
  markingForm: {
    SEARCH_MARKING_FORMS: '/marking-forms/search',
    GET_MARKING_FORMS: '/marking-forms',
    GET_MARKING_FORM_DETAIL: '/marking-forms/:markingFormId',
    POST_MARKING_FORM_DETAIL: '/marking-forms',
    PUT_MARKING_FORM_DETAIL: '/marking-forms/:markingFormId',
  },
  dictationService: {
    START_DICTATION: '/dictations/:dictationId/start',
    RESTART_DICTATION: '/dictations/:dictationId/restart',
    GET_DICTATION_PROGRESS: '/user-dictations/:progressId',
    SUBMIT_USER_DICTATION: '/user-dictations/:progressId/submit',
    SAVE_PROGRESS_DICTATION: '/user-dictations/:progressId',
    PUT_USER_DICTATION_SYNC_TIME: '/user-dictations/:progressId/sync-time',
    GET_DICTATION_PREVIEW: '/dictations/:dictationId',
    GET_USER_DICTATION_HISTORY: '/user-dictations/completed',
    PUT_USER_DICTATION_LEARN_CHECK: '/user-dictations/:progressId/learn/check',
    GET_USER_DICTATION_POPULAR_WORDS: 'user-dictations/:progressId/learn/popular-words',
    GET_USER_DICTATION_ATTEMPTS: '/user-dictations/attempted',
  },
  seoRedirect: {
    GET_SEO_REDIRECT_LISTING: '/url-redirect/paged',
    GET_SEO_REDIRECT_DETAIL: '/url-redirect/:id',
    POST_SEO_REDIRECT_CREATE: '/url-redirect/create',
    PUT_SEO_REDIRECT_UPDATE: '/url-redirect/:id/update',
    DELETE_SEO_REDIRECT: '/url-redirect/:id/delete',
    IMPORT_EXCEL: '/url-redirect/import-excel',
    EXPORT_EXCEL: '/url-redirect/export-excel',
    GET_DIRECT_PAGE: '/url-redirect/source-url',
  },
};

export const apiCacheKeys = {
  onlineTest: {
    GET_PUBLISHED_READING: 'GET_PUBLISHED_READING',
    GET_PUBLISHED_SAMPLE: 'GET_PUBLISHED_SAMPLE',
    GET_PUBLISHED_ONLINE_TEST: 'GET_PUBLISHED_ONLINE_TEST',
    GET_USER_TEST_HISTORY: 'GET_USER_TEST_HISTORY',
    GET_TEST_LIST: 'GET_TEST_LIST',
    GET_TEST_BY_ID: 'GET_TEST_BY_ID',
    GET_STUDENT_INFO_BY_USER_ID: 'GET_STUDENT_INFO_BY_USER_ID',
    GET_TEST_RESULT_BY_ID: 'GET_TEST_RESULT_BY_ID',
    GET_TEST_SECTION_BY_ID: 'GET_TEST_SECTION_BY_ID',
    GET_TEST_SECTION_RESULT_BY_ID: 'GET_TEST_SECTION_RESULT_BY_ID',
    GET_BOOK_LIST: 'GET_BOOK_LIST',
    GET_BOOK_BY_ID: 'GET_BOOK_BY_ID',
    GET_BOOK_SERIES_LIST: 'GET_BOOK_SERIES_LIST',
    FETCH_USER_TEST_INPROGRESS: 'FETCH_USER_TEST_INPROGRESS',
    GET_USER_VIEW: 'GET_USER_VIEW',
    GET_DICTATIONS: 'GET_DICTATIONS',
    GET_DICTATION_DETAIL: 'GET_DICTATION_DETAIL',

    POST_START_TEST: 'POST_START_TEST',
    GET_TEST_DETAIL: 'GET_TEST_DETAIL',
    POST_SUBMIT_TEST: 'POST_SUBMIT_TEST',
    GET_RESOURCE_STATUS: 'GET_RESOURCE_STATUS',

    GET_IELTS_BLOG_LIST: 'GET_IELTS_BLOG_LIST',
    GET_IELTS_BLOG_DETAIL: 'GET_IELTS_BLOG_DETAIL',

    GET_IELTS_SAMPLE_DETAIL: 'GET_IELTS_SAMPLE_DETAIL',
    GET_IELTS_SAMPLES: 'GET_IETLS_SAMPLES',

    GET_DAILY_LEARNING_NEWS: 'GET_DAILY_LEARNING_NEWS',
    GET_DAILY_LEARNING_NEWS_DETAIL: 'GET_DAILY_LEARNING_NEWS_DETAIL',
    GET_DAILY_LEARNING_FACT: 'GET_DAILY_LEARNING_FACT',
    GET_DAILY_LEARNING_FACT_DETAIL: 'GET_DAILY_LEARNING_FACT_DETAIL',
    GET_DAILY_LEARNING_RIDDLE: 'GET_DAILY_LEARNING_RIDDLE',
    GET_DAILY_LEARNING_RIDDLE_DETAIL: 'GET_DAILY_LEARNING_RIDDLE_DETAIL',
    GET_DAILY_LEARNING_STORIES: 'GET_DAILY_LEARNING_STORIES',
    GET_DAILY_LEARNING_STORIES_DETAIL: 'GET_DAILY_LEARNING_STORIES_DETAIL',
    GET_DAILY_LEARNING_VOCABS: 'GET_DAILY_LEARNING_VOCABS',
    GET_DAILY_LEARNING_VOCABS_DETAIL: 'GET_DAILY_LEARNING_VOCABS_DETAIL',

    GET_DICTIONARY_BY_ID: 'GET_DICTIONARY_BY_ID',
    GET_DICTIONARY_LIST: 'GET_DICTIONARY_LIST',
    GET_DIC_TOPIC: 'GET_DIC_TOPIC',

    GET_EXERCICES_PROGRESS: 'GET_EXERCICES_PROGRESS',
  },
  pageManagement: {
    GET_DICTATION_HISTORY: 'GET_DICTATION_HISTORY',
    GET_DICTATION_HISTORY_DETAIL: 'GET_DICTATION_HISTORY_DETAIL',
    GET_FULL_TEST: 'GET_FULL_TEST',
    GET_NEWLY_UPDATED_TESTS: 'GET_NEWLY_UPDATED_TESTS',
    GET_PRACTICE_TEST: 'GET_PRACTICE_TEST',
    GET_PRACTICE_TEST_INFINITY: 'GET_PRACTICE_TEST_INFINITY',
    GET_PRACTICE_TEST_DETAIL: 'GET_PRACTICE_TEST_DETAIL',
    GET_SEO_PAGE: 'GET_SEO_PAGE',
    GET_ALL_URL: 'GET_ALL_URL',
    GET_SEO_PAGE_DETAIL: 'GET_SEO_PAGE_DETAIL',

    GET_USER_TEST: 'GET_USER_TEST',
    FETCH_IELTS_BOOKS: 'FETCH_IELTS_BOOKS',

    FETCH_IELTS_BOOKS_WITH_DETAIL: 'FETCH_IELTS_BOOKS_WITH_DETAIL',
    FETCH_TEST_ACTIONS: 'FETCH_TEST_ACTIONS',

    GET_USER_BOOKMARKS: 'GET_USER_BOOKMARKS',
    GET_USER_PAGE_BOOKMARK: 'GET_USER_PAGE_BOOKMARK',

    GET_BLOG_DETAIL: 'GET_BLOG_DETAIL',
    GET_BOOK_SERIES_LIST: 'GET_BOOK_SERIES_LIST',
    GET_BOOK_BY_ID: 'GET_BOOK_BY_ID',
    GET_BOOK_LIST: 'GET_BOOK_LIST',
    GET_BOOK_LIST_DETAIL: 'GET_BOOK_LIST_DETAIL',

    GET_DICTATIONS: 'GET_LISTING_DICTATIONS',
    GET_BOOK: 'GET_LISTING_BOOK',
    GET_DICTATION_DETAIL: 'GET_LISTING_DICTATION_DETAIL',
    GET_DICTATION: 'GET_DICTATION',
    GET_DICTATION_OVERVIEW: 'GET_DICTATION_OVERVIEW',
    GET_TRENDING_ITEM_FOR_SETUP: 'GET_TRENDING_ITEM_FOR_SETUP',
    GET_TRENDING_PRACTICE_TESTS: 'GET_TRENDING_PRACTICE_TESTS',
    GET_TRENDING_DICTATIONS: 'GET_TRENDING_DICTATIONS',
    GET_DICTATION_IN_PROGRESS: 'GET_DICTATION_IN_PROGRESS',
    GET_TRENDING_BOOKS: 'GET_TRENDING_BOOKS',
    GET_BOOKS_FOR_TRENDING: 'GET_BOOKS_FOR_TRENDING',
    GET_IELTS_BLOG_LIST: 'GET_IELTS_BLOG_LIST',
    GET_IELTS_BLOG_LIST_SETUP: 'GET_IELTS_BLOG_LIST_SETUP',
    GET_TRENDING_ITEM_FOR_IE_BLOG: 'GET_TRENDING_ITEM_FOR_IE_BLOG',
    GET_PRACTICE_SAMPLES: 'GET_PRACTICE_SAMPLES',
    GET_PRACTICE_SAMPLE_DETAIL: 'GET_PRACTICE_SAMPLE_DETAIL',
    GET_IELTS_BLOGS: 'GET_IELTS_BLOGS',
    GET_TRENDING_ITEM_FOR_IE_BLOG_SHARING: 'GET_TRENDING_ITEM_FOR_IE_BLOG_SHARING',
    GET_VOCAB_GRAMMAR_BLOGS: 'GET_VOCAB_GRAMMAR_BLOGS',
    GET_VOCAB_GRAMMAR_BLOGS_TRENDING: 'GET_VOCAB_GRAMMAR_BLOGS_TRENDING',

    GET_DAILY_LEARNING_NEWS_SEO_INFO_DETAIL: 'GET_DAILY_LEARNING_NEWS_SEO_INFO_DETAIL',
    GET_DICTIONARY_LIST: 'GET_DICTIONARY_LIST',

    K12_EXAMS: 'K12_EXAMS_PM',
    K12_EXAM_DETAIL: 'K12_EXAM_DETAIL_PM',

    UPDATE_EXAM_QUESTIONS_PREDICTION: 'UPDATE_EXAM_QUESTIONS_PREDICTION',
    GET_RECOMMENDED_PRACTICE_TESTS: 'GET_RECOMMENDED_PRACTICE_TESTS',
    GET_RECOMMENDED_DICTATIONS: 'GET_RECOMMENDED_DICTATIONS',
    GET_NEWS_SITES: 'GET_NEWS_SITES',
    GET_NEWS_SITE_DETAIL: 'GET_NEWS_SITE_DETAIL',
    GET_NEWS_SITES_QUOTED: 'GET_NEWS_SITES_QUOTED',
    GET_NEWS_SITES_FEATURED: 'GET_NEWS_SITES_FEATURED',
    GET_NEWS_SITES_ARTICLES: 'GET_NEWS_SITES_ARTICLES',
    GET_NEWS_SITES_ARTICLE_DETAIL: 'GET_NEWS_SITES_ARTICLE_DETAIL',
  },
  k12Service: {
    GET_UNIT_NAME: 'GET_UNIT_NAME',
    GET_K12_BLOG: 'GET_K12_BLOG',
    GET_K12_BLOG_DETAIL: 'GET_K12_BLOG_DETAIL',
    K12_EXAMS: 'K12_EXAMS',
    K12_EXAM_DETAIL: 'K12_EXAM_DETAIL',
    PUBLISH_K12_EXAM: 'PUBLISH_K12_EXAM',
    K12_PROVINCES: 'K12_PROVINCES',
    GET_STANDARD_TEST: 'GET_STANDARD_TEST',
  },
  k12ResMng: {
    GET_K12_BLOG: 'GET_K12_BLOG',
    GET_K12_BLOG_LISTED: 'GET_K12_BLOG_LISTED',
    GET_K12_BLOG_TRENDING: 'GET_K12_BLOG_TRENDING',
    GET_K12_BLOG_DETAIL: 'GET_K12_BLOG_DETAIL',
    GET_K12_EXAM: 'GET_K12_EXAM',
    GET_K12_UNIT_REVIEW: 'GET_K12_UNIT_REVIEW',
    K12_EXAM_DETAIL: 'K12_EXAM_DETAIL',
    GET_STANDARD_TEST_SEO: 'GET_STANDARD_TEST_SEO',
  },
  verifyToken: {
    REQUEST_OTP: 'REQUEST_OTP',
    VERIFY_OTP: 'VERIFY_OTP',
  },
  publicService: {
    GET_PAGE_DICTATION_OVERVIEW: 'GET_PAGE_DICTATION_OVERVIEW',
    GET_PAGE_DICTATION_OVERVIEW_INFINITY: 'GET_PAGE_DICTATION_OVERVIEW_INFINITY',
    GET_TRENDING_ITEM: 'GET_TRENDING_ITEM',
    GET_RESOURCE_STATUS: 'GET_RESOURCE_STATUS',
    GET_TRENDING_PRACTICE_TESTS_OVERVIEW: 'GET_TRENDING_PRACTICE_TESTS_OVERVIEW',
    GET_TRENDING_BOOKS_OVERVIEW: 'GET_TRENDING_BOOKS_OVERVIEW',
    GET_PRACTICE_SAMPLES: 'GET_PRACTICE_SAMPLES',
    GET_NEWLY_UPDATED_TESTS: 'GET_NEWLY_UPDATED_TEST',
    GET_SPECIAL_OBJECT_IELTS_PURPOSE: 'GET_SPECIAL_OBJECT_IELTS_PURPOSE',
    GET_VOCAB_GRAMMAR_BLOGS: 'GET_VOCAB_GRAMMAR_BLOGS',
    GET_LISTED_EXAMS: 'GET_LISTED_EXAMS',
    GET_PRACTICE_TESTS: 'GET_PRACTICE_TESTS',
    GET_POPULAR_NEWS_LIST: 'GET_POPULAR_NEWS_LIST',
    GET_RECENT_NEWS_LIST: 'GET_RECENT_NEWS_LIST',
    GET_ACTIVE_STORIES: 'GET_ACTIVE_STORIES',
    GET_ACTIVE_RIDDLE: 'GET_ACTIVE_RIDDLE',
    GET_ACTIVE_VOCAB: 'GET_ACTIVE_VOCAB',
    GET_ACTIVE_FACT: 'GET_ACTIVE_FACT',
    FETCH_LISTED_BLOG: 'FETCH_LISTED_BLOG',
    GET_SPECIAL_OBJECT_IELTS_SCHEDULE_COMPUTER_TEST:
      'GET_SPECIAL_OBJECT_IELTS_SCHEDULE_COMPUTER_TEST',
    UPDATE_USER_INTERACTION: 'UPDATE_USER_INTERACTION',
    GET_VALIDATE_REFERRAL_CODE: 'GET_VALIDATE_REFERRAL_CODE',

    GET_EXAM_QUESTIONS_PREDICTION: 'GET_EXAM_QUESTIONS_PREDICTION',
    GET_VOCAB_LANDING: 'GET_VOCAB_LANDING',
    GET_STANDARD_TEST_OVERVIEW: 'GET_STANDARD_TEST_OVERVIEW',
  },
  search: {
    GET_SEARCH_RESULT: 'GET_SEARCH_RESULT',
    GET_SEARCH_MOBILE_RESULT: 'GET_SEARCH_MOBILE_RESULT',
    FILTER_SAMPLES: 'FILTER_SAMPLES',
    FILTER_IELTS_BLOGS: 'FILTER_IELTS_BLOGS',
    FILTER_VOCABS: 'FILTER_VOCABS',
  },
  user: {
    UPDATE_USER_TUTORIAL: 'UPDATE_USER_TUTORIAL',
    REQUEST_ADD_EMAIL: 'REQUEST_ADD_EMAIL',
    EMAIL_VERIFICATION_STATUS: 'EMAIL_VERIFICATION_STATUS',
  },
  activityService: {
    GET_ACCOUNT_LIST: 'GET_ACCOUNT_LIST',
  },
  membership: {
    GET_USER: 'GET_USER',
    GET_REWARD: 'GET_REWARD',
    GET_LIST_GIFT_CODE: 'GET_LIST_GIFT_CODE',
    GET_GIFT_CODE_DETAIL: 'GET_GIFT_CODE_DETAIL',
    GET_MY_DP_EARNINGS: 'GET_MY_DP_EARNINGS',
    GET_MOST_SHARED_PAGES: 'GET_MOST_SHARED_PAGES',
    GET_LEARNING_GROUPS: 'GET_LEARNING_GROUPS',
    CLAIM_GIFT_CODE: 'CLAIM_GIFT_CODE',
    GET_MY_PREMIUM_USER_INFO: 'GET_MY_PREMIUM_USER_INFO',
    SEND_REFERRAL_EMAIL: 'SEND_REFERRAL_EMAIL',
    GET_MY_REWARD_INFO: 'GET_MY_REWARD_INFO',
    GET_USER_REWARDS: 'GET_USER_REWARDS',
  },
  authorization: {
    GET_APP_LIST: 'GET_APP_LIST',
    GET_ACTIVITY_LIST: 'GET_ACTIVITY_LIST',
    GET_ACTIVITY_DETAIL: 'GET_ACTIVITY_DETAIL',
    GET_PERMISSION_LIST: 'GET_PERMISSION_LIST',
  },
  tts: {
    TEXT_TO_SPEECH: 'TEXT_TO_SPEECH',
  },
  cognitiveService: {
    SPEECH_TO_TEXT_TOKEN: 'SPEECH_TO_TEXT_TOKEN',
  },
  exercise: {
    GET_LIST_EXERCISE: 'GET_LIST_EXERCISE',
    POST_START_EXERCISE: 'POST_START_EXERCISE',
    POST_EXERCISE_PREVIEW: 'POST_EXERCISE_PREVIEW',
    GET_EXERCISE_ATTEMPTS: 'GET_EXERCISE_ATTEMPTS',
    GET_EXERCISE_PROGRESS_DETAIL: 'GET_EXERCISE_PROGRESS_DETAIL',

    GET_PUBLISHED_EXERCISES: 'GET_PUBLISHED_EXERCISES',
    GET_READING_LIST: 'GET_READING_LIST',
  },
  assignment: {
    GET_LIST_ASSIGNMENT: 'GET_LIST_ASSIGNMENT',
    GET_VIEW_ASSIGNMENT_PROGRESS: 'GET_VIEW_ASSIGNMENT_PROGRESS',
    POST_ASSIGNMENT_PREVIEW: 'POST_ASSIGNMENT_PREVIEW',
    GET_ASSIGNMENT_PROGRESS: 'GET_ASSIGNMENT_PROGRESS',
    GET_TA_LIST_ASSIGNMENT: 'GET_TA_LIST_ASSIGNMENT',
    GET_ASSIGNMENT_CORRECTION: 'GET_ASSIGNMENT_CORRECTION',
    GET_TA_ASSIGNMENT_CORRECTION: 'GET_TA_ASSIGNMENT_CORRECTION',
    GET_TEACHER_ASSIGNMENT_CORRECTION: 'GET_TEACHER_ASSIGNMENT_CORRECTION',
    GET_TEACHER_ASSIGNMENT_CORRECTION_VIEW: 'GET_TEACHER_ASSIGNMENT_CORRECTION_VIEW',
    TA_START_MARKING: 'TA_START_MARKING',
    GET_ASSIGNMENT_PROGRESS_HISTORY: 'GET_ASSIGNMENT_PROGRESS_HISTORY',
    GET_ASSIGNMENT_LEADERBOARD: 'GET_ASSIGNMENT_LEADERBOARD',
    GET_PUBLISHED_ASSIGNMENTS: 'GET_PUBLISHED_ASSIGNMENTS',

    GET_ALL_TA: 'GET_ALL_TA',
    TEACHER_GET_ALL_ASSIGNMENT: 'TEACHER_GET_ALL_ASSIGNMENT',
    GET_TA_REMOVED_LIST_ASSIGNMENT: 'GET_TA_REMOVED_LIST_ASSIGNMENT',
    TA_CORRECTION_INFORM_MARKING: 'TA_CORRECTION_INFORM_MARKING',
    TEACHER_CORRECTION_INFORM_MARKING: 'TEACHER_CORRECTION_INFORM_MARKING',

    GET_ASSIGNMENT_CORRECTION_HISTORIES: 'GET_ASSIGNMENT_CORRECTION_HISTORIES',
    GET_ASSIGNMENT_CORRECTION_HISTORIES_BY_VERSION:
      'GET_ASSIGNMENT_CORRECTION_HISTORIES_BY_VERSION',
    TEACHER_GET_MARKED_CORRECTIONS: 'TEACHER_GET_MARKED_CORRECTIONS',
    GET_FIREBASE_REALTIME_DB_TOKEN: 'GET_FIREBASE_REALTIME_DB_TOKEN',
  },
  cms: {
    GET_CHARACTERS: 'GET_CHARACTERS',
    GET_COURSE_PROGRAMS: 'GET_COURSE_PROGRAMS',
    GET_COURSE_LEVELS: 'GET_COURSE_LEVELS',
    GET_CENTERS: 'GET_CENTERS',
    GET_CENTER_ROOMS: 'GET_CENTER_ROOMS',
    GET_EMPLOYEES: 'GET_EMPLOYEES',
  },
  university: {
    GET_LIST_QUESTION: 'GET_LIST_QUESTION',
    GET_LIST_QUESTION_DETAIL: 'GET_LIST_QUESTION_DETAIL',
    GET_LIST_TEST: 'GET_LIST_TEST',
  },
  syllabus: {
    GET_SYLLABUSES: 'GET_SYLLABUSES',
    GET_SYLLABUS_DETAIL: 'GET_SYLLABUS_DETAIL',
    CREATE_SYLLABUS: 'CREATE_SYLLABUS',
    UPDATE_SYLLABUS: 'UPDATE_SYLLABUS',
    PUBLISH_SYLLABUS: 'PUBLISH_SYLLABUS',
    DELETE_SYLLABUS: 'DELETE_SYLLABUS',
    GET_PUBLISHED_SYLLABUSES: 'GET_PUBLISHED_SYLLABUSES',
  },
  courseService: {
    GET_COURSE_GENERAL_INFO: 'GET_COURSE_GENERAL_INFO',
    GET_COURSE_TEACHERS: 'GET_COURSE_TEACHERS',
    GET_COURSE_TA: 'GET_COURSE_TA',
    GET_COURSE_STUDENT: 'GET_COURSE_STUDENT',
    GET_STUDENT_COURSE_GENERAL_INFO: 'GET_STUDENT_COURSE_GENERAL_INFO',
    GET_STUDENT_EXERCISES: 'GET_STUDENT_EXERCISES',
    GET_COURSE_CHECKS: 'GET_COURSE_CHECKS',
    GET_TEACHER_COURSE_WEEKS: 'GET_TEACHER_COURSE_WEEKS',
    GET_COURSE_EXERCISES_STATISTIC: 'GET_COURSE_EXERCISES_STATISTIC',
    GET_COURSE_EXERCISE_STUDENTS: 'GET_COURSE_EXERCISE_STUDENTS',
    GET_COURSE_ASSIGNMENT_STUDENTS: 'GET_COURSE_ASSIGNMENT_STUDENTS',
    GET_STUDENT_ANNOUNCEMENT_DETAIL: 'GET_STUDENT_ANNOUNCEMENT_DETAIL',
    GET_CM_COURSE_NOT_SCHEDULED: 'GET_CM_COURSE_NOT_SCHEDULED',
    GET_TEACHER_COURSE_SESSION: 'GET_TEACHER_COURSE_SESSION',
    GET_TEACHER_LIST_UPCOMING_COURSES: 'GET_TEACHER_LIST_UPCOMING_COURSES',
    GET_TEACHER_LIST_COMPLETED_COURSES: 'GET_TEACHER_LIST_COMPLETED_COURSES',
    GET_TEACHER_LIST_SUBSTITUTE_COURSES: 'GET_TEACHER_LIST_SUBSTITUTE_COURSES',
    GET_STUDENT_ROADMAP_STATISTIC: 'GET_STUDENT_ROADMAP_STATISTIC',
    GET_COURSE_READING_STATISTIC: 'GET_COURSE_READING_STATISTIC',
    GET_COURSE_READING_DETAIL: 'GET_COURSE_READING_DETAIL',
    GET_COURSES_RELATED_READING: 'GET_COURSES_RELATED_READING',
    START_READING_SESSION: 'START_READING_SESSION',
    GET_LIST_FINISHED_STUDENT: 'GET_LIST_FINISHED_STUDENT',

    GET_COURSE_SAMPLE_STATISTIC: 'GET_COURSE_SAMPLE_STATISTIC',
    GET_COURSE_SAMPLE_DETAIL: 'GET_COURSE_SAMPLE_DETAIL',

    GET_STUDENT_ONLINE_TEST_STATISTIC: 'GET_STUDENT_ONLINE_TEST_STATISTIC',
    GET_ASSIGNED_ROADMAP_PAGE: 'GET_ASSIGNED_ROADMAP_PAGE',
    START_ONLINE_TESST_SESSION: 'START_ONLINE_TESST_SESSION',

    GET_STUDENT_COURSE_OPENING_CLOSED_ASSIGNMENTS: 'GET_STUDENT_COURSE_OPENING_CLOSED_ASSIGNMENTS',
    GET_STUDENT_COURSE_OVERVIEW_SYLLABUS: 'GET_STUDENT_COURSE_OVERVIEW_SYLLABUS',
    GET_STUDENT_COURSE_RESOURCES_REMINDER: 'GET_STUDENT_COURSE_RESOURCES_REMINDER',
    GET_STUDENT_ATTENDANCE_STATS: 'GET_STUDENT_ATTENDANCE_STATS',
    GET_STUDENT_ATTENDANCE: 'GET_STUDENT_ATTENDANCE',
    GET_COURSE_STUDENT_DETAIL: 'GET_COURSE_STUDENT_DETAIL',

    TEACHER_GET_STUDENT_ASSIGNMENT_DETAIL: 'TEACHER_GET_STUDENT_ASSIGNMENT_DETAIL',
    GET_COURSE_ASSIGNMENT_DETAIL: 'GET_COURSE_ASSIGNMENT_DETAIL',
    GET_COURSES_BY_TEACHER: 'GET_COURSES_BY_TEACHER',
    GET_ALL_COURSES: 'GET_ALLL_COURSES',
  },
  entranceTest: {
    GET_MARKING_FORMS: 'GET_MARKING_FORMS',
    GET_MARKING_FORM_DETAIL: 'GET_MARKING_FORM_DETAIL',
    GET_REPORT_LIST: 'GET_REPORT_LIST',
    GET_CONTACTS: 'GET_CONTACTS',
    GET_ONLINE_ENTRANCE_TEST_PROGRESS: 'GET_ONLINE_ENTRANCE_TEST_PROGRESS',
    POST_START_ONLINE_ENTRANCE_TEST_READING_PRACTICE_TEST:
      'POST_START_ONLINE_ENTRANCE_TEST_READING_PRACTICE_TEST',
    POST_START_ONLINE_ENTRANCE_TEST_READING_FULL_TEST:
      'POST_START_ONLINE_ENTRANCE_TEST_READING_FULL_TEST',
    POST_START_ONLINE_ENTRANCE_TEST_LISTENING_PRACTICE_TEST:
      'POST_START_ONLINE_ENTRANCE_TEST_LISTENING_PRACTICE_TEST',
    POST_START_ONLINE_ENTRANCE_TEST_LISTENING_FULL_TEST:
      'POST_START_ONLINE_ENTRANCE_TEST_LISTENING_FULL_TEST',
    GET_ONLINE_MARKING: 'GET_ONLINE_MARKING',
    GET_ONLINE_MARKING_SKILL: 'GET_ONLINE_MARKING_SKILL',
    GET_OFFLINE_MARKING_SKILL: 'GET_OFFLINE_MARKING_SKILL',
  },
  doEntranceTest: {
    GET_VALID_EMAIL: 'GET_VALID_EMAIL',
    GET_SKILL_INTRODUCTION: 'GET_SKILL_INTRODUCTION',
  },
  midFinalTest: {
    GET_LIST_ALL_COURSE_STUDENT_FINAL_TEST: 'GET_LIST_ALL_COURSE_STUDENT_FINAL_TEST',
    GET_ONLINE_FINAL_TEST_PROGRESS: 'GET_ONLINE_FINAL_TEST_PROGRESS',
    GET_VALID_EMAIL: 'GET_VALID_EMAIL',
    GET_SKILL_INTRODUCTION: 'GET_SKILL_INTRODUCTION',
    POST_START_ONLINE_FINAL_TEST_LISTENING_FULL_TEST:
      'POST_START_ONLINE_FINAL_TEST_LISTENING_FULL_TEST',
    POST_START_ONLINE_FINAL_TEST_READING_PRACTICE_TEST:
      'POST_START_ONLINE_FINAL_TEST_READING_PRACTICE_TEST',
    POST_START_ONLINE_FINAL_TEST_LISTENING_PRACTICE_TEST:
      'POST_START_ONLINE_FINAL_TEST_LISTENING_PRACTICE_TEST',
  },
};

const getMarkingBasePath = (correctionId) => `/marking-corrections/${correctionId}`;
const getMarkingEditBasePath = (correctionId) => `${getMarkingBasePath(correctionId)}/editing-data`;

const getFinalMarkingBasePath = (userFinalTestId) => `/final-test/marking/${userFinalTestId}`;
const getFinalInputBasePath = (userFinalTestId) => `/final-test/input/${userFinalTestId}`;

export const realTimeDBRefPaths = {
  assignment: {
    CORRECTION_ID: ({ correctionId }) => `${getMarkingBasePath(correctionId)}/id`,
    EVENT: ({ correctionId }) => `${getMarkingBasePath(correctionId)}/event`,

    // common marking
    MARKING_ALL: ({ correctionId }) => getMarkingEditBasePath(correctionId),
    MARKING_SCORE: ({ correctionId, scoreIndex }) =>
      `${getMarkingEditBasePath(correctionId)}/scores/${scoreIndex}`,
    MARKING_RATING: ({ correctionId }) => `${getMarkingEditBasePath(correctionId)}/rating`,
    MARKING_TA_FEEDBACK: ({ correctionId }) => `${getMarkingEditBasePath(correctionId)}/taFeedback`,
    MARKING_TA_FEEDBACK_AUDIO: ({ correctionId }) =>
      `${getMarkingEditBasePath(correctionId)}/taFeedbackAudio`,
    MARKING_TA_FEEDBACK_AUDIO_INPUT_TYPE: ({ correctionId }) =>
      `${getMarkingEditBasePath(correctionId)}/taFeedbackAudioInputType`,
    MARKING_PARTICIPANTS: ({ correctionId }) => `${getMarkingBasePath(correctionId)}/participants`,
    MARKING_EVENTS: ({ correctionId }) => `${getMarkingBasePath(correctionId)}/events`,

    // marking by skill
    WRITING_MARKING_COMMENTS: ({ correctionId }) =>
      `${getMarkingEditBasePath(correctionId)}/comments`,

    WRITING_MARKING_COMMENT_CORRECTION_AUDIO: ({ correctionId, commentIndex }) =>
      `${getMarkingEditBasePath(correctionId)}/comments/${commentIndex}/correctionAudio`,

    WRITING_MARKING_CORRECTION: ({ correctionId }) =>
      `${getMarkingEditBasePath(correctionId)}/correction`,

    SPEAKING_MARKING_QUESTION_CORRECTION_EXPLANATION: ({
      correctionId,
      currentQuestionIndex,
      currentCorrectionIndex,
    }) =>
      `${getMarkingEditBasePath(
        correctionId
      )}/questions/${currentQuestionIndex}/corrections/${currentCorrectionIndex}/explanation`,
    SPEAKING_MARKING_CURRENT_CORRECTION_INDEX: ({ correctionId, currentQuestionIndex }) =>
      `${getMarkingEditBasePath(
        correctionId
      )}/questions/${currentQuestionIndex}/currentCorrectionIndex`,
    SPEAKING_MARKING_CURRENT_QUESTION_INDEX: ({ correctionId }) =>
      `${getMarkingEditBasePath(correctionId)}/currentQuestionIndex`,
    SPEAKING_MARKING_CURRENT_QUESTION_CORRECTIONS_START_IN_SECONDS: ({
      correctionId,
      currentQuestionIndex,
      currentCorrectionIndex,
    }) =>
      `${getMarkingEditBasePath(
        correctionId
      )}/questions/${currentQuestionIndex}/corrections/${currentCorrectionIndex}/startInSeconds`,
    SPEAKING_MARKING_CURRENT_QUESTION_CORRECTIONS_END_IN_SECONDS: ({
      correctionId,
      currentQuestionIndex,
      currentCorrectionIndex,
    }) =>
      `${getMarkingEditBasePath(
        correctionId
      )}/questions/${currentQuestionIndex}/corrections/${currentCorrectionIndex}/endInSeconds`,
    SPEAKING_MARKING_CURRENT_QUESTION_CORRECTIONS: ({ correctionId, currentQuestionIndex }) =>
      `${getMarkingEditBasePath(correctionId)}/questions/${currentQuestionIndex}/corrections`,
    SPEAKING_MARKING_CURRENT_QUESTION_CORRECTIONS_DETAIL: ({
      correctionId,
      currentQuestionIndex,
      correctionIndex,
    }) =>
      `${getMarkingEditBasePath(
        correctionId
      )}/questions/${currentQuestionIndex}/corrections/${correctionIndex}`,
    SPEAKING_MARKING_CURRENT_QUESTION_STATUS: ({ correctionId, currentQuestionIndex }) =>
      `${getMarkingEditBasePath(correctionId)}/questions/${currentQuestionIndex}/status`,
    SPEAKING_MARKING_QUESTIONS: ({ correctionId }) =>
      `${getMarkingEditBasePath(correctionId)}/questions`,
    SPEAKING_MARKING_VIEW_MODE: ({ correctionId }) =>
      `${getMarkingEditBasePath(correctionId)}/viewMode`,
    SPEAKING_MARKING_CURRENT_QUESTION_CORRECTION_AUDIO: ({
      correctionId,
      currentQuestionIndex,
      currentCorrectionIndex,
    }) =>
      `${getMarkingEditBasePath(
        correctionId
      )}/questions/${currentQuestionIndex}/corrections/${currentCorrectionIndex}/correctionAudio`,

    REMOVED_FROM_ASSIGNMENT_WHEN_MARKING: ({ correctionId, userId }) =>
      `${getMarkingBasePath(correctionId)}/removing/${userId}`,
  },
  buildASentenceAssignment: {
    CORRECTION_ID: ({ correctionId }) => `${getMarkingBasePath(correctionId)}/bas`,
    UPDATE_DATA_TO_FIRE_BASE: ({ correctionId }) => `${getMarkingBasePath(correctionId)}/bas`,
  },
  finalTest: {
    SKILL_INPUT: ({ userFinalTestId, skill }) =>
      `${getFinalInputBasePath(userFinalTestId)}/${skill}/edit`,
    SKILL_INPUT_EVENTS: ({ userFinalTestId }) => `${getFinalInputBasePath(userFinalTestId)}/events`,
    SKILL_INPUT_UPDATE_EVENT: ({ userFinalTestId }) =>
      `${getFinalInputBasePath(userFinalTestId)}/events`,
    SKILL_INPUT_PARTICIPANTS: ({ userFinalTestId, skill }) =>
      `${getFinalInputBasePath(userFinalTestId)}/${skill}/participants`,
    SKILL_INPUT_UPDATE_PARTICIPANT: ({ userFinalTestId, skill, userId }) =>
      `${getFinalInputBasePath(userFinalTestId)}/${skill}/participants/${userId}`,

    SKILL_MARKING: ({ userFinalTestId, skill }) =>
      `${getFinalMarkingBasePath(userFinalTestId)}/${skill}/edit`,
    SKILL_MARKING_EVENTS: ({ userFinalTestId }) =>
      `${getFinalMarkingBasePath(userFinalTestId)}/events`,
    SKILL_MARKING_UPDATE_EVENT: ({ userFinalTestId }) =>
      `${getFinalMarkingBasePath(userFinalTestId)}/events`,
    SKILL_MARKING_PARTICIPANTS: ({ userFinalTestId, skill }) =>
      `${getFinalMarkingBasePath(userFinalTestId)}/${skill}/participants`,
    SKILL_MARKING_UPDATE_PARTICIPANT: ({ userFinalTestId, skill, userId }) =>
      `${getFinalMarkingBasePath(userFinalTestId)}/${skill}/participants/${userId}`,
    SKILL_MARKING_UPDATE_ALL_SCORING_CRITERIA: ({ userFinalTestId, skill }) =>
      `${getFinalMarkingBasePath(userFinalTestId)}/${skill}/edit/scoringCriteria`,
    SKILL_MARKING_UPDATE_SCORING_CRITERIA: ({ userFinalTestId, skill, index }) =>
      `${getFinalMarkingBasePath(userFinalTestId)}/${skill}/edit/scoringCriteria/${index}`,
    SKILL_MARKING_UPDATE_SCORING_PERCENTAGE: ({ userFinalTestId, skill }) =>
      `${getFinalMarkingBasePath(userFinalTestId)}/${skill}/edit/scoringPercentage`,
    SKILL_MARKING_UPDATE_COMMENT: ({ userFinalTestId, skill, index }) =>
      `${getFinalMarkingBasePath(userFinalTestId)}/${skill}/edit/commentItems/${index}`,
    SKILL_MARKING_UPDATE_ALL_COMMENT: ({ userFinalTestId, skill }) =>
      `${getFinalMarkingBasePath(userFinalTestId)}/${skill}/edit/commentItems`,
    SKILL_MARKING_UPDATE_FEEDBACK_AUDIO: ({ userFinalTestId, skill }) =>
      `${getFinalMarkingBasePath(userFinalTestId)}/${skill}/edit/feedbackAudio`,
    SKILL_MARKING_UPDATE_FEEDBACK_AUDIO_TYPE: ({ userFinalTestId, skill }) =>
      `${getFinalMarkingBasePath(userFinalTestId)}/${skill}/edit/feedbackAudioType`,
    SKILL_MARKING_UPDATE_GENERAL_COMMENT: ({ userFinalTestId, skill }) =>
      `${getFinalMarkingBasePath(userFinalTestId)}/${skill}/edit/generalComment`,
    SKILL_MARKING_UPDATE_GENERAL_FEEDBACK_AUDIO: ({ userFinalTestId, skill }) =>
      `${getFinalMarkingBasePath(userFinalTestId)}/${skill}/edit/generalFeedbackAudio`,
    SKILL_MARKING_UPDATE_GENERAL_FEEDBACK_AUDIO_TYPE: ({ userFinalTestId, skill }) =>
      `${getFinalMarkingBasePath(userFinalTestId)}/${skill}/edit/generalFeedbackAudioType`,
    SKILL_MARKING_UPDATE_GENERAL_MARK_STATUS: ({ userFinalTestId, skill }) =>
      `${getFinalMarkingBasePath(userFinalTestId)}/${skill}/edit/generalMarkStatus`,
  },
};
