import { ResponsiveTypography } from '../../../../Typography/v3/ResponsiveTypography';

import { colorsV2 } from '../../../../colors-v2';
import * as React from 'react';
import styled from 'styled-components';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';

import { CardPricingDol } from '../CardPricing/Dol/CardPricingDol';
import { CardPricingUser } from '../CardPricing/User/CardPricingUser';
import { ExtendModalHeader } from './ExtendModalHeader';
import { tuhocPrice } from '@doltech/core/lib/entity/tuhoc-price';

const Main = styled.div`
  width: 100%;
  ${fromScreen(768)} {
    width: 720px;
  }
`;

const Body = styled.div`
  background-color: ${colorsV2.white100};
  padding: 8px 32px 32px;
  ${fromScreen(768)} {
    border-radius: 0 0 16px 16px;
  }
`;

const CardWrapper = styled.div`
  display: grid;
  gap: 24px;
  ${fromScreen(600)} {
    grid-template-columns: 1fr 1fr;
    direction: rtl;
  }
`;

export const ExtendModalContent = () => {
  return (
    <Main>
      <ExtendModalHeader
        headerContent={
          <ResponsiveTypography.Paragraph variant="semi-bold/20-28" color="black100">
            Gia hạn miễn phí
          </ResponsiveTypography.Paragraph>
        }
      />
      <Body>
        <ResponsiveTypography.Paragraph variant="regular/14-20" color="black100" mb="24px">
          Tìm hiểu khóa học IELTS Tư Duy tại DOL và nhận ngay code tự học 1 tháng trị giá{' '}
          {tuhocPrice.DOLPRO_1M} khi đăng ký nhập học hoặc inbox DOL để mua gói nhé!
        </ResponsiveTypography.Paragraph>
        <CardWrapper>
          <CardPricingDol />
          <CardPricingUser />
        </CardWrapper>
      </Body>
    </Main>
  );
};
