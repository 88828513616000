import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import { IconCourseUserUser } from '@doltech/icons/IconCourseUserUser';
import { ButtonSizableContent } from '../../../../../Button/ButtonGeneral/ButtonSizableContent';
import { ButtonStyles } from '../../../../../Button/ButtonStyles/ButtonStyles';
import { colorsV2 } from '../../../../../colors-v2';
import { ResponsiveTypography } from '../../../../../Typography/v3/ResponsiveTypography';
import { openNewTab } from '@doltech/utils/lib/location';

import * as React from 'react';
import styled from 'styled-components';
import { ItemPricing } from '../../ItemPricing/ItemPricing';
import { tuhocPrice } from '@doltech/core/lib/entity/tuhoc-price';

const Main = styled.div`
  background-color: ${colorsV2.white100};
  border: 1px solid ${colorsV2.gray40};
  border-radius: 12px;
  padding: 20px;

  display: flex;
  flex-direction: column;

  .text {
    margin-top: 12px;

    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    gap: 16px;
  }

  .action-btn {
    margin-top: 20px;
  }

  ${fromScreen(600)} {
    .action-btn {
      margin-top: auto;
    }
  }

  ${fromScreen(768)} {
    padding: 32px;

    .text {
      gap: 20px;
    }
  }
`;

const BenefitWrapper = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  gap: 8px;
`;

const WHAT_WE_HAVE = [
  'IELTS Online Test',
  'Kho bài mẫu Writing/Speaking',
  'Chép chính tả',
  'Học Tiếng Anh qua tin tức',
];

export const CardPricingUser = () => {
  const openDolMessenger = () => {
    openNewTab('https://m.me/dolvn');
  };
  return (
    <Main>
      <div className="content">
        <IconCourseUserUser size={24} color="black60" />
        <div className="text">
          <ResponsiveTypography.Paragraph variant="semi-bold/14-20" color="black100">
            Người dùng
          </ResponsiveTypography.Paragraph>
          <ResponsiveTypography.Paragraph variant="bold/24-32" color="black100">
            {tuhocPrice.DOLPRO_1M_SHORT}
          </ResponsiveTypography.Paragraph>
          <BenefitWrapper>
            {WHAT_WE_HAVE.map((text, idx) => (
              <ItemPricing key={idx} text={text} />
            ))}
          </BenefitWrapper>
        </div>
      </div>
      <div className="action-btn">
        <ButtonStyles
          buttonStyle="outline"
          color="dark"
          colorStyle="matte"
          onClick={openDolMessenger}
          content={<ButtonSizableContent text="Inbox để mua gói" />}
        />
      </div>
    </Main>
  );
};
