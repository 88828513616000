import { IconChevronChevronDown } from '@doltech/icons/IconChevronChevronDown';
import cl from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';

import { colorsV2 } from '../../../../colors-v2';
import { Typography } from '../../../../DesignSystemV2/Typography';
import { Link } from '../../../../Link';
import { useLandingSharedDataStore } from '../../model/landing-shared-data.hook';
import { topNavs } from '../../model/menus/menus';
import { SubNavigationDrawer } from './SubNavigationDrawer/SubNavigationDrawer';
import { DolPopover } from '../../../../Popover/DolPopover';
import { DolPopoverWithFullWidthContent } from '../../../../Popover/DolPopoverWithFullWidthContent';

const NavItem = styled.div`
  cursor: pointer;
`;

const Main = styled.nav`
  display: grid;
  grid-auto-flow: column;
  gap: 40px;
  align-items: center;
  grid-template-columns: max-content;
  height: 100%;
  .tooltip-title {
    height: 100%;
  }
  .nav-item {
    position: relative;
    color: ${colorsV2.black100};
    height: 100%;
    display: grid;
    place-items: center;

    .title {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 1fr max-content;
      column-gap: 4px;
      align-items: center;
    }

    .indicator {
      opacity: 0;
      height: 2px;
      width: 100%;
      transition: opacity 0.2s ease;
      background-color: ${colorsV2.primary100};
      position: absolute;
      bottom: 0;
    }

    &.active,
    &:hover:not(.disabled) {
      .indicator {
        opacity: 1;
      }
    }

    &.disabled {
      color: ${colorsV2.gray100};
      cursor: not-allowed;
    }
  }

  .dol-popover {
    height: 100%;
    .dol-popover-trigger {
      height: 100%;
    }
  }
`;
export const TopbarNavList = observer(() => {
  const store = useLandingSharedDataStore();

  const [activeSubNav, setActiveSubNav] = React.useState(null);

  const ref = React.useRef<HTMLElement>(null);

  return (
    <Main ref={ref} id="topbar-nav-list">
      {topNavs.map((nav, navIndex) => {
        const {
          openNewTab,
          basePath,
          id,
          subs,
          pageType,
          title,
          width,
          subComponent: NavigationComponent,
          subMode,
          urlPath,
        } = nav as any;
        const topbarItem = store?.getNavigationItem(pageType);
        const finalUrl = basePath
          ? `${basePath}${topbarItem?.url || urlPath}`
          : topbarItem?.url || store.pageLink?.staticPageLink?.[pageType]?.url;
        if (subs) {
          if (subMode === 'popover-full-width') {
            return (
              <React.Fragment key={id + navIndex}>
                <DolPopoverWithFullWidthContent
                  overlayClassName="un-styled no-arrow custom-inner-content"
                  content={<NavigationComponent {...nav} navItems={subs} />}
                >
                  <Link key={id} href={finalUrl} target={openNewTab ? '_blank' : undefined}>
                    <NavItem className="nav-item">
                      <div className="title">
                        <Typography.Text variant="medium/14">{title}</Typography.Text>
                        <IconChevronChevronDown fontSize={12} />
                      </div>

                      <div className="indicator" />
                    </NavItem>
                  </Link>
                </DolPopoverWithFullWidthContent>
              </React.Fragment>
            );
          }
          if (subMode === 'popover') {
            return (
              <React.Fragment key={id + navIndex}>
                <DolPopover
                  overlayClassName="un-styled no-arrow custom-inner-content"
                  content={<NavigationComponent {...nav} navItems={subs} />}
                  placement="bottom-end"
                  maxWidth={`${width}px`}
                >
                  <Link key={id} href={finalUrl} target={openNewTab ? '_blank' : undefined}>
                    <NavItem className="nav-item">
                      <div className="title">
                        <Typography.Text variant="medium/14">{title}</Typography.Text>
                        <IconChevronChevronDown fontSize={12} />
                      </div>

                      <div className="indicator" />
                    </NavItem>
                  </Link>
                </DolPopover>
              </React.Fragment>
            );
          }

          return (
            <React.Fragment key={id + navIndex}>
              <NavItem
                className="nav-item"
                onMouseDown={() => {
                  setActiveSubNav((oldActiveSubNav) => {
                    if (oldActiveSubNav === id) {
                      return null;
                    }
                    return id;
                  });
                }}
              >
                <div className="title">
                  <Typography.Text variant="medium/14">{title}</Typography.Text>
                  <IconChevronChevronDown fontSize={12} />
                </div>

                <div className="indicator" />
              </NavItem>

              {NavigationComponent && activeSubNav === id && (
                <SubNavigationDrawer
                  visible={activeSubNav === id}
                  onClose={() => setActiveSubNav(null)}
                  parentNavEl={ref?.current}
                >
                  <NavigationComponent navItems={subs} />
                </SubNavigationDrawer>
              )}
            </React.Fragment>
          );
        }

        if (finalUrl) {
          // TODO: mismatch class "active" between client and server
          // const isActiveLink =
          //   pageType === store.pageTypeGroup ||
          //   pathname.split('?')[0] === topbarItem.url ||
          //   asPath.split('?')[0] === topbarItem.url;

          return (
            <Link
              target={openNewTab ? '_blank' : undefined}
              key={id}
              className={cl(
                'nav-item'
                // { active: isActiveLink }
              )}
              href={finalUrl}
              scroll
            >
              <Typography.Text variant="medium/14">{title}</Typography.Text>
              <div className="indicator" />
            </Link>
          );
        }

        return (
          <NavItem key={id} className="nav-item disabled">
            <Typography.Text variant="medium/14">{title}</Typography.Text>
            <div className="indicator" />
          </NavItem>
        );
      })}
    </Main>
  );
});
