import { PageTypeEnum } from '@doltech/utils/lib/constants';
import { TOP_NAVS_ID } from '../../../constants';
import { TopNavSelectOptionListSubNavigationExternal } from '../TopNavSelectOptionListSubNavigationExternal';
import { TopNavSelectOptionSampleList } from '../TopNavSelectOptionSampleList';
import { environment } from '@doltech/core/lib/environment/environment';
import { TopNavSelectCourseOptionListSubNavigationExternal } from '../TopNavSelectCourseOptionListSubNavigationExternal';

export const ieltsWritingSampleTask1Aca = {
  id: TOP_NAVS_ID.IELTS_WRITING_TASK1,
  title: 'Writing Task 1 Academic',
  pageType: PageTypeEnum.LANDING_WRITING_SAMPLE_T1,
  openNewTab: true,
};
export const ieltsWritingSampleTask1General = {
  id: TOP_NAVS_ID.IELTS_WRITING_TASK2_GENERAL,
  title: 'Writing Task 1 General',
  pageType: PageTypeEnum.LANDING_WRITING_SAMPLE_T2G,
  openNewTab: true,
};
export const ieltsWritingSampleTask2Aca = {
  id: TOP_NAVS_ID.IELTS_WRITING_TASK2_ACA,
  title: 'Writing Task 2 Academic',
  pageType: PageTypeEnum.LANDING_WRITING_SAMPLE_T2A,
  openNewTab: true,
};

export const ieltsSpeakingPart1 = {
  id: TOP_NAVS_ID.IELTS_SPEAKING_PART1,
  title: 'Speaking Part 1',
  pageType: PageTypeEnum.LANDING_SPEAKING_SAMPLE_P1,
  openNewTab: true,
};
export const ieltsSpeakingPart2 = {
  id: TOP_NAVS_ID.IELTS_SPEAKING_PART2,
  title: 'Speaking Part 2',
  pageType: PageTypeEnum.LANDING_SPEAKING_SAMPLE_P2,
  openNewTab: true,
};
export const ieltsSpeakingPart3 = {
  id: TOP_NAVS_ID.IELTS_SPEAKING_PART3,
  title: 'Speaking Part 3',
  pageType: PageTypeEnum.LANDING_SPEAKING_SAMPLE_P3,
  openNewTab: true,
};

export const ieltsSpeakingSample = {
  id: TOP_NAVS_ID.IELTS_SPEAKING_SAMPLE,
  title: 'IELTS Speaking Sample',
  subs: [ieltsSpeakingPart1, ieltsSpeakingPart2, ieltsSpeakingPart3],
  subMode: 'popover',
  subComponent: TopNavSelectOptionListSubNavigationExternal,
  pageType: PageTypeEnum.LANDING_SPEAKING_SAMPLE,
  openNewTab: true,
};

export const ieltsWritingSample = {
  id: TOP_NAVS_ID.IELTS_WRITING_SAMPLE,
  title: 'IELTS Writing Sample',
  subs: [ieltsWritingSampleTask1General, ieltsWritingSampleTask1Aca, ieltsWritingSampleTask2Aca],
  subMode: 'popover',
  subComponent: TopNavSelectOptionListSubNavigationExternal,
  width: 300,
  pageType: PageTypeEnum.LANDING_WRITING_SAMPLE,
  openNewTab: true,
};

export const ieltsSample = {
  id: TOP_NAVS_ID.IELTS_WRITING_SAMPLE,
  title: 'Bài mẫu IELTS',
  subs: [
    ieltsWritingSampleTask1General,
    ieltsWritingSampleTask1Aca,
    ieltsWritingSampleTask2Aca,
    ieltsSpeakingPart1,
    ieltsSpeakingPart2,
    ieltsSpeakingPart3,
  ],
  subMode: 'popover',
  subComponent: TopNavSelectOptionSampleList,
  width: 432,
  pageType: PageTypeEnum.LANDING_WRITING_SAMPLE,
  openNewTab: true,
};

export const dailyNews = {
  id: TOP_NAVS_ID.DAILY_LEARNING,
  title: TOP_NAVS_ID.DAILY_LEARNING,
  pageType: PageTypeEnum.LANDING_DAILY_LEARNING_NEWS,
};

export const k12 = {
  id: TOP_NAVS_ID.K12,
  title: TOP_NAVS_ID.K12,
  pageType: PageTypeEnum.LAND_K12_EXAM_BOOK,
};

export const dictation = {
  id: TOP_NAVS_ID.DICTATION,
  title: TOP_NAVS_ID.DICTATION,
  pageType: PageTypeEnum.LANDING_DICTATION,
};

export const dictionary = {
  id: TOP_NAVS_ID.DICTIONARY,
  title: TOP_NAVS_ID.DICTIONARY,
  pageType: PageTypeEnum.LANDING_DICTIONARY,
};

export const overrallIeltsBlog = {
  id: TOP_NAVS_ID.LANDING_IELTS_REVIEW,
  title: 'Tổng quan về IELTS',
  pageType: PageTypeEnum.LANDING_IELTS_REVIEW,
  basePath: environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME,

  openNewTab: true,
};

export const readingIeltsBlog = {
  id: TOP_NAVS_ID.LANDING_READING_IELTS_BLOG,
  title: 'IELTS Reading',
  pageType: PageTypeEnum.LANDING_READING_IELTS_BLOG,
  basePath: environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME,

  openNewTab: true,
};

export const writingIeltsBlog = {
  id: TOP_NAVS_ID.LANDING_WRITING_IELTS_BLOG,
  title: 'IELTS Writing',
  pageType: PageTypeEnum.LANDING_WRITING_IELTS_BLOG,
  basePath: environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME,
  openNewTab: true,
};

export const speakingIeltsBlog = {
  id: TOP_NAVS_ID.LANDING_SPEAKING_IELTS_BLOG,
  title: 'IELTS Speaking',
  pageType: PageTypeEnum.LANDING_SPEAKING_IELTS_BLOG,
  basePath: environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME,

  openNewTab: true,
};

export const listeningIeltsBlog = {
  id: TOP_NAVS_ID.LANDING_LISTENING_IELTS_BLOG,
  title: 'IELTS Listening',
  pageType: PageTypeEnum.LANDING_LISTENING_IELTS_BLOG,
  basePath: environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME,

  openNewTab: true,
};

export const grammarIeltsBlog = {
  id: TOP_NAVS_ID.LANDING_GRAMMAR_IELTS_BLOG,
  title: 'Ngữ pháp IELTS',
  pageType: PageTypeEnum.LANDING_GRAMMAR_IELTS_BLOG,
  basePath: environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME,

  openNewTab: true,
};

export const ieltsVocabBlog = {
  id: TOP_NAVS_ID.LANDING_VOCAB_IELTS_BLOG,
  title: 'Từ vựng IELTS',
  pageType: PageTypeEnum.LANDING_VOCAB_IELTS_BLOG,
  basePath: environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME,
  openNewTab: true,
};

export const bookReviewBlog = {
  id: TOP_NAVS_ID.LANDING_BOOK_REVIEW,
  title: 'Tài liệu IELTS',
  pageType: PageTypeEnum.LANDING_BOOK_REVIEW,
  basePath: environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME,
  openNewTab: true,
};

export const ieltsKnowledge = {
  id: TOP_NAVS_ID.LANDING_IELTS_KNOWLEDGE,
  title: 'Kiến thức IELTS',
  subs: [
    overrallIeltsBlog,
    readingIeltsBlog,
    writingIeltsBlog,
    speakingIeltsBlog,
    listeningIeltsBlog,
    grammarIeltsBlog,
    ieltsVocabBlog,
    bookReviewBlog,
  ],
  subMode: 'popover',
  subComponent: TopNavSelectOptionListSubNavigationExternal,
  width: 200,
  pageType: PageTypeEnum.LANDING_IELTS_KNOWLEDGE,
  openNewTab: true,
};

export const ieltsCourse = {
  id: TOP_NAVS_ID.LANDING_IELTS_KNOWLEDGE,
  subs: [],
  title: 'Khóa học IELTS',
  subMode: 'popover-full-width',
  subComponent: TopNavSelectCourseOptionListSubNavigationExternal,
  urlPath: '/khoa-hoc-ielts',
  basePath: environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME,
  openNewTab: true,
};
